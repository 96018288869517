import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import ListadoPostulados from '../components/ListadoPostulados';
import ListadoAdmin from '../pantallasadmin/ListadoAdmin';
import { getPerfil, getBusqueda, setPerfil} from '../actions';
import { connect }  from  'react-redux';


class Home extends Component {
    constructor(props){
        super(props);
        this.logout =  this.logout.bind(this);
        this.toggle =  this.toggle.bind(this);

        this.state = {
            perfil: {
                admin: null,
                contrasena: "",
                email: "",
                fechaCreacion: "",
                nombreEmpresa: "",
                numWhatsapp: "",
                pais: "",
                representanteLegal: "",
                rol: '',
                userId: "",
                usuario: "",
                datosFiscales: {
                    identifacionFiscal: "",
                    direccion: "", 
                    empresa: ""
                },
                ocultos: []
            },
            modal: false
        }
    }

    async logout() {
        await firebase.logout();
        this.props.history.replace('/');
    }

    toggle(){
        this.setState({modal: !this.state.modal});
        this.props.history.replace('/login');
    }

    componentDidMount(){
        if(firebase.auth.currentUser){ // si esta logueado en firebase
            if(this.props.perfil){
                const data = this.props.perfil;
                this.setState({perfil: data});
            } else {
                firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`) //optener los datos de perfil del usuario logueado
                .get()
                .then(doc => {
                    const data = doc.data();
                    if (doc.exists) {
                        this.setState({perfil: data });
                    } else {
                        this.setState({ modal: true });
                    }
                });
            }
            // observador es escucha cuando se actualiza la empresa
            let query = firebase.db.collection("empresas");
            query.onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(change => {
                    if (change.type === 'modified') {
                        console.log(change.doc.data())
                        const data = change.doc.data();
                        this.setState({perfil: data });
                        this.props.setPerfil(data);
                    }
                });
            }, err => {
                console.log(`Encountered error: ${err}`);
            });

        } else { // si no esta logueado en firebase
            this.props.history.replace('/login');
        } 
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            //this.props.setPerfil(data);
            this.setState({
                perfil: data
            });
        });
    }
    
    render() {
        if(!firebase.getCurrentUser()){
            this.props.history.replace('/login');
        }
        let content;
        if (this.state.perfil.admin === true) {
            content = <ListadoAdmin logout={this.logout}  perfil={this.state.perfil} filtros={this.props.busqueda} />;
        } else if (this.state.perfil.admin === false){
            content = content = <ListadoPostulados logout={this.logout}  perfil={this.state.perfil} filtros={this.props.busqueda} />
        }else {
            content = <div id="loader">
                <div className="lds-heart"><div></div></div>
            </div>
        }
        return (
            <div>
                {content}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Advertencia</ModalHeader>
                    <ModalBody>
                        Este usuario no pertenece a este sistema, por esta razon sera enviado nuevamente a la pantalla de Inicio de Sesión.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={this.toggle}>Aceptar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        busqueda: state.busqueda
    }
}

const mapDispatchToProps = {
    getPerfil,
    getBusqueda,
    setPerfil
}

export default connect(mapStateToProps, mapDispatchToProps) (Home);