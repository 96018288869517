import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import { Button, Input} from 'reactstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FaFilePdf, FaCheck } from 'react-icons/fa';
import Menu from '../components/Menu';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LoadingOverlay from 'react-loading-overlay';
// import Busquedas from '../containers/Busquedas';

class PerfilesEmpresas extends Component {
  constructor(props){
    super(props);

    this.logout  = this.logout.bind(this);
    this.exportarPDF  = this.exportarPDF.bind(this);
    this.changeEstado  = this.changeEstado.bind(this);
    this.changeComunicado = this.changeComunicado.bind(this);
    
    this.state = {
      programadores: [],
      programadoresTodos: [],
      pageCount: 0,
      perfil: null,
      busquedas: [],
      loader: true,
      loading: false,
      estado: '',
      imagenes: [],
    }
  }

  componentDidMount(){
    this.loadBusquedas();
    this.loadPostulates();
  }

  logout() {
    firebase.logout();
  }

    loadPostulates(){
        firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
            .get()
            .then(querySnapshot => {
                let objetoProgramador;
                let arrayProgramador = []
                querySnapshot.forEach((doc) => {
                    objetoProgramador = doc.data();
                    objetoProgramador.id = doc.id;
                    /*if(!this.buscarOculto(doc.id)){
                        arrayProgramador.push(objetoProgramador)
                    }*/
                    objetoProgramador.busquedas = this.compareBusquedas(doc.data());
                    arrayProgramador.push(objetoProgramador);
                });
                this.setState({
                    programadores: arrayProgramador, programadoresTodos: arrayProgramador
                })
                let newList =  this.state.programadores.sort(this.sortByDate);

                this.setState({
                    programadores: newList, pageCount: Math.ceil(newList.length / this.state.pageSize), loader: false
                });
            });
    }

    buscarOculto(id){
        let esOculto = false;
        const filter = this.state.perfil.ocultos.filter(row => row === id );
        if(filter.length > 0) { 
            esOculto = true;
        } else {
            esOculto = false;
        }
        return esOculto;
    }

    changeComunicado(idProgramador, idBusuqueda, busqueda, e){
      let array = busqueda.comunicada;
      array.push(idProgramador);
      firebase.db.collection('busquedas').doc(`${idBusuqueda}`)
          .update({comunicada: array})
          .then(result => {
              this.loadPostulates();
          });
    }

    sortByDate(a,b){  
        var dateA = new Date(a.updatedat).getTime();
        var dateB = new Date(b.updatedat).getTime();
        return dateA < dateB ? 1 : -1;  
    }

    loadBusquedas(){
        let objetoBusqueda = null;
        let arrayBusquedas = [];
        firebase.db.collection("busquedas")
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    objetoBusqueda = doc.data();
                    objetoBusqueda.id = doc.id
                    arrayBusquedas.push(objetoBusqueda);
                })
                this.setState({ busquedas: arrayBusquedas });
            });
    }

    compareBusquedas(programador){ // recibo un programador
        let busquedas = [];
        let tecnologias = [];
        let final = [];
        busquedas = this.state.busquedas;
        tecnologias = programador.tecnologias;
        tecnologias.forEach((tec, index) => { // recorro las tecnologias del programador
            let filter = [];
            busquedas.forEach((busqueda, j) => { // recorro las busquedas
                filter = busqueda.tecnologias.filter(row => (row.nombre === tec.tecnologia && row.periodo === tec.tecnologiaTipo && parseInt(tec.tecnologiaAños, 10) >= row.tiempo ))
                if(filter.length > 0){
                  const filterComunicada = busqueda.comunicada.filter(elem => elem ===  programador.uid)
                  if(filterComunicada.length === 0){ // para ver si ya se le a comunicado a la emrpresa de este programador
                    final.push(busqueda);
                  }
                }
            }); 
        });
        let distinc = Array.from(new Set(final.map(s => s.id))) // agrupo por que hay busquedas que se repiten
            .map(id => {
                return final.find(s => s.id === id)
            })
        let activas = distinc.filter(fill => fill.activa === true); // muestro solo las activas
        let sortArray = activas.sort(function(a, b){ // las ordeno
            return a.empresa.nombreEmpresa === b.empresa.nombreEmpresa ? 0 : +(a.nombre > b.nombre) || -1;
        });
        return sortArray;
    }

    async exportarPDF(programador){
        // const self = this;
        const addFooters = doc => {
            const pageCount = doc.internal.getNumberOfPages()
            doc.setFont('helvetica', 'italic')
            doc.setFontSize(10)
            for (var i = 0; i < pageCount; i++) {
              /*doc.text('Hispanos Soluciones C.A Contacto: info@hispanossoluciones.com', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 20, {
                align: 'center'
              })*/
                doc.setTextColor(0,0,0);
                doc.text('Hispanos Soluciones C.A. / contacto: ', 146, doc.internal.pageSize.height - 20, { });
                doc.setTextColor(51,122,183);
                doc.text('info@hispanossoluciones.com', 316, doc.internal.pageSize.height - 20, {});
            }
        }
        this.setState({ loading: true  });
        var doc = new jsPDF('p', 'pt', 'A4', true);
        const llena = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAjCAIAAABzZz93AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGmSURBVEhL7Za7SgNBFIZT+wQK9l4ewPgCSR+0VBAstLKIrQop1cJGwUqsbMROrKzSitiKsMoiKmJhqzbjjwvD+M/uOXOWECyErwgzO/PtucxsGvf5s8pD/pL9/Mjyp3DcSuPj82to/HnZ69s7jSRSR7a8tl3PZ5ZBMzLe3js8pXEV55xZdnRyAVmzvUrjKnVk80tbkIG77JGmZMyyIocF1kyaZRB42eTsIs3KmGUzrRUvA1c3t/SAgE2GIoUm0N3Yp2cEbLIwhwWmTP6Sofi9nePebiXYmmRgffOAHgsJ88yRYW50qkPb1QavHm5ekkbE15rr0jIreOOz8z7tXFkzVJ7Wp4OOLT3vUoPgWqqRUuGOlmQAJaSDJSPfKYoM4DX9ZSiAHKgHXJeB4pqXQU/RqpgkGcpAW5dSVSpPkiyxTZAAWkjoMlSCNq0CCaC1hC6LDxz687J/HY+PTXdoLaHLqPXDGwjKieZCOCs3pCILvymwxnuhKcIQ5S+OIvNNT1cq4UOU/wUpMhzn0oBifIjCvyBFJgcUgxABDXr0Bhkg/7KBMFyZc982987J93mLyAAAAABJRU5ErkJggg=='
        const vacia = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAIAAAC4O+zSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAF1SURBVEhL7ZbdSgMxEEZ9/1cTRCmKSqFUl61W19ZarfVm/TAwjF+SySSU4oXlXJT8ncxkkvbkc/91NP687H23pxYnLbLp/LnNVy2D5nRy3z2sqd1DtWzx9ArZ5HZB7Tbjz6dadjNbQgY22x11GbTIQg4DVZlskUEgsovrnnoNWmQ4KpGB9eaDBuSoluGQtAnMuoHG5KiW6RwG/JlkGQ5/3r/c9ascWJpkAMHRMI3kmWUAfWeXHS3XDLYuKydkAPFdTR9pWi3Y8XJ408umZQEkh+b7QcXG992SATxLDSnNvdEFGcAR0sWyMd6Usgxgm/IYGiAH9gV3yUB45m1QUzSL8MpwDLR0kuRRCV6Zs0yQAJqocclwErRoDiSA5mpcsvjCoT6H1TZuRwJorsYlo9LXLxCU579fS6MgyzL9mwJrvBaKQoeI7zRAKMuk6HVAMRIiNkRdQlmG65wMKEZCzP0LKsvsgGIQIqDGQFl2QP5lB+HosnH8BtAvjT7SGunQAAAAAElFTkSuQmCC'
        
        doc.autoTable({
            head: [[`Ficha ${programador.nombres.split(' ')[0]} ${programador.apellidos.split(' ')[0]}`]],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })

        let perfiles = [];
        perfiles = programador.tipoPerfiles;
     
        doc.autoTable({
            head: [[perfiles]],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })
        const costoSemanal = (programador.costoMes / 4 ).toFixed(1);
        const costoDia = (((programador.costoMes / 4 )) /  5).toFixed(1);
        const costoHora = (((programador.costoMes / 4 )) /  (5 * 8)).toFixed(1);
        const splitCostoSemanal = costoSemanal.split('.');
        const splitCostoDia = costoDia.split('.');
        const splitCostoHora = costoHora.split('.');
        let finalCostoSemanal = '';
        let finalCostoDia = '';
        let finalCostoHora ='';
        if(splitCostoSemanal[1] === '0'){
            finalCostoSemanal = splitCostoSemanal[0];
        } else {
            finalCostoSemanal = costoSemanal;
        }
        if(splitCostoDia[1] === '0'){
            finalCostoDia = splitCostoDia[0];
        } else {
            finalCostoDia = costoDia;
        }
        if(splitCostoHora[1] === '0'){
            finalCostoHora = splitCostoHora[0];
        } else {
            finalCostoHora = costoHora;
        }
        doc.autoTable({
            head: [['Atributo', 'Valor',]],
            body: [
              ['Nombres', programador.nombres.split(' ')[0]],
              ['Apellidos', programador.apellidos.split(' ')[0]],
              ['Ciudad', programador.ciudad],
              //['Fecha Postuación', programador.fecha],
              ['Años de Experiencia', programador.anosExperiencia],
              ['Titulo académico', programador.titulo],
              ['Estudio Avanzado', programador.otroTitulo],
              ['Universidad', programador.universidad],
              ['Tipo de Perfil', programador.tipoPerfiles],
              ['Habla y escribe ingles al 100%', programador.nivelIngles],
              ['Costo Mensual', programador.costoMes + ' USD'],
              ['Costo por Semana', finalCostoSemanal + ' USD'],
              ['Costo por Día', finalCostoDia + ' USD'],
              ['Costo por Hora', finalCostoHora + ' USD'],
            ],
            headStyles: {
              fillColor: [62, 64, 97],
            },
        })
        doc.autoTable({
            head: [['Tecnologías']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })

        let arrayTec = [];
        programador.tecnologias.forEach((item, i) => {
            arrayTec.push([item.tecnologia, item.tecnologiaAños + ' ' + item.tecnologiaTipo , '', item.tecnologiaVersion]);
        });

        doc.autoTable({
            head: [['Tecnología', 'Tiempo de experiencia', 'Dominio (1-5)', 'Versión']],
            body: arrayTec,
            headStyles: {
              fillColor: [62, 64, 97],
            },
            allSectionHooks: true,
            didDrawCell:  (data) => {
                const arreglo = programador.tecnologias;
                const value = arreglo[data.row.index].dominio;
                if(data.column.index === 2 && data.row.section !== 'head'){
                    for(let i=1; i<=5; i++ ){
                        if(i > value){
                            doc.addImage(vacia, "JPEG", data.cell.x + (15 * i) + 5, data.cell.y + 2, 15, 15);
                        } else {
                            doc.addImage(llena, "JPEG", data.cell.x + (15 * i) + 5 , data.cell.y + 2, 15, 15);
                        }
                    }
                }
            }
        });

        doc.autoTable({
            head: [['Experiencias Laborales']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })
        let arrayEmp = [];
        programador.experiencias.map((item, i) => {
            arrayEmp.push([item.empresaNombre, item.empresaDescripcion]);
            return arrayEmp;
        });
        doc.autoTable({
            head: [['Empresa', 'Actividades']],
            body: arrayEmp,
            headStyles: {
              fillColor: [62, 64, 97],
            },
        });
        doc.autoTable({
            head: [['Links de conocimientos o proyectos relizados']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })
        let arrayLin = [];
        programador.links.map((item, i) => {
            arrayLin.push([item.link, item.linkDescripcion]);
            return arrayLin;
        });
        doc.autoTable({
            head: [['Link', 'Descripción']],
            body: arrayLin,
            headStyles: {
              fillColor: [62, 64, 97],
            },
            columnStyles: {
                0: {
                    fontStyle: 'bold',
                    textDecoration: 'undeerline',
                    textColor: [51,122,183]                
                },
            }
        });
        if(programador.cursos.length > 0){
            doc.autoTable({
                head: [['Cursos realizados']],
                body: [
                ],
                headStyles:{
                    valign: 'middle',
                    halign : 'center',
                    fontSize: 18,
                    fillColor: [255, 255, 255],
                    textColor: [40, 167, 69],
                }
            })
            let arrayCur = [];
            programador.cursos.map((item, i) => {
                arrayCur.push([item.cursoNombre, item.cursoDescripcion]);
                return arrayCur;
            });
            doc.autoTable({
                head: [['Curso', 'Descripción']],
                body: arrayCur,
                headStyles: {
                  fillColor: [62, 64, 97],
                },
            });
        }
        let arrayImagesHtml = []
        const images = programador.imagenes ? programador.imagenes : [];
        let promises = [];
        for (let i = 0; i < images.length; i++) {
          const url = images[i].url;
          try {
              const data = await this.loadImage(url,i);
              arrayImagesHtml.push(data);
          } catch (error) {
              console.log('error', error)
          }
        }
        var width = doc.internal.pageSize.getWidth();
        let inicioY = 40; 
        this.setState({ loading: true  });
        arrayImagesHtml.forEach((img, vuelta) => {
          const ancho = img.width;
          const alto = img.height;
          if(vuelta % 2 === 0){
              doc.addPage();
              if(alto > ancho){
                  doc.addImage(img, 'JPEG', 200, inicioY, (width-400), 300);
              } else {
                  doc.addImage(img, 'JPEG', 40, inicioY, (width-80), 270);
              }
          } else {
              if(alto > ancho){
                  doc.addImage(img, 'JPEG', 200, (40 + 330), (width-400), 300);
              } else {
                  doc.addImage(img, 'JPEG', 40, (40 + 310), (width-80), 270);
              }
          }
      })
      addFooters(doc);
      this.setState({ loading: false  });
      // window.open(doc.output('bloburl'), '_blank');
      doc.save(`${programador.nombres.split(' ')[0]} ${programador.apellidos.split(' ')[0]}.pdf`);
    }

    loadImage(url, i) {
      return new Promise((resolve, rejected) => {
          let img = new Image();
          img.setAttribute('crossOrigin', 'anonymous');
          img.onload = () => {
              resolve(img);
          }
          img.onerror = function() {
              rejected('url no valida')
          };
          img.src = url
      })
    }

    changeEstado(uid, e){
        this.setState({estado: e.target.value});
        let data = {estado: e.target.value};
        firebase.db.collection("programadores").doc(uid).update(data)
            .then(result => {
                this.loadPostulates();
            }).catch(err => {
                console.log(err.message);
            });
    }

  render(){
    return(
      <div>
        <Menu link='coincidencias' admin={true} logout={this.logout} ></Menu>
        {this.state.loader &&
            <div id="loader">
                <div className="lds-heart"><div></div></div>
            </div>
        }
        <LoadingOverlay
              active={this.state.loading}
              spinner
              text='Cargando...'
        >
        {!this.state.loader &&  
          <main className="container d-flex align-items-center justify-content-center mb-lg-5">
            <div className="row w-100 h-100 align-items-center justify-content-center">
              <div className="row p-0 w-100 ">
                <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                  <div className="list-postul-header text-center p-3">
                      <h2>Perfiles para Empresas</h2>
                  </div>
                  <div className="list-postul-content py-3 px-2 px-md-3 w-100">                              
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="d-table-cell">Especialista</th>
                          <th scope="col" className="d-table-cell"></th>
                          <th scope="col" className="d-none d-sm-table-cell">Empresas Relacionadas al perfil</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.programadores 
                            //.slice (this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize) 
                            .map ((data, key) => { 
                              return ( 
                                data.busquedas.length > 0 &&
                                <tr key={key}>
                                    <td className="d-table-cell">
                                        <span><b>{data.nombres} {data.apellidos}</b></span><br/>
                                        <span>{data.email}</span><br />
                                        <span>{data.numWhatsapp}</span>

                                        <div className="form-group row align-items-center" style={{ marginTop: 10, marginRight: 10 }} >
                                            <div className="col-10" style={{ paddingRight: 5, paddingLeft: 5}}>
                                                <Input type="select" name="estado" id="estado" value={data.estado} onChange={(e)=> this.changeEstado(data.uid, e)} >
                                                    <option value="Habilitado">Habilitado</option>
                                                    <option value="Contratado">Contratado</option>
                                                </Input>
                                            </div>
                                            <div className="col-2" style={{ paddingRight: 5, paddingLeft: 5}}>
                                                <Button id={'pdf-'+key} type="button" color="primary" onClick={(event) => {this.exportarPDF(data); }} style={{marginLeft: 5}} size="sm" >
                                                    <FaFilePdf/>                                                                  
                                                </Button>
                                            </div>
                                        </div>

                                    </td>
                                    <td className="d-table-cell"></td>
                                    <td className="d-none d-sm-table-cell">
                                      <table className="table" style={{marginBottom: 0 }} >
                                        <thead>
                                          <tr>
                                            <th scope="col" className="d-table-cell">Obligatorias</th>
                                            <th scope="col" className="d-table-cell">Opcionales</th>
                                            <th scope="col" className="d-table-cell">Costo Mes</th>
                                            <th scope="col" className="d-table-cell">Ingles</th>
                                            <th scope="col" className="d-table-cell">Datos de la Empresa</th>
                                            <th scope="col" className="d-table-cell">Comunicar</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {data.busquedas.length === 0 &&
                                            <tr>
                                              <td className="d-table-cell" style={{ textAlign: 'center'}} colSpan="4" >
                                                No encaja en ninguna busqueda
                                              </td>
                                            </tr>
                                          }
                                          {data.busquedas.map((bsq, k) => ( 
                                            <tr key={k} >
                                              <td className="d-table-cell">
                                                {bsq.tecnologias.map((tec, j) => ( 
                                                  <div key={j}>
                                                    <span><b>{tec.nombre}</b>&nbsp;</span>
                                                    <span>{tec.tiempo}&nbsp;</span>
                                                    <span>{tec.periodo}</span>
                                                  </div>
                                                ))}
                                              </td>
                                              {bsq.opcionales.length === 0 &&
                                                  <td className="d-table-cell">Ninguna</td>
                                              }
                                              {bsq.opcionales.length > 0 &&
                                                  <td className="d-table-cell">
                                                    {bsq.opcionales.map((tec, j) => ( 
                                                      <div key={j}>
                                                        <span><b>{tec.nombre}</b>&nbsp;</span>
                                                        <span>{tec.tiempo}&nbsp;</span>
                                                        <span>{tec.periodo}</span>
                                                      </div>
                                                    ))}
                                                  </td>
                                              }
                                              <td className="d-table-cell">{bsq.costoMensual}</td>
                                              <td className="d-table-cell">{bsq.nivelIngles}</td>
                                              <td className="d-table-cell">
                                                <span><b>{bsq.empresa.nombreEmpresa}</b></span><br />
                                                <span>{bsq.empresa.email}</span><br />
                                                <span>{bsq.empresa.pais}</span>
                                              </td>
                                              <td className="d-table-cell">                                            
                                                <Button color="success" 
                                                  size="sm" id={key + '-' + k}  
                                                  onClick={(e) =>{ this.changeComunicado(data.id, bsq.id, bsq, e)}}
                                                >
                                                  <FaCheck />
                                                </Button>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                    </table>
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        }
        </LoadingOverlay>
      </div>
    );
  }
}

export default PerfilesEmpresas;