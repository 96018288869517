import React  from 'react';
import { Label  } from 'reactstrap';

function Link(props) {
    return <div className="form-group row m-0">
    <div className="col-12">
        <div className="form-holder">

            {/*<Label>Enlace</Label>
            <Input type="text" className="form-control" id="" name="" value={props.link.link} readOnly={true}/>*/}
            <a href={props.link.link} target="_blank" rel="noopener noreferrer" >{props.link.link}</a>
        </div>
    </div>
    <div className="col-12">
        <div className="form-holder">

            <Label><b>Descripción</b></Label>
            <p style={{textAlign: 'justify'}}>
                {props.link.linkDescripcion}
            </p>
            {/*<textarea name="" id="" cols="35" rows="4" readOnly={true} value={props.link.linkDescripcion}></textarea>*/}

        </div>
    </div>
</div>
}

export default Link;