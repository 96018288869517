import React, {useEffect, useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/opensans-font.css';
import './fonts/material-design-iconic-font/css/material-design-iconic-font.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import firebase from './firebase/firebase';
import Login from './auth/Login';
// import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import Home from './containers/Home';
import Busquedas from './containers/Busquedas';
import DatosFiscales from './containers/DatosFiscales';
import DettalleProgramador from './components/DettalleProgramador';
import Misagendas from './components/Misagendas';
import BusquedasAdmin from './pantallasadmin/BusquedasAdmin';
import PerfilesEmpresas from './pantallasadmin/perfilesparaEmpresas';
import ListadoAgendas from './pantallasadmin/ListadoAgendas';
import Empresas from './pantallasadmin/Empresas';
import Noexiste from './components/Noexiste';
import ProtectedRoute from './ProtectedRoute';

function App() {

  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(()=>{
    firebase.isInitialized().then(val => {
      setFirebaseInitialized(val);
    })
  })

  return firebaseInitialized !== false ? (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route  path="/inicio" component={Home}></Route>
        <Route  path="/login" component={Login}></Route>
        {/*<Route  path="/register" component={Register}></Route>*/}
        <Route  path="/forgotpassword" component={ForgotPassword}></Route> 
        <ProtectedRoute path="/detalle/:id" component={DettalleProgramador} />
        <ProtectedRoute path="/misbusquedas" component={Busquedas} />
        <ProtectedRoute path="/misagendas" component={Misagendas} />
        <ProtectedRoute path="/datosfiscales" component={DatosFiscales} />

        <ProtectedRoute path="/busquedas" component={BusquedasAdmin} />
        <ProtectedRoute path="/coincidencias" component={PerfilesEmpresas} />
        <ProtectedRoute path="/agendas" component={ListadoAgendas} />
        <ProtectedRoute path="/empresas" component={Empresas} />
        <Route  path='*' exact={true} component={Noexiste}></Route>
      </Switch>
    </Router>
  ) : <div id="loader">
      <div className="lds-heart"><div></div></div>
    </div>
}

export default App;
