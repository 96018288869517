import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import Menu from '../components/Menu'
import {Link} from "react-router-dom"
import { Input, Label } from 'reactstrap';

class ListadoAgendas extends Component {
    constructor(props){
        super(props);

        this.logout  = this.logout.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.changeRealizo = this.changeRealizo.bind(this);
        
        this.state = {
            agendas: [],
            loader: true,
            orderBy: 'fechaAgendado'
        }
    }

    componentDidMount(){
        this.loadAgendas();
    }

    loadAgendas(){
        firebase.db.collection("agendas")
        .get()
        .then(querySnapshot => {
            let objetoAgenda;
            let arrayagendas = []
            querySnapshot.forEach((doc) => {
                objetoAgenda = doc.data();
                objetoAgenda.id = doc.id
                arrayagendas.push(objetoAgenda)
            });
            let ordenado = arrayagendas.sort(this.sortByDate);
            this.setState({ agendas: ordenado, loader: false })
        });
    }

    logout() {
        firebase.logout();
    }

    changeOrder = (e) => {
        let value = e.target.value
        this.setState({[e.target.name]: value});
        let newList = this.state.agendas;
        let ordenado = [];

        if(value === 'fechaAgendado'){
            ordenado = newList.sort(this.sortByDate);
        }else if(value === 'programador'){
            ordenado = newList.sort((prev, next) => {
                if(prev.programador.nombres.toLowerCase()  > next.programador.nombres.toLowerCase()){
                    return 1;
                }
                if(prev.programador.nombres.toLowerCase() < next.programador.nombres.toLowerCase()){
                    return -1;
                }
                return 0;
            });
        } else if(value === 'empresa') {
            ordenado = newList.sort((prev, next) => {
                if(prev.empresa.nombreEmpresa.toLowerCase()  > next.empresa.nombreEmpresa.toLowerCase()){
                    return 1;
                }
                if(prev.empresa.nombreEmpresa.toLowerCase() < next.empresa.nombreEmpresa.toLowerCase()){
                    return -1;
                }
                return 0;
            });
        }
        else{
            ordenado = newList.sort((prev, next) => {
                if(prev.empresa.pais.toLowerCase()  > next.empresa.pais.toLowerCase()){
                    return 1;
                }
                if(prev.empresa.pais.toLowerCase() < next.empresa.pais.toLowerCase()){
                    return -1;
                }
                return 0;
            });
        }
        this.setState({
            programadores: ordenado, pageCount: Math.ceil(newList.length / this.state.pageSize)
        });
    }

    sortByDate(a,b){  
        var dateA = new Date(a.fechaAgendado).getTime();
        var dateB = new Date(b.fechaAgendado).getTime();
        return dateA < dateB ? 1 : -1;  
    }

    changeRealizo(event){
        let agendas = this.state.agendas
        agendas.forEach(agen => {
            if (agen.id === event.target.value){
                agen.seRealizo = event.target.checked
                firebase.db.collection("agendas").doc(`${agen.id}`).update({seRealizo: event.target.checked});
            }
        })
        this.setState({agendas: agendas});
    }

    render(){
        return(
            <div>

                <Menu link='agendas' admin={true} logout={this.logout} ></Menu>

                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!this.state.loader &&
                
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row w-100 h-100 align-items-center justify-content-center">
                        <div className="row p-0 w-100 ">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Agendas</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-5">

                                <div className="row mb-3 w-100">
                                    <div className="col-md-6 col-lg-5">
                                        <div className="form-group">
                                            <Label>Ordenar por</Label>
                                            <Input type="select" name="orderBy" id="orderBy" className="w-100" value={this.state.orderBy} onChange={this.changeOrder}  bsSize="sm">
                                                <option value="empresa">Empresa</option>
                                                <option value="fechaAgendado">Fecha</option>
                                                <option value="pais">País</option>
                                                <option value="programador">Programador</option>
                                            </Input>
                                        </div>
                                    </div>
                                </div>

                                    <div className="search-true">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="d-table-cell">Empresa</th>
                                                    <th scope="col" className="d-table-cell">Email empresa</th>
                                                    <th scope="col" className="d-table-cell">Programador</th>
                                                    <th scope="col" className="d-table-celll">Fecha/Hora</th>
                                                    <th scope="col" className="d-none d-md-table-cell">Pais</th>
                                                    <th scope="col" className="d-none d-md-table-cell text-center">Se realizo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.agendas.map((data, key) => (
                                                <tr key={key}>
                                                    <td className="d-table-cell">{data.empresa.nombreEmpresa}</td>
                                                    <td className="d-table-cell">{data.empresa.email}</td>
                                                    <td className="d-table-cell">
                                                        <Link target="_blank" to={{ pathname: `/detalle/${data.programador.uid}`}}>
                                                        {data.programador.nombres.split(' ')[0]} {data.programador.apellidos.split(' ')[0]}
                                                        </Link>
                                                    </td>
                                                    <td className="d-table-cell">{data.fechaAgendado} {data.horaAgendado}</td>
                                                    <td className="d-none d-md-table-cell">{data.empresa.pais}</td>
                                                    {/*<td className="d-none d-md-table-cell text-center">
                                                        { data.seRealizo ? 'Si' : 'No'  } 
                                                    </td>*/}
                                                    <td className="d-none d-md-table-cell text-center">
                                                        <Input  type="checkbox" value={data.id} checked={data.seRealizo} onChange={this.changeRealizo} style={{position:'relative'}} /> 
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }
            </div>
        );
    }
}

export default ListadoAgendas;