import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/database';
 
// Firebase Desarrollo
/*const firebaseConfig = {
    apiKey: "AIzaSyDr0EU9Q2j22SND7tAhwztU-my2JDFiHpA",
    authDomain: "postulacioneshp-f7ff8.firebaseapp.com",
    databaseURL: "https://postulacioneshp-f7ff8.firebaseio.com",
    projectId: "postulacioneshp-f7ff8",
    storageBucket: "postulacioneshp-f7ff8.appspot.com",
    messagingSenderId: "491302134860",
    appId: "1:491302134860:web:2155b11322e56c8ba340e9",
    measurementId: "G-GVEY1FEW5W"
};*/

const firebaseConfig = {
    apiKey: "AIzaSyBxlgvSy4R9yciZImbTO4cy-_S-j1MSq7Q",
    authDomain: "hsbusquedas.firebaseapp.com",
    databaseURL: "https://hsbusquedas.firebaseio.com",
    projectId: "hsbusquedas",
    storageBucket: "hsbusquedas.appspot.com",
    messagingSenderId: "1048022673421",
    appId: "1:1048022673421:web:9db613f9a47b39de344d44",
    measurementId: "G-53L033C7GE"
};
 
class Firebase {
    constructor(){
        app.initializeApp(firebaseConfig);
        this.auth = app.auth()
        this.db = app.firestore()
        this.database = app.database()
    }

    login(email, password){
        return this.auth.signInWithEmailAndPassword(email, password)
    }

    logout(){
        return this.auth.signOut()
    }

    async register (email, password ){
        const data = await this.auth.createUserWithEmailAndPassword(email, password)
        this.auth.currentUser.updateProfile({
            displayName: email
        })
        return data;
    }

    addProfile(nombreEmpresa, representanteLegal, numWhatsapp){
        let profileInfo = {
            nombreEmpresa,
            representanteLegal,
            numWhatsapp,
            rol : 'empresa',
            userId: this.auth.currentUser.uid,
            registroCompletado: false,
            ultimaPestaña: '1',
			admin: false
        }; 
        return this.db.collection('perfiles').add(profileInfo);  
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }

    getCurrentUser(){
        return this.auth.currentUser
    }

    getCurrentUsername(){
        return this.auth.currentUser && this.auth.currentUser.displayName
    }

    recoverPass(email){
        return this.auth.sendPasswordResetEmail(email);
    }
}

export default new Firebase();
