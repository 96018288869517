const tokenReducer = (state='', action) =>{
    switch (action.type){
        case 'SET_TOKEN':
            return action.token;
        case 'CLEAR_TOKEN':
            return '';
        default :
            return state;
    }
}

export default tokenReducer