import React  from 'react';

function Noexiste(props) {

    const divStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    };

    return <div>
        <main className="container d-flex align-items-center justify-content-center my-lg-5">
            <div className="row h-100 w-100 align-items-center justify-content-center">
                <div className="row p-0 w-100 h-100"  >
                    <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">
                        <div className="py-3 px-2 px-md-5 text-center h-100" style={divStyle} >
                            <h1>404 Page not Found</h1>
                            <a href="/inicio" className="btn btn-success"  >Volver al inicio</a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>;
}

export default Noexiste;