import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Input, Button, Label , Modal, ModalHeader, ModalBody, Collapse , Alert, Tooltip  } from 'reactstrap';
import firebase from '../firebase/firebase';
import ReactLoading from "react-loading";
import Tecnologia from './parciales/tecnologiaForm';
import Experiencia from './parciales/experienciaForm';
import Link from './parciales/linkForm';
import Curso from './parciales/cursoForm';
import { setPerfil } from '../actions';
import { setBusqueda } from '../actions';
import { connect }  from  'react-redux';
import axios from 'axios';
import * as moment from 'moment'
import Menu from './Menu';
// import momenttimezone from 'moment-timezone/builds/moment-timezone-with-data';
import timezones  from '../mocks/timezones';
import config from '../config/index';

import GooglePlay from '../img/google-play.png';
import AppStore from '../img/app-store.png';
import PcImg from '../img/pc.png';


class DetalleProgramador  extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeTimezone = this.handleChangeTimezone.bind(this);
        this.back = this.back.bind(this);
        this.agendar = this.agendar.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleTerminos = this.toggleTerminos.bind(this);
        this.confirmarAgendar = this.confirmarAgendar.bind(this);
        this.changeTerms = this.changeTerms.bind(this);
        this.openModalTerms = this.openModalTerms.bind(this);
        this.onDismissAlert = this.onDismissAlert.bind(this);
        this.logout  = this.logout.bind(this);

        this.toggleTooltipAndroid  = this.toggleTooltipAndroid.bind(this);
        this.toggleTooltipIos  = this.toggleTooltipIos.bind(this);
        this.toggleTooltipPc  = this.toggleTooltipPc.bind(this);

        this.state = {
            datosGenerales: { //datos de un programador
                activo: null,
                admin: null,
                anosExperiencia: 0,
                apellidos: '',
                aspiracion: '',
                cedula: '',
                ciudad: '',
                cursos: [],
                direccion: '',
                email: '',
                experiencias: [],
                fecha: '',
                links: [],
                nivelIngles: '',
                nombres: '',
                numWhatsapp: '',
                registroCompletado: null,
                rol: '',
                tecnologias: [],
                tipoPerfil: '',
                tipoPerfiles: '',
                titulo: '',
                uid: '',
                ultimaPestaña: '',
                universidad: '',
                costoMes: 0,             
            },
            perfil: { //datos de la empresa logueada
                admin: false,
                contrasena: "",
                email: "",
                fechaCreacion: "",
                nombreEmpresa: "",
                numWhatsapp: "",
                pais: "",
                representanteLegal: "",
                rol: '',
                userId: "",
                usuario: "",
                datosFiscales: {
                    identifacionFiscal: "",
                    direccion: "", 
                    empresa: ""
                },
                ocultos: []
            },
            nombreCompleto: '',
            tecnologias: [],
            experiencias: [],
            links: [],
            cursos: [],
            modal : false,
            agendado: false,
            fechaAgendado: '',
            horaAgendado: '',
            medioAgendado: '',
            horaAgendadoFin: '',
            codigoUsuarioAgendado: '',
            modalTerminos: false,
            acceptTerms: false,
            visibleAlert: false,
            loading: false,
            timezone: '',
            mindate: moment(new Date()).format('YYYY-MM-DD'),
            msgAlert: 'Por favor rellene todos los datos',
            tooltipAndroid: false,
            tooltipIos: false,
            tooltipPc: false,
            loader: true
        }
    }

    toggleTooltipAndroid(){
        this.setState({ tooltipAndroid: !this.state.tooltipAndroid })
    };

    toggleTooltipIos(){
        this.setState({ tooltipIos: !this.state.tooltipIos })
    };

    toggleTooltipPc(){
        this.setState({ tooltipPc: !this.state.tooltipPc })
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeSelect = (e) => {
        console.log(e.target.options[e.target.selectedIndex].text)
        this.setState({[e.target.name]: e.target.options[e.target.selectedIndex].text});
    }

    handleChangeTimezone = (e) => {
        console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value});
    }

    /*back = () => {
        this.props.history.replace('/inicio');
    }*/

    componentDidMount(){
        const arreglo = this.props.location.pathname.split('/');
        const id = arreglo[2];
        let datos;
        firebase.db.collection("programadores").doc(`${id}`)
        .get()
        .then(doc => {
            datos = doc.data();
            this.setState({
                datosGenerales: doc.data(),
                tecnologias: datos.tecnologias,
                experiencias: datos.experiencias,
                links: datos.links,
                cursos: datos.cursos,
                nombreCompleto: datos.nombres.split(' ')[0] + ' ' + datos.apellidos.split(' ')[0],
                loader: false
            })
        });
        this.getProfile()
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            this.setState({
                perfil: data
            });
            firebase.db.collection("log").add({ 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                accion: 'Ha revisado un Perfil', 
                tipo: 'empresa',
                info: data,
                modulo: 'Detalle de Programador' 
            });
        });
    }

    toggle = () =>{
        this.setState({ modal: !this.state.modal })
    }
    agendar(){
        const pais = this.state.perfil.pais;
        let array = timezones.list.filter(item => item.name.toLocaleLowerCase().indexOf(pais.toLocaleLowerCase()) > -1);
        let  tomorrow = moment(new Date(),"DD-MM-YYYY").add('days', 1);
        tomorrow = moment(tomorrow).format('YYYY-MM-DD');
        this.setState({ modal: !this.state.modal, timezone: array[0].timezone, mindate: tomorrow, fechaAgendado: tomorrow});
    }

    confirmarAgendar(){
        const self = this;
        if(this.state.fechaAgendado === '' || this.state.horaAgendado === '' || this.state.timezone === ''  ){
            this.setState({msgAlert: '*Por favor complete todos los datos.',  visibleAlert: true })
        }else{
            this.setState({loading: true});
            let objSave = {
                empresa: this.state.perfil,
                fechaAgendado: this.state.fechaAgendado,
                horaAgendado: this.state.horaAgendado,
                horaAgendadoFin: this.state.horaAgendadoFin,
                medioAgendado: this.state.medioAgendado,
                codigoUsuarioAgendado: this.state.codigoUsuarioAgendado,
                uid: firebase.auth.currentUser.uid,
                programador: this.state.datosGenerales,
                seRealizo: false
            }
            let motivo = `  <span><b>Empresa:</b> ${self.state.perfil.nombreEmpresa}.</span><br>
                            <span><b>Email Empresa:</b> ${self.state.perfil.email}.</span><br>
                            <span><b>Programador:</b> ${self.state.datosGenerales.nombres.split(' ')[0]} ${self.state.datosGenerales.apellidos.split(' ')[0]}.</span><br>
                            <span><b>N° whatsapp: </b> ${self.state.datosGenerales.numWhatsapp}.</span><br> 
                            <span><b>Día:</b> ${self.state.fechaAgendado}.</span><br>
                            <span><b>Hora:</b> ${self.state.horaAgendado}.</span><br>
                            <span><b>País:</b> ${self.state.perfil.pais}.</span><br>
                        `
            const data = {
                motivo,
                email: 'info@hispanossoluciones.com'
            }
            firebase.db.collection('agendas').add(objSave)
            .then(res => { 
                self.setState({ loading:false, agendado: true, visibleAlert: false, fechaAgendado: '', horaAgendado: '' }) 
                this.notificarReunion(data); 
            })

        }
    }


    confirmarAgendar2(){
        const self = this;
        if(this.state.fechaAgendado === '' || this.state.horaAgendado === '' || this.state.timezone === ''  ){
            this.setState({msgAlert: '*Por favor complete todos los datos.',  visibleAlert: true })
        }else{

            let objSave = {
                empresa: this.state.perfil,
                fechaAgendado: this.state.fechaAgendado,
                horaAgendado: this.state.horaAgendado,
                horaAgendadoFin: this.state.horaAgendadoFin,
                medioAgendado: this.state.medioAgendado,
                codigoUsuarioAgendado: this.state.codigoUsuarioAgendado,
                uid: firebase.auth.currentUser.uid,
                programador: this.state.datosGenerales,
                seRealizo: false
            }
           
            var dateString  = objSave.fechaAgendado + " " + objSave.horaAgendado;
            let dateStringFin = moment(dateString).add(1, 'hours').format('YYYY-MM-DD HH:mm');
            var timeMin1 = new Date(dateString);
            var timeMax1 = new Date(dateStringFin);
            var timeMin = moment(timeMin1).format('YYYY-MM-DD HH:mm');
            var timeMax = moment(timeMax1).format('YYYY-MM-DD HH:mm');
            let startDate = new Date(timeMin1).toISOString();
            let endDate = new Date(timeMax).toISOString();

            let event = {
                'summary': `Reunion con programador ${self.state.datosGenerales.nombres.split(' ')[0]}  ${self.state.datosGenerales.apellidos.split(' ')[0]}`,
                'location': `${self.state.perfil.datosFiscales.direccion}`,
                'description':  `Agendar entrevista con programador ${self.state.datosGenerales.nombres.split(' ')[0]}  ${self.state.datosGenerales.apellidos.split(' ')[0]}`,
                'start': {
                  'dateTime': startDate,
                  'timeZone': self.state.timezone,
                },
                'end': {
                  'dateTime': endDate,
                  'timeZone': self.state.timezone,
                },
                'recurrence': [
                  'RRULE:FREQ=DAILY;COUNT=2'
                ],
                'attendees': [
                  {'email': self.state.datosGenerales.email},
                  {'email': self.state.perfil.email},
                ],
                'reminders': {
                  'useDefault': false,
                  'overrides': [
                    {'method': 'email', 'minutes': 24 * 60},
                    {'method': 'popup', 'minutes': 10},
                  ],
                },
            };
            
            this.setState({loading: true});
            axios.get(`${config.url}/getEvents`, {
                params: {
                    timeMin, timeMax
                } 
            })
            .then(resp => {
                if(resp.data.events.length > 0){
                    this.setState({loading:false, msgAlert: 'No hay disponibilidad para la Hora seleccionada',  visibleAlert: true })
                } else {   
                    axios.post(`${config.url}/createEvent`, 
                        {
                           event
                        })
                        .then( (response) => {
                            console.log('response', response.data);
                            self.setState({ loading:false, visibleAlert: false });
                            //self.setState({ loading:false, agendado: true, visibleAlert: false })  
                            /*firebase.db.collection('agendas').add(objSave)
                            .then(res => { 
                                self.setState({ loading:false, agendado: true, visibleAlert: false })  
                            })*/
                        })
                }
            })
            .catch(err =>{
                this.setState({msgAlert: 'Error de servidor vuelva a intentar', loading: false, visibleAlert: true })
            })
        }
    }

    async notificarReunion(data){
        return await axios.post(`${config.notificationUrl}/sendmail`,  data )
    }

    calcTime(city, offset, date) {
        let d = new Date(date);
        let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        let nd = new Date(utc + (3600000*offset));
        return nd.toISOString();
    }

    toggleTerminos = () => {
        this.setState({ modal: !this.state.modal })
    }

    changeTerms({target}){
        if (target.checked){
           this.setState({acceptTerms: true, modalTerminos: false, modal: false, agendado: false })
        } else {
            this.setState({acceptTerms: false})
        }
    }

    openModalTerms() {
        this.setState({ modal: false, modalTerminos: true })
    }

    onDismissAlert(){
        this.setState({visibleAlert: !this.state.visibleAlert })
    }

    back = () => {
        if(localStorage.getItem('busquedaactiva')){
            const data = JSON.parse(localStorage.getItem('busquedaactiva'));
            if(data.tecnologias.length === 0 && data.opcionales.length === 0 && data.nivelIngles === 'No' && data.costoMensual === 0){
                localStorage.removeItem('busquedaactiva');
            } else {
                if(data.tecnologias.length === 0 && data.opcionales.length === 0){
                    localStorage.removeItem('busquedaactiva');
                } else {
                    this.props.setBusqueda(data);
                }
            }
        }
        this.props.history.replace('/inicio');
    }

    logout() {
        firebase.logout();
    }

    render(){
        
        return(
            <div>
        
                <Menu link='inicio' admin={false} logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
                {!this.state.loader &&
                    <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                        <div className="row h-100 align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">   

                                    <div className="list-postul-header text-center p-3">
                                        <h2>Hoja de vida de <span id="nombre-programador">{this.state.nombreCompleto}</span>, programador <span id="perfil-programador">{this.state.datosGenerales.tipoPerfiles}</span> </h2>
                                    </div>
                                    <div className="list-postul-content py-3 px-2 px-md-3 w-100">
                                        <div className="datos">
                                            <div className="datos-header w-100 text-center">
                                                <h3 className="heading font-weight-bold">Datos Generales</h3>
                                                <p>
                                                    Datos generales del programador
                                                </p>
                                            </div>
                                            <div className="datos-content w-100 py-3">
                                                <div className="form-group row m-0">
                                                    <div className="col-lg-5">
                                                        <div className="form-holder">
                                                            <Label>Nombres y Apellidos</Label>
                                                            <Input type="text" className="form-control" id="" name="" value={this.state.nombreCompleto} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-holder">
                                                            <Label>Años de experiencia</Label>
                                                            <Input type="text" name="" id="" className="form-control" value={this.state.datosGenerales.anosExperiencia} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 col-lg-4">
                                                        <div className="form-holder ">
                                                            <Label>Ciudad</Label>
                                                            <Input type="text" className="form-control" id="" name="" value={this.state.datosGenerales.ciudad} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {!this.state.perfil.sincosto  &&
                                                <div className="form-group row m-0">
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-holder">
                                                            <Label>Costo hora $USD</Label>
                                                            <Input type="number" name="" id="" className="form-control" value={(((this.state.datosGenerales.costoMes / 4 )) /  (5 * 8))} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-holder ">
                                                            <Label>Costo día $USD</Label>
                                                            <Input type="number" className="form-control" id="" name="" value={(((this.state.datosGenerales.costoMes / 4 )) /  5)} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-holder">
                                                            <Label>Costo semana $USD</Label>
                                                            <Input type="number" name="" id="" className="form-control" value={(this.state.datosGenerales.costoMes / 4 )} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-holder ">
                                                            <Label>Costo mes $USD</Label>
                                                            <Input type="number" className="form-control" id="" name="" value={this.state.datosGenerales.costoMes} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-12 text-center mt-4">
                                                            <Button color="success" type="button" className="px-5 py-2 btn-reunion" onClick={this.agendar} > Agendar reunión</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row m-0">
                                                    <div className="col-md-6">
                                                        <div className="form-holder ">
                                                            <Label>
                                                                Título académico
                                                            </Label>
                                                            <Input type="text" className="form-control" id="" name="" value={this.state.datosGenerales.titulo} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    {this.state.datosGenerales.universidad !== '' &&
                                                        <div className="col-md-6">
                                                            <div className="form-holder ">
                                                                <Label>
                                                                    Universidad/Institucion
                                                                </Label>
                                                                <Input type="text" className="form-control" id="" name="" value={this.state.datosGenerales.universidad} readOnly={true} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-md-6">
                                                        <div className="form-holder ">
                                                            <Label>
                                                                Estudio avanzado
                                                            </Label>
                                                            <Input type="text" className="form-control" id="" name="" value={this.state.datosGenerales.totroTitulotulo} readOnly={true} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-holder ">
                                                            <Label>
                                                                Nivel de Inglés
                                                            </Label>
                                                            <Input type="text" className="form-control" id="" name="" value={this.state.datosGenerales.nivelIngles} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-holder ">
                                                            <Label>
                                                                Tipo de perfil
                                                            </Label>
                                                            <Input type="text" className="form-control" id="" name="" value={this.state.datosGenerales.tipoPerfiles} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="datos p-3 p-md-5">
                                        <div className="datos-header w-100 text-center">
                                            <h3 className="heading font-weight-bold">Datos Técnicos</h3>
                                            <p>
                                                Perfil profesional del programador
                                            </p>
                                        </div>
                                        <h4 className="mt-3">Tecnologias</h4>
                                        {this.state.tecnologias.map((tecnologia, key) => (
                                            <Tecnologia  tecnologia={tecnologia}  key={key} />
                                        ))}
                                        {this.state.tecnologias.length === 0 &&
                                            <Tecnologia  tecnologia={{tecnologia: '', tecnologiaDominio: '', tecnologiaVersion: ''}} />
                                        }
                                        <h4 className="mt-3">Experiencias laborales</h4>
                                        {this.state.experiencias.map((experiencia, key) => (
                                            <Experiencia  experiencia={experiencia}  key={key} />
                                        ))}
                                        {this.state.experiencias.length === 0 &&
                                            <Experiencia  experiencia={{empresaNombre: '',empresaDescripcion: '' }}  />
                                        }
                                        <h4 className="mt-3">Links</h4>
                                        {this.state.links.map((link, key) => (
                                            <Link  link={link}  key={key} />
                                        ))}
                                        {this.state.links.length === 0 &&
                                            <Link  link={{link: '', linkDescripcion: '' }}  />
                                        }
                                        {this.state.cursos.length > 0 &&
                                            <h4 className="mt-3">Cursos</h4>
                                        }
                                        {this.state.cursos.length > 0 &&
                                            this.state.cursos.map((curso, key) => (
                                                <Curso  curso={curso}  key={key}  back={this.back} />
                                            ))
                                        }
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-12 text-center mt-3">
                                                    <Button color="success" type="button" className="px-5 py-2 btn-reunion" onClick={this.agendar} > Agendar reunión</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <div className="form-holder text-center">
                                                <Button color="info"  onClick={this.back} >Volver al listado</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>        
                            
                            </div>
                        </div>
                    </main>
                }
                <Modal isOpen={this.state.modal} toggle={this.toggleTerminos} size="lg" id="modal-terminos" >
                    <ModalHeader className="d-flex justify-content-center">
                        Agendar reunión
                    </ModalHeader>
                    <ModalBody>

                        <Collapse isOpen={!this.state.agendado}>
                        
                            <div id="formulario_ocultar">
                                <div className="form-group row m-0 mt-3">
                                    <div className="col-md-4">
                                        <label>Fecha:</label>
                                        <input name="fechaAgendado" id="fechaAgendado" className="form-control" type="date"  min={this.state.mindate}
                                            value={this.state.fechaAgendado} onChange={this.handleChange}  />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Hora:</label>
                                        <input name="horaAgendado" id="horaAgendado" className="form-control" type="time" value={this.state.horaAgendado} onChange={this.handleChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>País</label>
                                        {/*<input name="horaAgendadoFin" id="horaAgendadoFin" className="form-control" type="time" value={this.state.horaAgendadoFin} onChange={this.handleChange} /> */}
                                        <Input type="select" name="timezone" id="timezone" className="form-group w-100" value={this.state.timezone} onChange={this.handleChangeTimezone} >
                                            <option value="">Seleccione</option>
                                            {timezones.list.map((item, key) => (
                                                <option value={item.timezone} key={key}>{item.name}</option>
                                            ))}
                                        </Input>
                                    </div>

                                </div>
                                {/*<div className="form-group row m-0 mt-3">
                                    <div className="col-md-5">
                                        <label>Via de comunicación:</label>
                                        <Input type="select" name="medioAgendado" id="medioAgendado" className="form-group w-100" value={this.state.medioAgendado} onChange={this.handleChangeSelect} >
                                            <option value="">Seleccione</option>
                                            <option value="Skype">Skype</option>
                                            <option value="Hangouts">Hangouts</option>
                                            <option value="Zoom">Zoom</option>
                                            <option value="Whereby">Whereby</option>
                                            <option value="Meet">Meet</option>
                                        </Input>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Usuario o código para ingresar a la reunión:</label>
                                        <input name="codigoUsuarioAgendado" id="codigoUsuarioAgendado" className="form-control" type="text"  
                                            value={this.state.codigoUsuarioAgendado} onChange={this.handleChange} />
                                    </div>

                                </div>*/}

                                <div className="form-group row justify-content-center m-0 mt-3">
                                    <div className="col-12">
                                        <blockquote className="mx-5">
                                            Todas nuestras reuniones se realizaran mediande la aplicación <strong> Google Meet</strong>, puedes descargarla desde cualquiera de los siguientes enlaces:
                                        </blockquote>
                                    </div>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                    <Tooltip placement="top" isOpen={this.tooltipAndroid} target="linkandroid" toggle={this.toggleTooltipAndroid}>
                                        Android!
                                    </Tooltip>
                                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.meetings" target="_blank" rel="noopener noreferrer" > 
                                        <img src={GooglePlay} alt="android" width="100"id="linkandroid" />
                                    </a>
                                    <Tooltip placement="top" isOpen={this.tooltipIos} target="linkios" toggle={this.toggleTooltipIos}>
                                        IOS!
                                    </Tooltip>
                                    <a href="https://apps.apple.com/us/app/google-meet/id1013231476" target="_blank" rel="noopener noreferrer" >
                                        <img src={AppStore} alt="ios" width="100" id="linkios" />
                                    </a>
                                    <Tooltip placement="top" isOpen={this.tooltipPc} target="linkpc" toggle={this.toggleTooltipPc}>
                                        PC!
                                    </Tooltip>
                                    <a href="https://meet.google.com/" target="_blank" rel="noopener noreferrer" >
                                        <img src={PcImg} alt="pc" width="100" id="linkpc" />
                                    </a>
                                </div>

                                <div className="form-group row m-0 mt-2">
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center'}} >
                                        <Alert color="danger" isOpen={this.state.visibleAlert} toggle={this.onDismissAlert}  style={{ display: 'flex', width: '100%'}} >
                                            {this.state.msgAlert}
                                        </Alert>
                                       
                                        {this.state.loading === true && (
                                            <ReactLoading type="bars" color="#3e4061" />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row m-0 mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <blockquote className="mx-5">
                                                <strong> Nota:</strong> La hora de la reunion corresponde al uso horario de la capital de <strong>{this.state.perfil.pais}</strong>, que es el pais que selecciono la empresa para la reunion.
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group row justify-content-center m-0 mt-3">
                                    <Button type="submit" className="btn btn-success py-2 px-5" onClick={this.confirmarAgendar} >Agendar</Button>
                                </div>
                            </div>
                        </Collapse>
                        
                        <Collapse
                            isOpen={this.state.agendado}
                        >
                            <div id="reunion_agendada">
                                <div className="row">
                                    <div className="col-12 px-3">
                                        <h3 className="mb-3 font-weight-bold text-center">Reunión Agendada</h3>
                                        <p>Gracias por agendar se enviara un enlace con la reunión a <b>{this.state.perfil.email}</b>, recuerde revisar las <a href="javascript:void(0)" id="condiciones" className="font-weight-bold" onClick={this.openModalTerms}  >
                                                condiciones para recibir el servicio</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        
                        
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.modalTerminos} toggle={this.toggleTerminos} size="lg" id="modal-terminos" >
                    <ModalHeader className="d-flex justify-content-center">
                        Condiciones de servicio
                    </ModalHeader>
                    <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-unstyled">
                                <li>
                                    <b>¿Quienes somos?</b>: Hispanos Soluciones C.A. es una empresa de Software venezolana con clientes en el extranjero, posee sede en la ciudad de Maturín y cuenta con aproximadamente 30 programadores
                                    distribuidos en toda Venezuela, es dirigida por los hermanos Jonathan Araul y Adelys Araul y ha generado trabajo a más de 200 programadores en 5 años de trayectoria.
                                </li>
                                <li>
                                    <b>Forma de trabajo</b>: Se trabaja en la modalidad de teletrabajo, lo que implica un cumplimiento de horario lo que es opuesto al trabajo freelance, únicamente laboramos a TIEMPO COMPLETO, si
                                    usted no dispone del tiempo necesario para cumplir jornadas de 8h diarias de lunes a viernes por favor desista de trabajar con nosotros, no buscamos desarrolladores por horas si no a tiempo completo.
                                </li>
                                <li>
                                    <b>Horarios de trabajo</b>: La hora de inicio puede variar entre las 7AM a las 11AM dependiendo de la ubicaci&oacute;n de nuestro cliente, sin embargo lo m&aacute;s com&uacute;n es iniciar a
                                    las 9AM, con una hora para el almuerzo.
                                </li>
                                <li>
                                    <b>Fallos electricos y problemas de conexi&oacute;n</b>: Cuando se presentan este tipo de dificultades los desarrolladores deben recuperar sus horas en horario nocturno, pero siempre se debe
                                    trabajar 8h diarias, de lunes a viernes sin excepci&oacute;n.
                                </li>
                                <li>
                                    <b>Formas de pago</b>: Pagamos en d&oacute;lares (USD) a todos los miembros, por ende su aspiraci&oacute;n salarial mensual deber&aacute; ser en esta moneda, no pagamos en bolivares, se efectuan
                                    pagos quincenales, el 15 y &uacute;ltimo d&iacute;a de cada mes, a trav&eacute;s de la plataforma Localbitcoins.com donde es posible vender las divisas en cuesti&oacute;n de minutos, si ud no
                                    conoce esta plataforma haga clic aqu&iacute; para ver un tutorial, tambi&eacute;n nos puede suministrar un c&oacute;digo de recepci&oacute;n de Uphold (tarjeta verde) en caso de que desee almacenar
                                    sus fondos en otra plataforma.
                                </li>
                                <li>
                                    <b>Seguimiento</b>: Utilizamos WAKATIME (plugin que se instala en su IDE o EDITOR) para llevar un control de las horas tecleadas por desarrollador. Adem&aacute;s cada desarrollador debe vaciar
                                    un reporte diario en hispanossoluciones.com y subir por lo minimo un commit diario, en donde debe justificar sus 8h diarias. Nos preocupamos por mantener satisfechos a nuestros clientes por eso
                                    exigimos pruebas de trabajo a todos nuestros miembros sin excepci&oacute;n. El incumplimiento de este reglamento acarrea penalizaciones en el salario.
                                </li>
                                <li>
                                    <b>¿Como ser contratado?</b>: Para tener &eacute;xito; en este proceso de postulaci&oacute;n ud deber&aacute; ser extremadamente detallista a la hora de describir sus habilidades y experiencias
                                    previas. Considere que lo que ingrese en este formulario ser&aacute; comparado con los registros de otros programadores, ud no debe olvidar ninguna de sus habilidades o experiencias, se le recomienda
                                    ingresar links a sitios webs/aplicaciones que esten p&uacute;blicas o a repositorios p&uacute;blicos, mientras m&aacute;s sea la evidencia de su experiencia m&aacute;s posibilidades tendr&aacute;
                                    de ser contactado r&aacute;pidamente, adem&aacute;s deber&aacute; ingresar un n&uacute;mero telef&oacute;nico con whatsapp v&aacute;lido y por &uacute;ltimo su aspiraci&oacute;n salarial deber&aacute;
                                    coincidir con nuestros rangos salariales, estos rangos salariales no son p&uacute;blicos, con el objetivo de tener seguridad de que su aspiraci&oacute;n hace match con nuestras posibilidades
                                    econ&oacute;micas.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-end pr-4">
                            <input type="checkbox" className="mr-2 my-3" name="" id="check_condiciones" checked={this.state.acceptTerms} onChange={this.changeTerms} /> Acepto las condiciones de servicio
                        </div>
                    </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
    
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        busqueda: state.busqueda
    }
}

const mapDispatchToProps = {
    setPerfil,
    setBusqueda,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (DetalleProgramador))