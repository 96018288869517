import React from 'react'
import { Redirect } from 'react-router-dom'
import firebase from './firebase/firebase';

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        const isAuthenticated = firebase.auth.currentUser ? true : false;
      
        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/inicio' }} />
        );
    }
}

export default ProtectedRoute;