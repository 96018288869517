import React  from 'react';
import { Input, Label  } from 'reactstrap';

function Experiencia(props) {
    return <div className="form-group row m-0">
    <div className="col-12">
        <div className="form-holder">
            <Label>Empresa</Label>
            <Input type="text" className="form-control" id="" name="" value={props.experiencia.empresaNombre} readOnly={true} />
        </div>
    </div>
    <div className="col-12">
        <div className="form-holder">
            <Label><b>Descripcion de las actividades</b></Label>
            <p style={{textAlign: 'justify'}}>
                {props.experiencia.empresaDescripcion}
            </p>
        </div>
    </div>
</div>
}

export default Experiencia;