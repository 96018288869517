import React, { Component } from 'react';
import firebase from '../firebase/firebase';
import { Input, Button } from 'reactstrap';
import  Menu  from '../components/Menu';
import moment from 'moment';
import {Link} from "react-router-dom";

import { setBusqueda } from '../actions';
import { connect }  from  'react-redux';

class Busquedas  extends Component {
    constructor(props) {
        super(props);

        this.changeActive = this.changeActive.bind(this);
        this.verResultados = this.verResultados.bind(this);

        this.state = {
            busquedas: [],
            loader: true,
            perfil: {}
        }
    }

    componentDidMount(){
        this.getProfile();
        this.loadBusquedas();

        let query = firebase.db.collection("busquedas");
            query.onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(change => {
                    if (change.type === 'modified') {
                        let data = change.doc.data();
                        data.id = change.doc.id;
                        let busquedasTemp = this.state.busquedas;
                        busquedasTemp.forEach((item, index) => {
                            if(item.id === data.id ){
                                busquedasTemp[index] = data;
                            }
                        })
                        this.setState({ busquedas: busquedasTemp });
                    }
                });
            }, err => {
                console.log(`Encountered error: ${err}`);
            });
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            this.setState({
                perfil: data
            });
            firebase.db.collection("log").add({ 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                accion: 'Entro en la ruta Busquedas', 
                tipo: 'empresa',
                info: data,
                modulo: 'Búsquedas' 
            });
        });
    }

    loadBusquedas(){
        firebase.db.collection("busquedas")
        .where("uid", "==", `${firebase.auth.currentUser.uid}`)
        .get()
        .then(querySnapshot => {
            let objetoBusqueda;
            let arrayBusqueda = []
            querySnapshot.forEach((doc) => {
                objetoBusqueda = doc.data();
                objetoBusqueda.id = doc.id
                arrayBusqueda.push(objetoBusqueda);
                this.ejecutarBusqueda(objetoBusqueda);
            });
            let newList =  arrayBusqueda.sort(this.sortByDate);
            this.setState({
                busquedas: newList,
                loader: false
            })
        });
    }

    sortByDate(a,b){  
        var dateA = new Date(a.fecha).getTime();
        var dateB = new Date(b.fecha).getTime();
        return dateA < dateB ? 1 : -1;  
    }

    changeActive(event){
        let busquedas = this.state.busquedas
            busquedas.forEach(busq => {
            if (busq.id === event.target.value){
                busq.activa =  event.target.checked
                firebase.db.collection("busquedas").doc(`${busq.id}`).update({activa: event.target.checked});
            }
        })
        this.setState({busquedas: busquedas});
    }

    logout() {
        firebase.logout();
    }

    verResultados(data){
        this.props.setBusqueda(data)
    }

    ejecutarBusqueda(busq){

        let ref; 
        if(busq.nivelIngles === 'No' && busq.costoMensual === 0){
            ref = firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
        } else if(busq.nivelIngles !== 'No' && busq.costoMensual === 0) {
            ref = firebase.db.collection("programadores")
            .where("nivelIngles", "==", busq.nivelIngles)
            .where("estado", "==", "Habilitado")
        } else if(busq.nivelIngles === 'No' && busq.costoMensual !== 0){
            ref = firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
            .where("costoMes", "<=", busq.costoMensual)
        } else {
            ref = firebase.db.collection("programadores")
            .where("nivelIngles", "==", busq.nivelIngles)
            .where("estado", "==", "Habilitado")
            .where("costoMes", "<=", busq.costoMensual)
        }

        let arregloProgramadores = [];
        let arrayFinal = [];
        let tecEncontradas = [];
        const tecnologiasSeleccionadas = busq.tecnologias;

        ref
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach((doc) => { 
                let armar = doc.data(); //aca estan todos los datos segun los filtros seleccionados (no incluye filtro las tecnologias)
                armar.id = doc.id;
                arregloProgramadores.push(armar)
            });
            //Aplicar filtro de tecnologias a los registros devueltos segun los otros filtros
            arregloProgramadores.forEach((row, index) => { //recorrer todos los registros de programadores sin tomar los ocultos
                row.tecnologias.forEach((tec, i) => { //recorrer todas las tecnologias de un programador
                    if(tecnologiasSeleccionadas.length === 0){ // si no ha sleccionado ninguna tecnologia en la busqueda todo bien
                        arrayFinal = arregloProgramadores;
                    } else{// si ha sleccionado alguna tecnologia en la busqueda
                        tecnologiasSeleccionadas.forEach((item, pos)=>{ // repetir segun las tecnologias seleccionadas 
                            if(item.tiempo === 0){
                                if(tec.tecnologia === item.nombre){// comparar si alguna de la tecnologia seleccionada es igual a alguna de las tecnologias de un programador, este es valido 
                                    tecEncontradas.push(true);
                                } else {
                                    tecEncontradas.push(false);
                                }
                            } else {
                                if(tec.tecnologia === item.nombre && tec.tecnologiaTipo === item.periodo && parseInt(tec.tecnologiaAños, 10) >= item.tiempo  ){
                                    tecEncontradas.push(true);
                                } else {
                                    tecEncontradas.push(false);
                                }
                            }
                        })
                    }
                });
                let serached = tecEncontradas.filter(fila => fila === true); 
                //con esta condicion se que las tecnologias que seleccione en el filtro, estan o no en las tecnologias de un programador
                if(tecnologiasSeleccionadas.length > 0){
                    if(serached.length === tecnologiasSeleccionadas.length){
                        arrayFinal.push(row);
                    }
                }
                tecEncontradas = [];
                serached  = [];
            });
            firebase.db.collection("busquedas").doc(`${busq.id}`).update({resultados: arrayFinal.length});
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });

    }


    render(){
        return(
            <div>
                <Menu link='misbusquedas' admin={false} logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!this.state.loader &&   
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row w-100 h-100 align-items-center justify-content-center">
                        <div className="row p-0 w-100 ">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Búsquedas</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-5">
                                <div className="search-true">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="d-table-cell">Obligatotrio</th>
                                                    <th scope="col" className="d-table-cell">Opcionales</th>
                                                    <th scope="col" className="d-none d-lg-table-cell">Fecha</th>
                                                    {!this.state.perfil.sincosto && <th scope="col" className="d-table-cell">Costo Mes</th> }
                                                    <th scope="col" className="d-none d-sm-table-cell">Habla Ingles</th>
                                                    <th scope="col" className="d-none d-lg-table-cell">Resultados</th>
                                                    <th scope="col" className="text-center">
                                                        activa
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.busquedas.map((data, key) => (
                                                <tr key={key}>
                                                    <td className="d-table-cell">
                                                        {data.tecnologias.map((tec, k) => ( 
                                                            <div  key={k}>
                                                                <span><b>{tec.nombre}</b>&nbsp;{tec.tiempo}&nbsp;{tec.periodo}</span><br/>
                                                            </div>
                                                        ))}
                                                    </td>
                                                    {data.opcionales.length === 0 &&
                                                        <td className="d-table-cell">Ninguna</td>
                                                    }
                                                    {data.opcionales.length > 0 &&
                                                        <td className="d-table-cell">
                                                            {data.opcionales.map((tec, k) => ( 
                                                                <div  key={k}>
                                                                    <span><b>{tec.nombre}</b>&nbsp;{tec.tiempo}&nbsp;{tec.periodo}</span><br/>
                                                                </div>
                                                            ))}
                                                        </td>
                                                    }
                                                    <td className="d-none d-lg-table-cell">
                                                        {moment(data.fecha).format('YYYY-MM-DD')}
                                                    </td>
                                                    {!this.state.perfil.sincosto && <td className="d-none d-lg-table-cell">{data.costoMensual}</td> }
                                                    <td className="d-none d-lg-table-cell">{data.nivelIngles}</td>
                                                    <td className="d-none d-sm-table-cell">
                                                        { data.resultados > 0 ? 
                                                            <Link 
                                                                to={{
                                                                    pathname: "/inicio",
                                                                }}
                                                            >   
                                                                <Button color="link" 
                                                                        className="font-weight-bold" onClick={(e) => this.verResultados(data) }  >
                                                                    {data.resultados} Ver
                                                                </Button>
                                                            </Link> : 'Aún no hay resultados'  }
                                                    </td>
                                                    <td className="text-center">
                                                        <Input  type="checkbox" value={data.id} checked={data.activa} onChange={this.changeActive} style={{position:'relative'}} />     
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        busqueda: state.busqueda,
    }
}

const mapDispatchToProps = {
    setBusqueda,
}

export default connect(mapStateToProps, mapDispatchToProps) (Busquedas);

//export default Busquedas;