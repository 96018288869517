import React, {Component} from 'react';
import { Button, Form, Label, Input, Alert  } from 'reactstrap';
import {Link} from "react-router-dom";
import firebase from '../firebase/firebase';
import { setPerfil } from '../actions';
import { connect }  from  'react-redux';
import moment from 'moment';

class Login extends Component {
    constructor(props){
        super(props);
        this.login = this.login.bind(this);
    }

    state = {
        username: '',
        password: '',
        errorMessage: ''
    }

    /*componentDidMount(){
        mocks.tecnologiasData.forEach((item, key ) => {
            console.log(item);
            firebase.db.collection('tecnologias').add({name: item})
        })
    }*/
    async login(event) {
        event.preventDefault();
        if (this.state.username === '' || this.state.password === '' ) {
            this.setState({errorMessage: '*Por favor introduzca todos los campos'});
        } else {
            try {
                const email = this.state.username.trim();
                const password = this.state.password.trim();
                let {user} = await firebase.auth.signInWithEmailAndPassword(email, password);
                firebase.db.collection("empresas").doc(`${user.uid}`) //optener los datos de perfil del usuario logueado
                    .get()
                    .then(doc => {
                        const data = doc.data();
                        if (doc.exists) {
                            this.props.setPerfil(data)
                            firebase.db.collection("log").add({ 
                                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                                accion: 'inicio sesión',
                                tipo: 'empresa',
                                info: data,
                                modulo: 'Login' 
                            });
                            if(data.admin){
                                this.props.history.replace('/inicio')
                            } else {
                                this.props.setPerfil(null)
                                this.setState({errorMessage: '*No esta disponible el inicio de sesíon'})
                            }
                        } else {
                            this.props.setPerfil(null)
                            this.props.history.replace('/inicio')
                        } 
                    }).catch(err => this.setState({errorMessage: '*Error de servidor, intenta iniciar sesión nuevamente'}))
            } catch (err) {
                let msg = '';
                    switch (err.code) {
                        case 'auth/wrong-password':
                            msg = '*Contraseña incorrecta.';
                            break;
                        case 'auth/invalid-email':
                            msg = '*Formato de email invalido.'
                            break;
                        case 'auth/user-disabled':
                            msg = '*La cuenta de correo ingresada ha sido desactivada.';
                            break;
                        case 'auth/user-not-found':
                            msg = '*Correo no registrado';
                            break;
                        default:
                            msg ='*Correo y/o Contraseña invalida.';
                            break;
                    }
                this.setState({errorMessage: msg});
            }
            /*await firebase.login(this.state.username, this.state.password)
                .then(resp => {
                    this.setState({
                        username: '',
                        password: '',
                        errorMessage: ''
                    });
                    firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`) //optener los datos de perfil del usuario logueado
                    .get()
                    .then(doc => {
                        const data = doc.data();
                        if (doc.exists) {
                            this.props.setPerfil(data)
                        } else {
                            this.props.setPerfil(null)
                        } 
                        this.props.history.replace('/inicio')
                        firebase.db.collection("log").doc(doc.id).set({  });  
                    }).catch(err => this.setState({errorMessage: '*Error de servidor, intenta iniciar sesión nuevamente'}))
                }).catch(err => this.setState({errorMessage: '*Usuario y/o password invalidos'}))*/
        }
        
    }

    render(){
        return(
            
            <main className="container d-flex align-items-center justify-content-center">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="row">
                        <Form id="login_form" className="form-login rounded border border-secondary shadow-lg" onSubmit={this.login}>
                            <div className="form-header">
                                <div className="row">
                                    <div className="col-12 py-3">
                                        <h2 className="text-center">Login</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-content px-4 py-5">
                                    <div className="form-group row m-0 mt-3">
                                        <div className="col-12">
                                            <Label for="user">Correo electrónico:</Label>
                                        </div>
                                        <div className="col-12">
                                            <Input className="form-control" type="text" placeholder="Introduzca su correo electrónico" name="username" onChange={e => this.setState({ username: e.target.value})} 
                                             onKeyUp={e => this.setState({ errorMessage: ''})} />
                                        </div>
                                    </div>
                                    <div className="form-group row m-0 mt-3">
                                        <div className="col-12">
                                            <Label for="password">Contraseña:</Label>
                                        </div>
                                        <div className="col-12">
                                            <Input className="form-control" type="password" placeholder="Introduzca su contraseña" name="password" onChange={e => this.setState({ password: e.target.value})} 
                                            onKeyUp={e => this.setState({ errorMessage: ''})} />
                                        </div>
                                    </div>
                                    {this.state.errorMessage !== '' &&
                                        <div className="form-group row m-0 mt-3">
                                            <div className="col-12 text-center">
                                                <Alert color="danger">
                                                    {this.state.errorMessage}
                                                </Alert>
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group row justify-content-center m-0 mt-5">
                                        <Button color="success">Ingresar</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-footer">
                                <div className="row mb-5">
                                    <div className="col-12 text-center">
                                        <Link to="/forgotpassword">¿Olvidó su contraseña?</Link>
                                    </div>
                                    {/*<div className="col-12 text-center">
                                        ¿No tienes una cuenta? Registrate  <Link to="/register">aquí</Link> 
                                    </div> */}
                                </div>
                            </div>
        
        
                        </Form>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        perfil: state.perfil,
        //token: state.token
    }
}

const mapDispatchToProps = {
    setPerfil,
}

export default connect(mapStateToProps, mapDispatchToProps) (Login);