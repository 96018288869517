import React  from 'react';
import { Input, Label  } from 'reactstrap';
import StarRatings from 'react-star-ratings';

function Tecnologia(props) {
    return  <div className="form-group row m-0">
    <div className="col-md-3">
        <div className="form-holder">
            <Label>Tecnologia</Label>
            <Input type="text" className="form-control" id="" name="" value={props.tecnologia.tecnologia} readOnly={true} />
        </div>
    </div>

    <div className="col-md-3">
        <div className="form-holder">
            <Label>Tiempo de experiencia</Label>

            <div className="row">
                <div className="col-md-6">
                    <Input type="text" className="form-control" id="" name="" value={props.tecnologia.tecnologiaAños} readOnly={true}/>
                </div>
                <div className="col-md-6">
                    <Input type="text" className="form-control" id="" name="" value={props.tecnologia.tecnologiaTipo} readOnly={true}/>
                </div>
            </div>
        </div>
    </div>

    <div className="col-md-3">
        <div className="form-holder">
            <Label>
                Dominio
            </Label>
            <StarRatings
                rating={props.tecnologia.dominio}
                starRatedColor="rgb(10,28,70)"
                starHoverColor="rgb(62,64,97)"
                numberOfStars={5}
                name='rating'
                starDimension='30px'
                disabled={true}
            />
            {/*<Input type="text" className="form-control" id="" name="" value={props.tecnologia.tecnologiaDominio} readOnly={true} />*/}
        </div>
    </div>
    <div className="col-md-3">
        <div className="form-holder">

            <Label>Version</Label>
            <Input type="text" className="form-control" id="" name="" value={props.tecnologia.tecnologiaVersion} readOnly={true}/>

        </div>
    </div>
</div>
}

export default Tecnologia;