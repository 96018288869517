import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import Menu from '../components/Menu';
import mocks from '../mocks/data';
import moment from 'moment';
import CSVReader from "react-csv-reader";
import { FaFileImport, FaTrash } from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay';
import { Input, Button, Label, Alert, Form, InputGroup,InputGroupAddon, } from 'reactstrap';
import generator from 'generate-password';
import axios from 'axios';
import config from '../config/index';

const toParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

class Empresas extends Component {
    constructor(props){
        super(props);

        this.handleChange  =  this.handleChange.bind(this);
        this.register  =  this.register.bind(this);
        this.importEmpresas = this.importEmpresas.bind(this);
        this.handleForce = this.handleForce.bind(this);
        this.handleErrorEmpresas =   this.handleErrorEmpresas.bind(this);
        this.onDismissAlert =   this.onDismissAlert.bind(this);
        this.generatePassword = this.generatePassword.bind(this);
        this.removePassword = this.removePassword.bind(this);
       
        this.state = {
            pais: '',
            nombreEmpresa: '',
            email: '',
            password: '',
            passwordGenerado: '',
            isGeneratePassword: false,
            errorMessage: '',
            paises: mocks.paises,
            colorAlert: 'danger',
            visibleAlert: false,
            typeAlert: '',
            messageAlert: '',
            loading: false,
        }
    
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    async register(event){
        event.preventDefault();
        const self = this;
        if (this.state.pais === ''  || this.state.email === '' || this.state.password === '' || this.state.nombreEmpresa === '' ) {
            this.setState({colorAlert: 'danger', errorMessage: '*Por favor introduzca todos los campos'}); 
        } else {
            const email = this.state.email.trim();
            const password = this.state.password.trim();
            try {
                let response = await this.createUser(email, password);
                if(response.data.success === true){
                    let {user} =  response.data;
                    self.saveDatos(this.state.nombreEmpresa, this.state.pais, user);
                } else {
                    self.buildErrorMesagge(response.data.error)
                }
            } catch (err){
                this.setState({errorMessage: '*Error de servidor, intenta nuevamente'});
            }
        }
    }

    buildErrorMesagge = (err) => {
        let msg = '';
        switch (err.code) {
            case 'auth/email-already-in-use':
                msg ='*Email ya se encuentra registrado en nuestra plataforma';
                break;
            case 'auth/invalid-email':
                msg ='*Formato de email invalido';
                break;
            case 'auth/operation-not-allowed':
                msg ='*App no tiene habilitada la autenticacion mediante correo y contraseña.';
                break;
            case 'auth/weak-password':
                msg ='*La contraseña no es lo suficientemente segura.';
                break;
            case 'auth/email-already-exists':
                msg ='*Email ya se encuentra registrado en nuestra plataforma';
                break;
            default: 
                break;
        }
        this.setState({errorMessage: msg});
    }
    
    async saveDatos(nombreEmpresa, pais, user) {
        const self = this;
        let datosEmpresa = {
            admin: false,
            contrasena: '',
            email: user.email,
            fechaCreacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            nombreEmpresa: nombreEmpresa,
            numWhatsapp: '',
            pais: pais,
            representanteLegal: '',
            rol: 'empresa',
            userId: user.uid,
            usuario: user.email,
            datosFiscales: {identificacionFiscal: '',direccion: '', ciudad: ''},
            ocultos: []
        }
        await firebase.db.collection('empresas').doc(`${user.uid}`).set(datosEmpresa)
            .then( () =>{
                self.setState({
                    colorAlert: 'success', 
                    errorMessage: 'Empresa Registrada exitosamente', 
                    email: '', 
                    pais: '', 
                    password: '', 
                    nombreEmpresa: '',
                    isGeneratePassword: false,
                    passwordGenerado: '',
                    pais: ''
                });
                if(self.state.isGeneratePassword){
                    firebase.db.collection('passwords').add({ uid: user.uid, password: self.state.password  })
                }
                setTimeout(() => { 
                    self.setState({ errorMessage: '' })
                }, 3000); 
            }).catch(err => console.log(err.message));
       
    }

    importEmpresas(){
        const fileinput = document.getElementById('fileempresas');
        fileinput.click();
    }

    createUser = async (email, password) => {
        const data = {email, password};
        return await axios.post(`${config.notificationUrl}/createUser`,  data );    
    }

    handleForce = async (data, fileInfo) => {
        this.setState({ loading: true });
        let contrasena = '';
        let errorsCount = 0;
        for (let index = 0; index < data.length; index++) {
            const value = data[index];
            if(typeof value.pais === 'undefined' || typeof value.empresa === 'undefined' || typeof value.email === 'undefined' || typeof value.password === 'undefined') {
                this.setState({
                    typeAlert: 'danger',
                    messageAlert: 'Alguna columna del archivo no tiene el formato correcto, deben tener el siguiente formato: pais|empresa|email|password',
                });
                setTimeout(() =>{ 
                    this.setState({ visibleAlert: true }) 
                    return;
                }, 200);
            } else {
                if(typeof value.password === 'number'){
                    contrasena = value.password.toString();
                } else {
                    contrasena  = value.password;
                }
                const username = value.email.trim();
                const password = contrasena.trim();
                try {
                    let response = await this.createUser(username, password);
                    if(response.data.success === true){
                        let {user} =  response.data;
                        let datosEmpresa = {
                            admin: false,
                            contrasena: '',
                            email: value.email,
                            fechaCreacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            nombreEmpresa: value.empresa,
                            numWhatsapp: '',
                            pais: value.pais,
                            representanteLegal: '',
                            rol: 'empresa',
                            userId: user.uid,
                            usuario: user.email,
                            datosFiscales: {identificacionFiscal: '',direccion: '', ciudad: ''},
                            ocultos: []
                        }
                        await firebase.db.collection('empresas').doc(`${user.uid}`).set(datosEmpresa);
                    } else {
                        errorsCount ++;
                        this.buildErrorMesagge(response.data.error)
                    }
                } catch (err){
                    errorsCount ++;
                    this.setState({errorMessage: '*Error de servidor, intenta nuevamente'});
                }
            }
        }
        this.setState({ loading: false });
        if(errorsCount === 0){
            this.setState({ typeAlert: 'success', messageAlert: 'Datos cagados exitosamente',  visibleAlert: true  });
        } else {
            this.setState({ 
                typeAlert: 'warning', 
                messageAlert: `${errorsCount} registros no se cargaron correctamente`,  
                visibleAlert: true  
            });
        }
    }

    handleErrorEmpresas(event){
        console.log(event)
    }

    onDismissAlert(){
        this.setState({visibleAlert: !this.state.visibleAlert })
    }

    generatePassword(){
        const generado = generator.generate({
            length: 10,
            numbers: true,
            symbols: false
        });
        this.setState({ password: generado, isGeneratePassword: true, passwordGenerado: generado });
    }

    removePassword(){
        this.setState({ password: '', isGeneratePassword: false, passwordGenerado: '' });
    }

    render(){
        return(
            <div>
                <Menu link='empresas' admin={true} logout={this.logout} ></Menu>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Cargando...'
                >
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row h-100 align-items-center justify-content-center w-100">
                        <div className="row p-0 w-100">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100"> 
                                <div className="list-postul-header text-center p-3">
                                    <h2>Empresas</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-3 w-100">
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col-7">
                                            <Form onSubmit={this.register} >
                                                <div className="row w-100">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Label for="pais">País:</Label>
                                                            <Input name="pais" id="pais" className="form-control" type="select" onChange={e => this.setState({ pais: e.target.value})} >
                                                                <option value="" key="0" >Seleccione</option>
                                                                {
                                                                    this.state.paises.map((item, key) =>(
                                                                        <option value={item} key={key} >{item}</option>
                                                                    ))
                                                                }
                                                            </Input>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                        <Label>Email</Label> 
                                                        <Input type="email" name="email" value={this.state.email} 
                                                            onChange={this.handleChange}  
                                                            onKeyUp={(e)=> this.setState({ errorMessage: ''})} 
                                                        />    
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row w-100">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                        <Label>Nombre de la Empresa</Label> 
                                                        <Input type="text" name="nombreEmpresa" value={this.state.nombreEmpresa} 
                                                            onChange={this.handleChange}
                                                            onKeyUp={(e)=> this.setState({ errorMessage: ''})} 
                                                         />    
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Label>Contraseña</Label> 
                                                            <InputGroup>
                                                                <Input type="password" name="password" value={this.state.password} 
                                                                    minLength="6"
                                                                    onChange={this.handleChange}
                                                                    onKeyUp={(e)=> this.setState({ errorMessage: ''})}   
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="primary" type="button" onClick={this.generatePassword}>Generar contraseña</Button>
                                                                </InputGroupAddon>  
                                                            </InputGroup> 
                                                            {this.state.isGeneratePassword &&
                                                                <Alert color="info" style={{marginTop: 5 }} >
                                                                    <label> Contraseña Generada: <b>{this.state.passwordGenerado}</b> </label> 
                                                                    <FaTrash style={{ cursor: 'pointer', marginLeft: 5}} onClick={this.removePassword} />
                                                                </Alert>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.errorMessage !== '' &&
                                                    <div className="form-group row m-0 mt-3">
                                                        <div className="col-12 text-center">
                                                            <Alert color={this.state.colorAlert}>
                                                                {this.state.errorMessage}
                                                            </Alert>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row w-100">
                                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                                        <Button type="submit" color="success">
                                                            Registrar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                            <div className="row w-100">
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row w-100">
                                                <div className="col-12" style={{ textAlign: 'center' }}>
                                                    <Button id="importarTecnologia" color="success" size="sm" onClick={this.importEmpresas}>
                                                        <FaFileImport /> Importar CSV
                                                    </Button>
                                                    <CSVReader
                                                        inputStyle={{display: 'none'}}
                                                        inputId="fileempresas"
                                                        cssClass="react-csv-input"
                                                        label=""
                                                        onFileLoaded={this.handleForce}
                                                        onError={this.handleErrorEmpresas}
                                                        parserOptions={toParseOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row mt-3">
                                                <div className="col">
                                                    <Alert color={this.state.typeAlert} isOpen={this.state.visibleAlert} toggle={this.onDismissAlert} >
                                                        {this.state.messageAlert}
                                                    </Alert>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                </LoadingOverlay>
            </div>
        );
    }
}

export default Empresas;