import React, { Component } from 'react';
import firebase from '../firebase/firebase';
import { Input, Label } from 'reactstrap';
import Menu from '../components/Menu';
import moment from 'moment';

class BusquedasAdmin  extends Component {
    constructor(props) {
        super(props);

        this.handleChangeSelect  =  this.handleChangeSelect.bind(this); 
        this.changeOrder  =  this.changeOrder.bind(this); 

        this.state = {
            busquedas: [],
            busquedasTodas: [],
            empresas: [],
            empresa: 'todas',
            orderBy: 'nombre',
            loader: true,
            perfil: {}
        }
    }

    componentDidMount(){
        this.getProfile();
        this.loadBusquedas();
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            this.setState({
                perfil: data
            });
            firebase.db.collection("log").add({ 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                accion: 'Entro en la ruta Busquedas', 
                tipo: 'empresa',
                info: data,
                modulo: 'Búsquedas' 
            });
        });
    }

    loadBusquedas(){
        firebase.db.collection("busquedas")
        .get()
        .then(querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data());
            const empresasConBusquedas = [...new Set(data.map(x => x.empresa.nombreEmpresa))];
            const ordenar = data.sort(this.sortByName)
            this.setState({
                busquedas: ordenar, busquedasTodas: ordenar, empresas: empresasConBusquedas.sort(), loader: false
            })
        });
    }

    filter(filtro) {
        firebase.db.collection("busquedas")
        .where("empresa.nombreEmpresa", "==", `${filtro}`)
        .get()
        .then(querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data());
            this.setState({busquedas: data})
        });
    }

    handleChangeSelect = (e) => {
        const filtro = e.target.value;
        this.setState({[e.target.name]: e.target.value});
        if (filtro === 'todas') {
            this.setState({busquedas: this.state.busquedasTodas})
        } else {
            this.setState({busquedas: this.state.busquedasTodas.filter(row => row.empresa.nombreEmpresa === filtro)})
        }
    }

    logout() {
        firebase.logout();
    }

    changeOrder = (e) => {
        this.setState({[e.target.name]: e.target.value});
        let newList = this.state.busquedas;
        let ordenado = [];
        if(e.target.value === 'nombre'){
            ordenado = newList.sort(this.sortByName);
        } else if(e.target.value === 'fecha'){
            ordenado = newList.sort(this.sortByDate);
        }
        this.setState({busquedas: ordenado}); 
    }

    sortByName(a,b){
        var empA = a.empresa.nombreEmpresa;
        var empB = b.empresa.nombreEmpresa
        return empA > empB ? 1 : -1; 
    }

    sortByDate(a,b){  
        var dateA = new Date(a.fecha).getTime();
        var dateB = new Date(b.fecha).getTime();
        return dateA < dateB ? 1 : -1;  
    }


    render(){
        return(
            <div>

                <Menu link='busquedas' admin={true} logout={this.logout} ></Menu>

                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!this.state.loader &&       
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row w-100 h-100 align-items-center justify-content-center">
                        <div className="row p-0 w-100 ">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Busquedas por Empresas</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2">

                                    <div className="row mb-3 justify-content-start">
                                        <div className="col-md-5 col-lg-4 mb-3">
                                            <Label>Empresa</Label>
                                            <Input type="select" name="empresa" id="empresa" className="w-100"  
                                                value={this.state.empresa} onChange={this.handleChangeSelect} bsSize="sm">
                                                <option value="todas">Todas</option>
                                                {this.state.empresas.map((item, key) => (
                                                    <option value={item} key={key} >{item}</option>
                                                ))}
                                            
                                            </Input>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-md-5 col-lg-4 mb-3">
                                        <Label>Ordenar por</Label>
                                            <Input type="select" name="orderBy" id="orderBy" className="w-100"  
                                                value={this.state.orderBy} onChange={this.changeOrder} bsSize="sm">
                                                <option value="nombre">Nombre de empresa</option>
                                                <option value="fecha">Fecha</option>
                                            </Input>
                                        </div>
                                    </div>

                                <div className="search-true">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="d-table-cell">Empresa</th>
                                                    <th scope="col" className="d-table-cell">Tecnologías</th>
                                                    <th scope="col" className="d-table-cell">Fecha</th>
                                                    <th scope="col" className="d-none d-md-table-cell">Ingles</th>
                                                    {!this.state.perfil.sincosto && <th scope="col" className="d-none d-md-table-cell">Costo Mes</th>}
                                                    <th scope="col" className="d-none d-md-table-cell">Resultados</th>
                                                    <th scope="col" className="d-none d-md-table-cell text-center">Activa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.busquedas.map((data, key) => (
                                                <tr key={key}>
                                                    <td className="d-table-cell">{data.empresa.nombreEmpresa}</td>
                                                    <td className="d-table-cell">
                                                        
                                                        {data.tecnologias.map((tec, k) => ( 
                                                            <div key={k}>
                                                                <span>
                                                                    <b style={{ color: '#ec5353'}}>{tec.nombre}</b>&nbsp;{tec.tiempo}&nbsp;{tec.periodo}&nbsp;
                                                                </span>
                                                            </div>
                                                        ))}
                                                        <div style={{ margin: 3  }} ></div>
                                                        {data.opcionales.map((tec, k) => ( 
                                                            <div  key={k}>
                                                                <span><b style={{ color: '#40adff'}}>{tec.nombre}</b>&nbsp;{tec.tiempo}&nbsp;{tec.periodo}</span><br/>
                                                            </div>
                                                        ))}

                                                    
                                                    </td>
                                                    <td className="d-table-cell">{data.fecha}</td>
                                                    <td className="d-none d-md-table-cell">{data.nivelIngles}</td>
                                                    {!this.state.perfil.sincosto && <td className="d-none d-md-table-cell">{data.costoMensual} </td> }
                                                    <td className="d-none d-md-table-cell">
                                                        { data.resultados}</td>
                                                    <td className="text-center">
                                                        { data.activa ? 'Si' : 'No'  }    
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }
            </div>
        )
    }
}

export default BusquedasAdmin;