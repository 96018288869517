import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import { Button, Input, Label, Form, Jumbotron, Pagination, PaginationItem, 
    PaginationLink, Tooltip, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import {Link} from "react-router-dom";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from 'react-bootstrap-typeahead'; 
import { FaFilePdf, FaRegCopyright } from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay';

import Menu from './Menu';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import {calcularCosto} from '../helpers';

class ListadoPostulado extends Component {
    typeRef = React.createRef();
    typeRefOpcional = React.createRef();
    // formRef = React.createRef();
    constructor(props){
        super(props);
        this.logout =  this.logout.bind(this);
        this.changeRange  =  this.changeRange.bind(this);    
        this.handleSubmit  =  this.handleSubmit.bind(this);   
        this.handleChange  =  this.handleChange.bind(this); 
        this.handleChangeSelect  =  this.handleChangeSelect.bind(this); 
        this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
        this.handleChangeAutocompleteOpcional = this.handleChangeAutocompleteOpcional.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this._handleSearchOpcionales = this._handleSearchOpcionales.bind(this);
        this.handleNewSearch = this.handleNewSearch.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.ocultar = this.ocultar.bind(this);
        this.toggleTooltip1 = this.toggleTooltip1.bind(this);
        this.toggleTooltip2 = this.toggleTooltip2.bind(this);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.changePeriod = this.changePeriod.bind(this);
        this.handleChangeTiempo = this.handleChangeTiempo.bind(this);
        this.changePeriodOpcional = this.changePeriodOpcional.bind(this);
        this.handleChangeTiempoOpcional = this.handleChangeTiempoOpcional.bind(this);
        this.toggle = this.toggle.bind(this);
        this.isToolTipOpen = this.isToolTipOpen.bind(this);
        this.exportarPDF = this.exportarPDF.bind(this);
        this.saveBusq = this.saveBusq.bind(this);
        this.handleChangeNumeric = this.handleChangeNumeric.bind(this);
        
        this.state = {
            programadores: [],
            programadoresFiltrados: [],
            programadoresTodos: [],
            tecnologia: [],
            opcionales: [],
            imagenes: [],
            tecnologiaAnadida: [],
            categorias: [],
            tipoPerfil: '',
            anosExperiencias: 0,
            nivelIngles: 'No',
            orderBy: 'updatedat',
            sinResultadosInicialmente: false,
            sinResultadosBusqueda: false,
            options: [],
            optionsOpcionales: [],
            multiple: true,
            perfil: this.props.perfil,
            addTecnologia: false,
            tooltip1Open: false,
            tooltip2Open: false,
            currentPage: 0,
            pageCount: 0,
            pageSize: 50,
            costoMensual: '',
            loader: true,
            loading: false,
            tecnologiaOpcionales: [],
            vieneDeBusquedas: false,
            search: ''
        }
    }

    handlePageClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: index});
    };

    handleNextClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: (this.state.currentPage + 1)});
    }

    handlePreviousClick = (e, index) => {
        e.preventDefault();
        this.setState({currentPage: (this.state.currentPage  - 1)});
    }

    logout() {
        firebase.logout();
    }

    componentDidMount(){
        this.loadTecnologias();
        this.loadPostulates();
        this.loadCategorias();
        // esta seccion de codigo es para aplicar fitros cuando se quiere ver las busquedas desde la pantallas Mis Busquedas
        if(this.props.filtros !== ''){
            this.setState({ vieneDeBusquedas: true });
            const filtros = this.props.filtros;
            // console.log(filtros);
            //const tecnologias = filtros.tecnologias.map(row => row.nombre);
            const filter = filtros.tecnologias.map(row => {
                return { name: row.nombre }
            });
            const filterOpcionales = filtros.opcionales.map(row => {
                return { name: row.nombre }
            });
            this.setState({  
                costoMensual: filtros.costoMensual === 0 ? '' : filtros.costoMensual.toString(), 
                nivelIngles: filtros.nivelIngles,
            });
            setTimeout(() => { 
                if(this.typeRef.current && this.props.filtros !== ''){
                    filter.forEach(item =>{
                        this.typeRef.current._handleSelectionAdd(item);
                    })
                    filterOpcionales.forEach(item =>{
                        this.typeRefOpcional.current._handleSelectionAdd(item);
                    })
                    this.setState({ vieneDeBusquedas: false });
                }
                this.filter();
            }, 3000);   
        }

        firebase.db.collection("log").add({ 
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            accion: 'Entro en la ruta Home', 
            tipo: 'empresa',
            info: this.state.perfil,
            modulo: 'Home' 
        });
        
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            this.setState({
                perfil: data
            });
        });
    }

    loadPostulates2(){
        firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
            .get()
            .then(querySnapshot => {
                let objetoProgramador;
                let arrayProgramador = []
                querySnapshot.forEach((doc) => {
                    objetoProgramador = doc.data();
                    objetoProgramador.id = doc.id
                    if(!this.buscarOculto(doc.id)){
                        arrayProgramador.push(objetoProgramador)
                    }
                });
                this.setState({
                    programadores: arrayProgramador, programadoresTodos: arrayProgramador
                })
                let newList =  this.state.programadores.sort(this.sortByDate);

                this.setState({
                    programadores: newList, pageCount: Math.ceil(newList.length / this.state.pageSize), loader: false
                }); 
            });
    }
    
    loadPostulates(){
        firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
            .get()
            .then(querySnapshot => {
                let objetoProgramador;
                let arrayProgramador = []
                querySnapshot.forEach((doc) => {
                    objetoProgramador = doc.data();
                    objetoProgramador.id = doc.id
                    if(!this.buscarOculto(doc.id)){
                        arrayProgramador.push(objetoProgramador)
                    }
                });
                this.setState({
                    programadores: arrayProgramador, programadoresTodos: arrayProgramador
                })
                let newList =  this.state.programadores.sort(this.sortByDate);

                this.setState({
                    programadores: newList, pageCount: Math.ceil(newList.length / this.state.pageSize), loader: false
                });
            });
    }

    sortByAños(a,b){  
        var anosA = new Date(a.anosExperiencia).getTime();
        var anosB = new Date(b.anosExperiencia).getTime();
        return anosA < anosB ? 1 : -1;  
    }

    sortByDate(a,b){  
        var dateA = new Date(a.updatedat).getTime();
        var dateB = new Date(b.updatedat).getTime();
        return dateA < dateB ? 1 : -1;  
    }

    sortByAspiracion(a,b){  
        var numbera = parseInt(a.aspiracion, 10);
        var numberb = parseInt(b.aspiracion, 10);
        return   numbera - numberb;
    }

    loadTecnologias(){
        firebase.db.collection("tecnologias")
           .orderBy("name")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                this.setState({ options: data, optionsOpcionales: data })
            });
    }

    loadCategorias(){
        firebase.db.collection("categorias")
           .orderBy("nombre")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                this.setState({ categorias: data })
            });
    }

    buscarOculto(id){
        let esOculto = false;
        const filter = this.state.perfil.ocultos.filter(row => row === id );
        if(filter.length > 0) { 
            esOculto = true;
        } else {
            esOculto = false;
        }
        return esOculto;
    }

    changeRange(e){
        
    }

    handleSubmit(e){ //este metodo es quin realiza la buasqueda
        e.preventDefault();
        this.filter();
    }

    filter(){
        //armamos la referencia segun los filtros seleccionados
        let ref; 
        if(this.state.nivelIngles === 'No' && this.state.costoMensual === ''){
            ref = firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
        } else if(this.state.nivelIngles !== 'No' && this.state.costoMensual === '') {
            ref = firebase.db.collection("programadores")
            .where("nivelIngles", "==", this.state.nivelIngles)
            .where("estado", "==", "Habilitado")
        } else if(this.state.nivelIngles === 'No' && this.state.costoMensual !== ''){
            ref = firebase.db.collection("programadores")
            .where("estado", "==", "Habilitado")
            .where("costoMes", "<=", parseInt(this.state.costoMensual, 10))
        } else {
            ref = firebase.db.collection("programadores")
            .where("nivelIngles", "==", this.state.nivelIngles)
            .where("estado", "==", "Habilitado")
            .where("costoMes", "<=", parseInt(this.state.costoMensual, 10))
        }
        
        const self = this;
        let arregloProgramadores = [];
        let arrayFinal = [];
        let ListadoFinal = [];
        let tecEncontradas = [];
        const tecnologiasSeleccionadas = this.state.tecnologiaAnadida;
        const tecnologiasOpcionales = this.state.tecnologiaOpcionales;
        let resultadosEncontrados;
        ref
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach((doc) => { 
                let armar = doc.data(); //aca estan todos los datos segun los filtros seleccionados (no incluye filtro las tecnologias)
                armar.id = doc.id;
                /*if(!self.buscarOculto(doc.id)){ // validar los programadores ocultos para la mepresa que ha buscado
                    arreglo.push(armar)
                }*/
                arregloProgramadores.push(armar)
            });
            //Aplicar filtro de tecnologias a los registros devueltos segun los otros filtros
            arregloProgramadores.forEach((row, index) => { //recorrer todos los registros de programadores sin tomar los ocultos
                row.tecnologias.forEach((tec, i) => { //recorrer todas las tecnologias de un programador
                    if(tecnologiasSeleccionadas.length === 0){ // si no ha sleccionado ninguna tecnologia en la busqueda todo bien
                        arrayFinal = arregloProgramadores;
                    } else{// si ha sleccionado alguna tecnologia en la busqueda
                        tecnologiasSeleccionadas.forEach((item, pos)=>{ // repetir segun las tecnologias seleccionadas 
                            if(item.tiempo === 0){
                                if(tec.tecnologia === item.nombre){// comparar si alguna de la tecnologia seleccionada es igual a alguna de las tecnologias de un programador, este es valido 
                                    tecEncontradas.push(true);
                                } else {
                                    tecEncontradas.push(false);
                                }
                            } else {
                                if(item.periodo === 'Mes') { // si en el filtro selecciono por Mes
                                    if(item.periodo === tec.tecnologiaTipo){// si la tacnologia es meses comparo por meses
                                        if(tec.tecnologia === item.nombre && tec.tecnologiaTipo === item.periodo && parseInt(tec.tecnologiaAños, 10) >= item.tiempo  ){
                                            tecEncontradas.push(true);
                                        } else {
                                            tecEncontradas.push(false);
                                        }
                                    } else { // si la tacnologia tiene años, Logico Años es mayor a Meses, no comparo el tiempo
                                        if(tec.tecnologia === item.nombre){
                                            tecEncontradas.push(true);
                                        } else {
                                            tecEncontradas.push(false);
                                        }
                                    }
                                } else { // si en el filtro selecciono por Años
                                    if(tec.tecnologia === item.nombre && tec.tecnologiaTipo === item.periodo && parseInt(tec.tecnologiaAños, 10) >= item.tiempo  ){
                                        tecEncontradas.push(true);
                                    } else {
                                        tecEncontradas.push(false);
                                    }
                                }
                            }
                        })
                    }
                });
                let serached = tecEncontradas.filter(fila => fila === true); 
                //con esta condicion se que las tecnologias que seleccione en el filtro, estan o no en las tecnologias de un programador
                if(tecnologiasSeleccionadas.length > 0){
                    if(serached.length === tecnologiasSeleccionadas.length){
                        arrayFinal.push(row);
                    }
                }
                tecEncontradas = [];
                serached  = [];
            });

            // codigo nuevo aplicando nuevo filtro search
            let dataFiltrada  = [];
            let filterTipoPerfiles = [];
            let programadores = [];
            let arrayDistint = [];
            let arrayConcat = [];
            if(tecnologiasSeleccionadas.length === 0){ 
                if(self.state.search === ''){
                    ListadoFinal = arrayFinal;
                    self.setState({programadores: arrayFinal, pageCount: Math.ceil(arrayFinal.length / self.state.pageSize)})
                } else {
                    dataFiltrada = arregloProgramadores.filter(item => item.nombres.toLowerCase().indexOf(self.state.search.toLowerCase()) > -1 || 
                        item.apellidos.toLowerCase().indexOf(self.state.search.toLowerCase()) > -1 
                        || item.nivelIngles.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.estado.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.updatedat.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.aspiracion.toString().toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.anosExperiencia.toString().indexOf(self.state.search.toLowerCase()) > - 1
                    );
                    programadores = arregloProgramadores;
                    programadores
                    .map( item => {
                        return item.tipoPerfiles.map(fila => {
                            if(fila.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1){
                                filterTipoPerfiles.push(item);
                            }
                            return filterTipoPerfiles;
                        });
                    })
                    arrayConcat = dataFiltrada.concat(filterTipoPerfiles);
                    arrayDistint = Array.from(new Set(arrayConcat.map(s => s.uid)))
                    .map(uid => {
                        return arrayConcat.find(x => x.uid === uid)
                    })
                    ListadoFinal = arrayDistint;
                    self.setState({programadores: arrayDistint, pageCount: Math.ceil(arrayDistint.length / self.state.pageSize)})
                }
            } else {
                if(self.state.search === ''){
                    ListadoFinal = arrayFinal;
                    self.setState({programadores: arrayFinal, pageCount: Math.ceil(arrayFinal.length / self.state.pageSize)})
                } else {
                    programadores = arrayFinal;
                    dataFiltrada = arrayFinal.filter(item => item.nombres.toLowerCase().indexOf(self.state.search.toLowerCase()) > -1 || 
                        item.apellidos.toLowerCase().indexOf(self.state.search.toLowerCase()) > -1 
                        || item.nivelIngles.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.estado.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.updatedat.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.aspiracion.toString().toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1
                        || item.anosExperiencia.toString().indexOf(self.state.search.toLowerCase()) > - 1
                    );
                    programadores
                    .map( item => {
                        return item.tipoPerfiles.map(fila => {
                            if(fila.toLowerCase().indexOf(self.state.search.toLowerCase()) > - 1){
                                filterTipoPerfiles.push(item);
                            }
                            return filterTipoPerfiles;
                        });
                    })
                    arrayConcat = dataFiltrada.concat(filterTipoPerfiles);
                    arrayDistint = Array.from(new Set(arrayConcat.map(s => s.uid)))
                    .map(uid => {
                        return arrayConcat.find(x => x.uid === uid)
                    })
                    ListadoFinal = arrayDistint;
                    self.setState({programadores: arrayDistint, pageCount: Math.ceil(arrayDistint.length / self.state.pageSize)})
                }
            }

            // self.setState({programadores: arrayFinal, pageCount: Math.ceil(arrayFinal.length / self.state.pageSize)}) //actualizo el estado programadores segun los encongtrados o no
            if(ListadoFinal.length === 0){ //si no encontro ningun programador segun la tecnologia
                self.setState({sinResultadosBusqueda: true});
                resultadosEncontrados = false;
                //self._typeahead.clear();
            } else { // si consigue uno o mas programadores segun la tecnologia
                self.setState({sinResultadosBusqueda: false, sinResultadosInicialmente: false })
                resultadosEncontrados = true;
            }
            self.setState({ currentPage: 0 });
            // Verificar si ya ha realizado una busqueda igual para no repetirla en la BD
            let existeBusqueda = false;
            firebase.db.collection("busquedas")
            .where("uid", "==", `${firebase.auth.currentUser.uid}`)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => { // recorro las busquedas que tiene la empresa
                    const data = doc.data();
                    if(tecnologiasSeleccionadas.length > 0){
                        if(data.tecnologias.length > 0){ //si la busqueda tiene tecnologias comparo las mismas y el costo Mennsual, y el nivel de ingles
                            const tecnologiasFilterSort = tecnologiasSeleccionadas.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
                            const tecnologiasDBSort = data.tecnologias.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
                            if(self.state.costoMensual === ''){
                                if(JSON.stringify(tecnologiasFilterSort) === JSON.stringify(tecnologiasDBSort) && data.nivelIngles === self.state.nivelIngles) {
                                    existeBusqueda = true;
                                }
                            } else {
                                if(JSON.stringify(tecnologiasFilterSort) === JSON.stringify(tecnologiasDBSort) 
                                    && data.costoMensual === parseInt(self.state.costoMensual, 10)  && data.nivelIngles === self.state.nivelIngles) {
                                    existeBusqueda = true;
                                }
                            }
                        }
                    } else {
                        existeBusqueda = true; 
                        // en este caso es que ha filtrado si tecnologias lo coloco en true para que no se guarde la busqueda
                    }
                });

                if(existeBusqueda === false){
                    //Finalmente guardo si no existe la busqueda
                    let objSave = { //objeto usado para gaurdar la busqueda
                        activa: true,
                        costoMensual: self.state.costoMensual === '' ? 0 : parseInt(self.state.costoMensual, 10),
                        empresa: self.state.perfil,
                        nivelIngles: self.state.nivelIngles,
                        tecnologias: tecnologiasSeleccionadas,
                        opcionales: tecnologiasOpcionales,
                        uid: firebase.auth.currentUser.uid,
                        resultadosEncontrados,
                        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                        resultados: ListadoFinal.length,
                        comunicada: []
                    }
                    firebase.db.collection('busquedas').add(objSave) //guardar la busqueda
                        .then((querySnapshot) => { 
                            // localStorage.setItem('busquedaactiva',JSON.stringify(objSave));
                        });
                }
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
            self.setState({ currentPage: 0 });
        });
    }

    /*  EXISTEN DOS AUTOCOMPLETES UNO PARA LAS OBLIGATORIAS Y OTRA PARA LAS OPCIONALES AMBOS CON LOS DATOS ASOCIADOS A ESTADOS DISTINTOS
        OBLIGATORIOS CON state.options  OCIONALES ON state.optionsOpcionales
        Estos dos metodos removeItemOptions y addItemOpstions es para no duplicar los conocimientos en ambos autocompletes de una busqueda
        Ejamplo para no seleccionar ASPNET en ambos autocompletes en una sola busqueda */
    removeItemOptions(temp, evento, autocomplete){ 
        if(autocomplete === 'requeridas'){
            let quito = [];
            let restan = temp;
            if(temp.length > 1){
                evento.forEach((item, i) => {
                    let coincide = restan.filter(row => row.nombre !== item.name );
                    restan = coincide;
                })
                quito = restan;
            } else {
                quito = temp
            }
            let arreglo = this.state.optionsOpcionales;
            arreglo.push({ name: quito[0].nombre  });
            arreglo = arreglo.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
            this.setState({ optionsOpcionales: arreglo});
        } else {
            let quito = [];
            let restan = temp;
            if(temp.length > 1){
                evento.forEach((item, i) => {
                    let coincide = restan.filter(row => row.nombre !== item.name );
                    restan = coincide;
                })
                quito = restan;
            } else {
                quito = temp
            }
            let arreglo = this.state.options;
            arreglo.push({ name: quito[0].nombre  });
            arreglo = arreglo.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
            this.setState({ options: arreglo});
        }
    }

    addItemOpstions(item, autocomplete){
        if(autocomplete === 'requeridas'){
            const ArrayRemoveTec = this.state.optionsOpcionales.filter(fila => fila.name !== item.name);
            this.setState({optionsOpcionales: ArrayRemoveTec});
        } else {
            const ArrayRemoveTec = this.state.options.filter(fila => fila.name !== item.name);
            this.setState({options: ArrayRemoveTec});
        }
    }

    handleChangeAutocomplete = (e) => { //evento añadir una tecnologia obligatoria
        let array = [];
        let tempAnadida = [];
        tempAnadida = this.state.tecnologiaAnadida;
        let objAñadir; 
        e.forEach((item, i) => {
            if (item.customOption) {
                firebase.db.collection('tecnologias').add({name: item.name}).then();
                e[i]= {name: item.name};
                this.typeRef.current.state.initialItem = {name: item.name};
                let dataAutocomplete = [];
                let dataAutocompleteOpcionales = [];
                dataAutocomplete = this.state.options;
                dataAutocompleteOpcionales = this.state.optionsOpcionales;
                dataAutocomplete.push({name: item.name})
                dataAutocompleteOpcionales.push({name: item.name})
                this.setState({options: dataAutocomplete, optionsOpcionales: dataAutocompleteOpcionales});
            }
                array.push(item.name)
        })
        if(tempAnadida.length > e.length){ // si ha removido una o todas las tecnologias
            if(e.length === 0){ // si ha quitado todas
                let arreglo = this.state.optionsOpcionales;
                tempAnadida.forEach(item => {
                    arreglo.push({ name: item.nombre  });
                })
                arreglo = arreglo.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
                this.setState({ optionsOpcionales: arreglo});
                tempAnadida = [];
            } else { // si ha quitado una
                this.removeItemOptions(tempAnadida, e, 'requeridas');
                tempAnadida = this.removerTecnologia(tempAnadida, e); // funcionamiento Normal
            }
        } else if(tempAnadida.length < e.length) { // si ha añadido una nueva tecnologia
            e.forEach((item, i) => {
                if (typeof tempAnadida[i] === 'undefined') {
                    if(this.state.vieneDeBusquedas === false){ // funcionamiento normal no viene de la pantalla busquedas
                        objAñadir = {nombre: item.name, periodo: 'Años', tiempo: 0 };
                    } else { // solo cuando se abre esta pantalla desde la pantalla de busquedas
                        const tecs = this.props.filtros.tecnologias;
                        const filter = tecs.filter(fila => fila.nombre === item.name);
                        objAñadir = {nombre: item.name, periodo: filter[0].periodo, tiempo: filter[0].tiempo };
                    }
                    tempAnadida.push(objAñadir);
                    this.addItemOpstions(item, 'requeridas');
                }
            })
        }
        this.setState({tecnologia: array, tecnologiaAnadida: tempAnadida})
    }

    handleChangeAutocompleteOpcional = (e) => { //evento añadir una tecnologia opcional
        let array = [];
        let tempAnadida = [];
        tempAnadida = this.state.tecnologiaOpcionales;
        let objAñadir;
        e.forEach((item, i) => {
            if (item.customOption) {
                firebase.db.collection('tecnologias').add({name: item.name}).then();
                e[i]= {name: item.name};
                this.typeRefOpcional.current.state.initialItem = {name: item.name};
                let dataAutocomplete = [];
                let dataAutocompleteOpcionales = [];
                dataAutocomplete = this.state.options;
                dataAutocompleteOpcionales = this.state.optionsOpcionales;
                dataAutocomplete.push({name: item.name})
                dataAutocompleteOpcionales.push({name: item.name})
                this.setState({options: dataAutocomplete, optionsOpcionales: dataAutocompleteOpcionales});
            }
            array.push(item.name)
        })
        if(tempAnadida.length > e.length){ // si ha quitado una tecnologia
            if(e.length === 0){ // si ha quitado todas
                let arreglo = this.state.options;
                tempAnadida.forEach(item => {
                    arreglo.push({ name: item.nombre  });
                })
                arreglo = arreglo.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1);
                this.setState({ options: arreglo});
                tempAnadida = [];
            } else { // si ha quitado una
                this.removeItemOptions(tempAnadida, e, 'opcionales');
                tempAnadida = this.removerTecnologia(tempAnadida, e); // funcionamiento Normal
            }
        } else if(tempAnadida.length < e.length) { // si ha añadido una nueva tecnologia
            e.forEach((item, i) => {
                if (typeof tempAnadida[i] === 'undefined') {
                    if(this.state.vieneDeBusquedas === false){ // funcionamiento normal
                        objAñadir = {nombre: item.name, periodo: 'Años', tiempo: 0 };
                    } else { // cuando se abre esta pantalla desde la pantalla de busquedas
                        const tecs = this.props.filtros.opcionales;
                        const filter = tecs.filter(fila => fila.nombre === item.name);
                        if(filter.length > 0){
                            objAñadir = {nombre: item.name, periodo: filter[0].periodo, tiempo: filter[0].tiempo };
                        } else {
                            objAñadir = {nombre: item.name, periodo: 'Años', tiempo: 0 };
                        }
                    }
                    tempAnadida.push(objAñadir);
                    this.addItemOpstions(item, 'opcionales');
                }
            })
        }
        this.setState({opcionales: array, tecnologiaOpcionales: tempAnadida})
    }

    _handleSearch(text, e){
        let value = text;
        if(text.indexOf(',') !== -1){
            const array = text.split(',');
            value = array[0];
            let search = [];
            search = this.state.options.filter(el => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if(search.length === 0){
                this.typeRef.current._handleSelectionAdd({customOption: true, id: Math.random().toString(20).substr(2, 6), name: search[0].name})
            } else{
                this.typeRef.current._handleSelectionAdd({name: search[0].name})
            }
        }
    }

    _handleSearchOpcionales(text, e){
        let value = text;
        if(text.indexOf(',') !== -1){
            const array = text.split(',');
            value = array[0];
            let search = [];
            search = this.state.optionsOpcionales.filter(el => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if(search.length === 0){
                this.typeRefOpcional.current._handleSelectionAdd({customOption: true, id: Math.random().toString(20).substr(2, 6), name: search[0].name})
            } else{
                this.typeRefOpcional.current._handleSelectionAdd({name: search[0].name})
            }
        }
    }
    
    removerTecnologia(array1, array2){
        let final = [];
        array2.forEach((fila, i) => {
            array1.forEach((row, k) =>{
                if(row.nombre === fila.name ){
                    final.push(row)
                }
            })
        })
        return final;
    }

    ordenarBusuqeda(array){
        return array.sort(function(a, b){
            return a.nombre === b.nombre ? 0 : +(a.nombre > b.nombre) || -1;
        });
    }

    objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o1).every(p => o1[p] === o2[p]);

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeSelect = (e) => {
        this.setState({[e.target.name]: e.target.options[e.target.selectedIndex].text});
    }

    handleNewSearch(){
        this.setState({ sinResultadosBusqueda: false, 
            tecnologia: [], tecnologiaAnadida: [], nivelIngles: 'No', tipoPerfil: '', 
            anosExperiencia: 0, costoMensual: '',
            tecnologiaOpcionales: [],
            opcionales: []
         });
        this.typeRef.current.clear();
        this.typeRefOpcional.current.clear();
        this.loadPostulates();
        localStorage.removeItem('busquedaactiva');
    }

    handleChangeNumeric = (e) => {
        const value = e.target.value;
        if(value !== ''){
            if (!Number(value)) {
                return;
            }
        }
        this.setState({ [e.target.name]: value });
    }

    changeOrder = (e) => {
        this.setState({[e.target.name]: e.target.value});
        let newList = this.state.programadores;
        let ordenado = [];

        if(e.target.value === 'updatedat'){
            ordenado = newList.sort(this.sortByDate);
        } else if(e.target.value === 'aspiracion'){
            ordenado = newList.sort(this.sortByAspiracion);
        }else if(e.target.value === 'años'){
            ordenado = newList.sort(this.sortByAños);
        } else {
            ordenado = newList.sort((prev, next) => {
                if( prev[e.target.value].toLowerCase()  > next[e.target.value].toLowerCase()  ){
                    return 1;
                }
                if( prev[e.target.value].toLowerCase() < next[e.target.value].toLowerCase()  ){
                    return -1;
                }
                return 0;
            });
        }
        this.setState({
            programadores: ordenado, pageCount: Math.ceil(newList.length / this.state.pageSize)
        }); 
    }

    ocultar(i){
        let array = this.state.perfil.ocultos;
        array.push(this.state.programadores[i].id)
        firebase.db.collection('empresas').doc(`${firebase.auth.currentUser.uid}`)
            .update({ocultos: array})
            .then(result => {
                this.loadPostulates();
            });
    }

    toggleTooltip1(event){
        this.setState({tooltip1Open: !this.state.tooltip1Open})
    }
    toggleTooltip2(event){
        this.setState({tooltip2Open: !this.state.tooltip2Open})
    }

    changePeriod(event, index){
        let data = this.state.tecnologiaAnadida;
        data[index].periodo = event.target.value;
        this.setState({ tecnologiaAnadida: data })
    }

    handleChangeTiempo(event, index){
        let data = this.state.tecnologiaAnadida;
        data[index].tiempo = parseInt(event.target.value, 10);
        this.setState({ tecnologiaAnadida: data })
    }

    changePeriodOpcional(event, index){
        let data = this.state.tecnologiaOpcionales;
        data[index].periodo = event.target.value;
        this.setState({ tecnologiaOpcionales: data })
    }

    handleChangeTiempoOpcional(event, index){
        let data = this.state.tecnologiaOpcionales;
        data[index].tiempo = parseInt(event.target.value, 10);
        this.setState({ tecnologiaOpcionales: data })
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
          this.setState({
            ...this.state,
            [targetName]: {
              tooltipOpen: true
            }
          });
        } else {
          this.setState({
            ...this.state,
            [targetName]: {
              tooltipOpen: !this.state[targetName].tooltipOpen
            }
          });
        }
    };
    
    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    async exportarPDF(datosGenerales){
        // const self = this;
        const addFooters = doc => {
            const pageCount = doc.internal.getNumberOfPages()
            doc.setFont('helvetica', 'italic')
            doc.setFontSize(10)
            for (var i = 0; i < pageCount; i++) {
              /*doc.text('Hispanos Soluciones C.A Contacto: info@hispanossoluciones.com', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 20, {
                align: 'center'
              })*/
                doc.setTextColor(0,0,0);
                doc.text('Hispanos Soluciones C.A. / contacto: ', 146, doc.internal.pageSize.height - 20, { });
                doc.setTextColor(51,122,183);
                doc.text('info@hispanossoluciones.com', 316, doc.internal.pageSize.height - 20, {});
            }
        }
        this.setState({ loading: true  });
        var doc = new jsPDF('p', 'pt', 'A4', true);
        const llena = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAjCAIAAABzZz93AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGmSURBVEhL7Za7SgNBFIZT+wQK9l4ewPgCSR+0VBAstLKIrQop1cJGwUqsbMROrKzSitiKsMoiKmJhqzbjjwvD+M/uOXOWECyErwgzO/PtucxsGvf5s8pD/pL9/Mjyp3DcSuPj82to/HnZ69s7jSRSR7a8tl3PZ5ZBMzLe3js8pXEV55xZdnRyAVmzvUrjKnVk80tbkIG77JGmZMyyIocF1kyaZRB42eTsIs3KmGUzrRUvA1c3t/SAgE2GIoUm0N3Yp2cEbLIwhwWmTP6Sofi9nePebiXYmmRgffOAHgsJ88yRYW50qkPb1QavHm5ekkbE15rr0jIreOOz8z7tXFkzVJ7Wp4OOLT3vUoPgWqqRUuGOlmQAJaSDJSPfKYoM4DX9ZSiAHKgHXJeB4pqXQU/RqpgkGcpAW5dSVSpPkiyxTZAAWkjoMlSCNq0CCaC1hC6LDxz687J/HY+PTXdoLaHLqPXDGwjKieZCOCs3pCILvymwxnuhKcIQ5S+OIvNNT1cq4UOU/wUpMhzn0oBifIjCvyBFJgcUgxABDXr0Bhkg/7KBMFyZc982987J93mLyAAAAABJRU5ErkJggg=='
        const vacia = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAIAAAC4O+zSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAF1SURBVEhL7ZbdSgMxEEZ9/1cTRCmKSqFUl61W19ZarfVm/TAwjF+SySSU4oXlXJT8ncxkkvbkc/91NP687H23pxYnLbLp/LnNVy2D5nRy3z2sqd1DtWzx9ArZ5HZB7Tbjz6dadjNbQgY22x11GbTIQg4DVZlskUEgsovrnnoNWmQ4KpGB9eaDBuSoluGQtAnMuoHG5KiW6RwG/JlkGQ5/3r/c9ascWJpkAMHRMI3kmWUAfWeXHS3XDLYuKydkAPFdTR9pWi3Y8XJ408umZQEkh+b7QcXG992SATxLDSnNvdEFGcAR0sWyMd6Usgxgm/IYGiAH9gV3yUB45m1QUzSL8MpwDLR0kuRRCV6Zs0yQAJqocclwErRoDiSA5mpcsvjCoT6H1TZuRwJorsYlo9LXLxCU579fS6MgyzL9mwJrvBaKQoeI7zRAKMuk6HVAMRIiNkRdQlmG65wMKEZCzP0LKsvsgGIQIqDGQFl2QP5lB+HosnH8BtAvjT7SGunQAAAAAElFTkSuQmCC'
        
        doc.autoTable({
            head: [[`Ficha ${datosGenerales.nombres.split(' ')[0]} ${datosGenerales.apellidos.split(' ')[0]}`]],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })

        let perfiles = [];
        perfiles = datosGenerales.tipoPerfiles;
     
        doc.autoTable({
            head: [[perfiles]],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })

        const costoSemanal = (datosGenerales.costoMes / 4 ).toFixed(1);
        const costoDia = (((datosGenerales.costoMes / 4 )) /  5).toFixed(1);
        const costoHora = (((datosGenerales.costoMes / 4 )) /  (5 * 8)).toFixed(1);
        const splitCostoSemanal = costoSemanal.split('.');
        const splitCostoDia = costoDia.split('.');
        const splitCostoHora = costoHora.split('.');
        let finalCostoSemanal = '';
        let finalCostoDia = '';
        let finalCostoHora ='';
        if(splitCostoSemanal[1] === '0'){
            finalCostoSemanal = splitCostoSemanal[0];
        } else {
            finalCostoSemanal = costoSemanal;
        }
        if(splitCostoDia[1] === '0'){
            finalCostoDia = splitCostoDia[0];
        } else {
            finalCostoDia = costoDia;
        }
        if(splitCostoHora[1] === '0'){
            finalCostoHora = splitCostoHora[0];
        } else {
            finalCostoHora = costoHora;
        }

        doc.autoTable({
            head: [['Atributo', 'Valor',]],
            body: [
              ['Nombres', this.splitNombres(datosGenerales.nombres.split(' ')[0])],
              ['Apellidos', this.splitNombres(datosGenerales.apellidos.split(' ')[0])],
              ['Ciudad', datosGenerales.ciudad],
              //['Fecha Postuación', datosGenerales.fecha],
              ['Años de Experiencia', datosGenerales.anosExperiencia],
              ['Titulo académico', datosGenerales.titulo],
              ['Estudio Avanzado', datosGenerales.otroTitulo],
              ['Universidad', datosGenerales.universidad],
              ['Tipo de Perfil', datosGenerales.tipoPerfiles],
              ['Habla y escribe ingles al 100%', datosGenerales.nivelIngles],
              ['Costo Mensual', datosGenerales.costoMes + ' USD'],
              ['Costo por Semana', finalCostoSemanal + ' USD'],
              ['Costo por Día', finalCostoDia + ' USD'],
              ['Costo por Hora', finalCostoHora + ' USD'],
            ],
            headStyles: {
                fillColor: [62, 64, 97],
            },
        })
        doc.autoTable({
            head: [['Tecnologías']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })

        let arrayTec = [];
        datosGenerales.tecnologias.forEach((item, i) => {
            arrayTec.push([item.tecnologia, item.tecnologiaAños + ' ' + item.tecnologiaTipo , '', item.tecnologiaVersion]);
        });

        doc.autoTable({
            head: [['Tecnología', 'Tiempo de experiencia', 'Dominio (1-5)', 'Versión']],
            body: arrayTec,
            headStyles: {
                fillColor: [62, 64, 97],
            },
            allSectionHooks: true,
            didDrawCell:  (data) => {
                const arreglo = datosGenerales.tecnologias;
                const value = arreglo[data.row.index].dominio;
                if(data.column.index === 2 && data.row.section !== 'head'){
                    for(let i=1; i<=5; i++ ){
                        if(i > value){
                            doc.addImage(vacia, "JPEG", data.cell.x + (15 * i) + 5, data.cell.y + 2, 15, 15);
                        } else {
                            doc.addImage(llena, "JPEG", data.cell.x + (15 * i) + 5 , data.cell.y + 2, 15, 15);
                        }
                    }
                }
            }
        });

        doc.autoTable({
            head: [['Experiencias Laborales']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })
        let arrayEmp = [];
        datosGenerales.experiencias.map((item, i) => {
            arrayEmp.push([item.empresaNombre, item.empresaDescripcion]);
            return arrayEmp;
        });
        doc.autoTable({
            head: [['Empresa', 'Actividades']],
            body: arrayEmp,
            headStyles: {
                fillColor: [62, 64, 97],
            },
        });
        doc.autoTable({
            head: [['Links de conocimientos o proyectos relizados']],
            body: [
            ],
            headStyles:{
                valign: 'middle',
                halign : 'center',
                fontSize: 18,
                fillColor: [255, 255, 255],
                textColor: [40, 167, 69],
            }
        })
        let arrayLin = [];
        datosGenerales.links.map((item, i) => {
            arrayLin.push([item.link, item.linkDescripcion]);
            return arrayLin;
        });
        doc.autoTable({
            head: [['Link', 'Descripción']],
            body: arrayLin,
            headStyles: {
                fillColor: [62, 64, 97],
            },
            columnStyles: {
                0: {
                    fontStyle: 'bold',
                    textDecoration: 'undeerline',
                    textColor: [51,122,183]                
                },
            }
        });
        if(datosGenerales.cursos.length > 0){
            doc.autoTable({
                head: [['Cursos realizados']],
                body: [
                ],
                headStyles:{
                    valign: 'middle',
                    halign : 'center',
                    fontSize: 18,
                    fillColor: [255, 255, 255],
                    textColor: [51,122,183],
                }
            })
            let arrayCur = [];
            datosGenerales.cursos.map((item, i) => {
                arrayCur.push([item.cursoNombre, item.cursoDescripcion]);
                return arrayCur;
            });
            doc.autoTable({
                head: [['Curso', 'Descripción']],
                body: arrayCur,
                headStyles: {
                    fillColor: [62, 64, 97],
                },
            });
        }
        /*addFooters(doc)
        // window.open(doc.output('bloburl'), '_blank');
        doc.save(`${datosGenerales.nombres.split(' ')[0]} ${datosGenerales.apellidos.split(' ')[0]}.pdf`);*/
        let arrayImagesHtml = []
        const images = datosGenerales.imagenes ? datosGenerales.imagenes : [];
        let promises = [];
        for (let i = 0; i < images.length; i++) {
            const url = images[i].url;
            try {
                const data = await this.loadImage(url,i);
                arrayImagesHtml.push(data);
            } catch (error) {
                console.log('error', error)
            }
        }
        var width = doc.internal.pageSize.getWidth();
        let inicioY = 40; 
        this.setState({ loading: true  });
        arrayImagesHtml.forEach((img, vuelta) => {
            const ancho = img.width;
            const alto = img.height;
            if(vuelta % 2 === 0){
                doc.addPage();
                if(alto > ancho){
                    doc.addImage(img, 'JPEG', 200, inicioY, (width-400), 300);
                } else {
                    doc.addImage(img, 'JPEG', 40, inicioY, (width-80), 270);
                }
            } else {
                if(alto > ancho){
                    doc.addImage(img, 'JPEG', 200, (40 + 330), (width-400), 300);
                } else {
                    doc.addImage(img, 'JPEG', 40, (40 + 310), (width-80), 270);
                }
            }
        })
        addFooters(doc);
        this.setState({ loading: false  });
        // window.open(doc.output('bloburl'), '_blank');
        doc.save(`${datosGenerales.nombres.split(' ')[0]} ${datosGenerales.apellidos.split(' ')[0]}.pdf`);
    }

    loadImage(url, i) {
        return new Promise((resolve, rejected) => {
            let img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                resolve(img);
            }
            img.onerror = function() {
                rejected('url no valida')
            };
            img.src = url
        })
    }

    splitNombres(value){
        return value.split(' ')[0];
    }

    saveBusq(){ //metodo para guaradar o no el localstorage la busqueda activa
        const requeridas = this.state.tecnologiaAnadida;
        const opcionales = this.state.tecnologiaOpcionales;
        const nivelIngles = this.state.nivelIngles;
        const costoMensual = this.state.costoMensual === '' ? 0 : parseInt(this.state.costoMensual, 10);
        let objSave = { //objeto usado para gaurdar la busqueda
            activa: true,
            costoMensual,
            empresa: this.state.perfil,
            nivelIngles,
            tecnologias: requeridas,
            opcionales: opcionales,
            uid: firebase.auth.currentUser.uid,
            resultadosEncontrados: 0,
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm'),
            resultados: 0,
            comunicada: []
        }
        localStorage.setItem('busquedaactiva',JSON.stringify(objSave));
    }

    itemsPerPage(){
        let array = []
        array = this.state.programadores.slice(this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize)
        return  (this.state.currentPage * this.state.pageSize) + array.length;
    }

    render(){
  
        return (
            <div>
                <Menu link='inicio' admin={false} logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Cargando...'
                >
            {!this.state.loader &&
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row h-100 align-items-center justify-content-center w-100">
                        <div className="row p-0 w-100">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg w-100">                               
                                <div className="list-postul-header text-center p-3">
                                    <h2>Listado de Postulados</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-3 w-100">

                                    <div className="row w-100">
                                        <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3 col-xl-3">
                                            <Form onSubmit={this.handleSubmit} >
                                            <ListGroup>
                                                <ListGroupItem active>FILTROS</ListGroupItem>
                                                <ListGroupItem style={{fontSize: '12px', backgroundColor: '#f9f9f9'}}><b>OBLIGATORIO</b></ListGroupItem>
                                                <ListGroupItem style={{ padding: '.75rem .45rem' }} >
                                                    <Typeahead
                                                        clearButton
                                                        id="tecnologia"
                                                        labelKey="name"
                                                        allowNew
                                                        multiple={this.state.multiple}
                                                        emptyLabel="No se encontraron coincidencias."
                                                        newSelectionPrefix="Añadir tecnología: "
                                                        options={this.state.options}
                                                        placeholder="Ej: Wordpress, ReactJS, PHP, Django, MySQL, .NET, Java EE, SQL Server, Laravel, HTML5, CSS3, ES6" 
                                                        // svalue={this.state.tecnologia} 
                                                        onChange={this.handleChangeAutocomplete}
                                                        onInputChange={this._handleSearch}
                                                        ref={this.typeRef}
                                                    />
                                                </ListGroupItem>
                                                {this.state.tecnologia.length > 0 &&
                                                    <ListGroupItem>
                                                        {this.state.tecnologia.map((item, key) => (
                                                            <div key={key} style={{height: '100%'}}>
                                                                <ListGroupItemHeading style={{ fontSize: '1.05rem' }}>{item}</ListGroupItemHeading>
                                                                <div className="row"  >  
                                                                    <div className="col-6" style={{ paddingLeft: '5px', paddingRight: '5px' }} >
                                                                        <div className="form-group" style={{ marginBottom: '0rem' }}  >
                                                                            <Input type="select" bsSize="sm" name="tecnologiaPeriodo" className="w-100" 
                                                                                value={this.state.tecnologiaAnadida[key].periodo} onChange={(e) => {this.changePeriod(e, key); }} >
                                                                                <option value="Mes">Mes</option>
                                                                                <option value="Años">Años</option>
                                                                            </Input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6" style={{ paddingLeft: '5px', paddingRight: '5px' }} >
                                                                        <div className="form-group" style={{ marginBottom: '0rem' }}  >
                                                                        <Input type="number" bsSize="sm" className="form-control" name="tecnologiaTiempo" 
                                                                            onChange={(e) => {this.handleChangeTiempo(e, key); }} value={this.state.tecnologiaAnadida[key].tiempo} /> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ListGroupItem>
                                                }
                                                <ListGroupItem style={{fontSize: '12px', backgroundColor: '#f9f9f9'}}><b>OPCIONAL</b></ListGroupItem>
                                                <ListGroupItem style={{ padding: '.75rem .45rem' }} >
                                                    <Typeahead
                                                        clearButton
                                                        id="tecnologia"
                                                        labelKey="name"
                                                        allowNew
                                                        multiple={this.state.multiple}
                                                        emptyLabel="No se encontraron coincidencias."
                                                        newSelectionPrefix="Añadir tecnología: "
                                                        options={this.state.optionsOpcionales}
                                                        placeholder="Ej: Wordpress, ReactJS, PHP, Django, MySQL, .NET, Java EE, SQL Server, Laravel, HTML5, CSS3, ES6" 
                                                        // svalue={this.state.tecnologia} 
                                                        onChange={this.handleChangeAutocompleteOpcional}
                                                        onInputChange={this._handleSearchOpcionales}
                                                        ref={this.typeRefOpcional}
                                                    />
                                                </ListGroupItem>
                                                {this.state.opcionales.length > 0 &&
                                                    <ListGroupItem>
                                                        {this.state.opcionales.map((item, key) => (
                                                            <div key={key} style={{height: '100%'}}>                                                               
                                                                <ListGroupItemHeading style={{ fontSize: '1.05rem' }}>{item}</ListGroupItemHeading>
                                                                <div className="row"  >  
                                                                    <div className="col-6" style={{ paddingLeft: '5px', paddingRight: '5px' }} >
                                                                        <div className="form-group" style={{ marginBottom: '0rem' }}  >
                                                                            <Input type="select" bsSize="sm" name="tecnologiaPeriodo" className="w-100" 
                                                                                value={this.state.tecnologiaOpcionales[key].periodo} onChange={(e) => {this.changePeriodOpcional(e, key); }} >
                                                                                <option value="Mes">Mes</option>
                                                                                <option value="Años">Años</option>
                                                                            </Input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6" style={{ paddingLeft: '5px', paddingRight: '5px' }} >
                                                                        <div className="form-group" style={{ marginBottom: '0rem' }}  >
                                                                        <Input type="number" bsSize="sm" className="form-control" name="tecnologiaTiempo" 
                                                                            onChange={(e) => {this.handleChangeTiempoOpcional(e, key); }} value={this.state.tecnologiaOpcionales[key].tiempo} /> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ListGroupItem>
                                                }
                                                <ListGroupItem style={{fontSize: '12px', backgroundColor: '#f9f9f9'}}><b>HABLA ESCRIBE INGLES</b></ListGroupItem>
                                                <ListGroupItem>
                                                    <Input type="select" name="nivelIngles" id="nivelIngles" className="w-100"  value={this.state.nivelIngles} onChange={this.handleChangeSelect}>
                                                        <option value="Si">Si</option>
                                                        <option value="No">No</option>
                                                    </Input>
                                                </ListGroupItem>
                                                <ListGroupItem style={{fontSize: '12px', backgroundColor: '#f9f9f9'}}><b>MAX COSTO MENSUAL USD</b></ListGroupItem>
                                                <ListGroupItem>
                                                    <Input name="costoMensual" id="costoMensual" className="w-100"  value={this.state.costoMensual} onChange={this.handleChangeNumeric}>
                                                    </Input>
                                                </ListGroupItem>
                                                <ListGroupItem style={{fontSize: '12px', backgroundColor: '#f9f9f9'}}><b>COINCIDENCIA</b></ListGroupItem>
                                                <ListGroupItem>
                                                    <Input name="search" id="search" className="w-100"  value={this.state.search} onChange={this.handleChange}>
                                                    </Input>
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <Button type="submit" color="success" block>
                                                        Buscar <i className="zmdi zmdi-search ml-sm-2" aria-hidden="true"></i>
                                                    </Button>
                                                </ListGroupItem>
                                            </ListGroup>
                                            </Form>
                                        </div>
                                        <div className="col-xs-12 col-sm-7 col-md-9 col-lg-9 col-xl-9">

                                            <div className="row mb-3 w-100">
                                                <div className="col-md-6 col-lg-5">
                                                    <div className="form-group">
                                                        <Label>Ordenar por</Label>
                                                        <Input type="select" name="orderBy" id="orderBy" className="w-100" value={this.state.orderBy} onChange={this.changeOrder}  bsSize="sm">
                                                            <option value="años">Años de experiencia</option>
                                                            <option value="aspiracion">Aspiración Salarial</option>
                                                            <option value="updatedat">Fecha de postulación</option>
                                                            <option value="nombres">Nombre</option>
                                                        </Input>
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            { this.state.sinResultadosBusqueda &&
                                                <div className="search-false">
                                                    <div className="row">
                                                        <div className="col-12 p-3">

                                                            <Jumbotron>
                                                                <h1 className="display-3 text-center">Lo sentimos!</h1>
                                                                <p className="lead"> En estos momentos no tenemos resultados, sin embargo guardamos tu busqueda y <strong>te notificaremos por correo y por whatsapp cuando tengamos un especialista</strong> que cumpla con estas caracteristicas.</p>
                                                                <hr className="my-2" />
                                                                <p className="text-center">si desea realizar otra busqueda presione el botón Regresar.</p>
                                                                <p className="lead text-center">
                                                                    <Button id="new_search" type="button" color="primary" onClick={this.handleNewSearch}
                                                                        >Regresar <i className="zmdi zmdi-search-replace ml-sm-2"></i>
                                                                    </Button>
                                                                </p>
                                                            </Jumbotron>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            { !this.state.sinResultadosBusqueda &&
                                                <div className="search-true">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="d-table-cell">Nombre</th>
                                                                <th scope="col" className="d-none d-md-table-cell">Tipo de perfil</th>
                                                                <th scope="col" className="d-sm-table-cell">Años Exp.</th>
                                                                {!this.state.perfil.sincosto && <th scope="col" className="d-sm-table-cell">Costo Mes</th>}
                                                                <th scope="col" className="text-center">Acciones</th>
                                                            </tr>
                                                        </thead>                                                        
                                                        <tbody>
                                                            {this.state.programadores 
                                                                .slice (this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize) 
                                                                .map ((data, key) => { 
                                                                return ( 
                                                                    <tr key={key}>
                                                                        <td className="d-table-cell">{this.splitNombres(data.nombres)} {this.splitNombres(data.apellidos)}</td>
                                                                        <td className="d-none d-md-table-cell" style={{width: 320}}  >
                                                                            {data.tipoPerfiles.map((per, k) => ( 
                                                                                <span key={k}>{per} {k !== (data.tipoPerfiles.length -1)  ? ',' : '' }&nbsp;</span>
                                                                            ))}
                                                                        </td>
                                                                        <td className="d-table-cell">{data.anosExperiencia}</td>
                                                                        {!this.state.perfil.sincosto && 
                                                                        <td className="d-table-cell">
                                                                            {data.costoMes}  {/*calcularCosto(data.costoMes,this.state.perfil.pais)*/}
                                                                        </td>
                                                                        }
                                                                        <td className="text-center" style={{display: 'flex'}} > 

                                                                            <Tooltip
                                                                                placement="top"
                                                                                isOpen={this.isToolTipOpen(`ver-${key}`)}
                                                                                target={`ver-${key}`}
                                                                                toggle={() => this.toggle(`ver-${key}`)}>
                                                                                Ver Detalles
                                                                            </Tooltip>

                                                                            <Link to={{pathname: `/detalle/${data.id}`}} id={'ver-'+ key} target={"_blank"}
                                                                                className="btn btn-info btn-sm" >   
                                                                                {/*<Button color="info" size="sm"
                                                                                    onClick={(e) => this.saveBusq() }  
                                                                                >
                                                                                    Ver
                                                                                </Button>*/}
                                                                                 Ver
                                                                            </Link>

                                                                            {/*<Link id={'ver-'+key} className="btn btn-info btn-sm" to={{
                                                                                pathname: `/detalle/${data.id}`,
                                                                            }}>                                                                 
                                                                                Ver                                            
                                                                            </Link>  */}                                                        
                                                                            {/*<Button type="button" color="danger" onClick={() => {this.ocultar(key); }} style={{marginLeft: 5}} size="sm" >
                                                                                <FaEyeSlash id="tooltip2cerrarx" />                                                                  
                                                                            </Button>*/}
                                                                            <Tooltip
                                                                                placement="top"
                                                                                isOpen={this.isToolTipOpen(`pdf-${key}`)}
                                                                                target={`pdf-${key}`}
                                                                                toggle={() => this.toggle(`pdf-${key}`)}>
                                                                                Exportar PDF
                                                                            </Tooltip>
                                                                            <Button id={'pdf-'+key} type="button" color="primary" onClick={(event) => {this.exportarPDF(data); }} style={{marginLeft: 5}} size="sm" >
                                                                                <FaFilePdf/>                                                                  
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>

                                                        
                                                    </table>
                                                    <div style={{display: 'flex', justifyContent: 'space-between'}} >
                                                        <span>Mostrando<b> {(this.state.currentPage * this.state.pageSize) + 1}&nbsp;</b> 
                                                            a <b>{this.itemsPerPage()}&nbsp;</b> 
                                                            de <b>{this.state.programadores.length}</b>
                                                        </span>
                                                        <Pagination size="sm" aria-label="Page navigation example">
                                                            {[...Array(this.state.pageCount)].map((page, i) => (
                                                                <PaginationItem active={i === this.state.currentPage} key={i}>
                                                                    <PaginationLink onClick={e => this.handlePageClick(e, i)} href="#">
                                                                        {i + 1}
                                                                    </PaginationLink>
                                                                </PaginationItem>
                                                            ))}
                                                        </Pagination>
                                                    </div>

                                                </div>
                                            }
                                        </div>                                      
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                <span>Hispanos Soluciones C.A. <FaRegCopyright/> 2020.  Conctacto: <a href="mailto:info@hispanossoluciones.com">info@hispanossoluciones.com</a></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }   
            </LoadingOverlay>
            </div>
        );
    }
}

export default ListadoPostulado;