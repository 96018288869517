const busquedaReducer = (state='', action) =>{
    switch (action.type){
        case 'SET_BUSQUEDA':
            return action.busqueda;
        case 'CLEAR_BUSQUEDA':
            return '';
        default :
            return state;
    }
}

export default busquedaReducer