import React  from 'react';
import { Input, Label } from 'reactstrap';


function Curso(props) {
    return <div className="form-group row m-0">
        <div className="col-12">
            <div className="form-holder">
                <Label>Nombre del curso</Label>
                <Input type="text" className="form-control" id="" name="" value={props.curso.cursoNombre} readOnly={true} />
            </div>
        </div>
        <div className="col-12">
            <div className="form-holder">
                <Label><b>Descripción</b></Label>
                <p style={{textAlign: 'justify'}}>
                    {props.curso.cursoDescripcion}
                </p>
            </div>
        </div>
    </div>;
}

export default Curso;