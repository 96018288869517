import React, {useState}  from 'react';
import Logo from '../img/logo-hispanos.png';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
  } from 'reactstrap';

function Menu(props) {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return <header className="header position-fixed w-100 shadow-lg">
            <Navbar className="p-0" dark expand="md">
                <NavbarBrand href="/inicio">
                    <img src={Logo} width="60" height="60" className="d-inline-block align-top" alt="" />  
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>

                {props.admin === false && (
                    <Nav className="ml-auto" navbar>
                        <NavItem className="px-1 text-center" active={props.link === 'inicio'}>
                            <NavLink href="/inicio">Inicio</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'misbusquedas'}>
                            <NavLink href="/misbusquedas">Búsquedas</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'misagendas'}>
                            <NavLink  href="/misagendas">Agendas</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'datosfiscales'}>
                            <NavLink href="/datosfiscales">Datos Fiscales</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center">
                            <NavLink href="/" onClick={props.logout}>Cerrar sesión</NavLink>
                        </NavItem>
                    </Nav>
                )}
                {props.admin === true && (
                    <Nav className="ml-auto" navbar>
                        <NavItem className="px-1 text-center" active={props.link === 'inicio'}>
                            <NavLink href="/inicio">Inicio</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'agendas'}>
                            <NavLink href="/agendas">Agendas</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'busquedas'}>
                            <NavLink  href="/busquedas">Búsquedas</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'coincidencias'}>
                            <NavLink href="/coincidencias">Coincidencias</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center" active={props.link === 'empresas'}>
                            <NavLink href="/empresas">Empresas</NavLink>
                        </NavItem>
                        <NavItem className="px-1 text-center">
                            <NavLink href="/" onClick={props.logout}>Cerrar sesión</NavLink>
                        </NavItem>
                    </Nav>
                )}
                </Collapse>
            </Navbar>

        </header>
}

export default Menu;