import React, { Component } from 'react';
import { Input, Button, Label, Form, Alert } from 'reactstrap';
import firebase from '../firebase/firebase';
import Menu from '../components/Menu';
import moment from 'moment';

class DatosFiscales  extends Component {
    constructor(props) {
        super(props);

        this.update = this.update.bind(this);
        this.onDismissAlert = this.onDismissAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            identificacionFiscal: '',
            ciudad: '',
            direccion: '',
            visibleAlert: false,
            typeAlert: '',
            messageAlertt: '',
            perfil: { //datos de la empresa logueada
                admin: null,
                contrasena: "",
                email: "",
                fechaCreacion: "",
                nombreEmpresa: "",
                numWhatsapp: "",
                pais: "",
                representanteLegal: "",
                rol: '',
                userId: "",
                usuario: "",
                datosFiscales: {
                    identificacionFiscal: "",
                    direccion: "", 
                    empresa: ""
                },
                ocultos: []
            },
            loader: true
        }
    }

    componentDidMount(){
        this.getProfile()
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            
            this.setState({
                perfil: data,
                identificacionFiscal: data.datosFiscales.identificacionFiscal,
                ciudad: data.datosFiscales.ciudad,
                direccion: data.datosFiscales.direccion,
                numWhatsapp: data.numWhatsapp,
                representanteLegal: data.representanteLegal,
                nombreEmpresa: data.nombreEmpresa,
                loader: false
            });
            firebase.db.collection("log").add({ 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                accion: 'Entro en la ruta Datos Fiscales', 
                tipo: 'empresa',
                info: data,
                modulo: 'Datos Fiscales' 
            });
        });
    }

    update(e){
        e.preventDefault();
        const objSave = {
            datosFiscales: {
                identificacionFiscal: this.state.identificacionFiscal,
                ciudad: this.state.ciudad,
                direccion: this.state.direccion,
            },
            numWhatsapp: this.state.numWhatsapp,
            representanteLegal: this.state.representanteLegal,
            nombreEmpresa: this.state.nombreEmpresa
        }
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
            .update(objSave)
            .then(result => {
                this.setState({
                    typeAlert: 'success',
                    messageAlert: 'Datos Actualizados',
                });
                setTimeout(() =>{ this.setState({ visibleAlert: true }) }, 200);
            });
    }

    onDismissAlert(){
        this.setState({visibleAlert: !this.state.visibleAlert })
    }

    logout() {
        firebase.logout();
    }

    render(){
        return(
            <div>
                <Menu link='datosfiscales' admin={false} logout={this.logout} ></Menu>
                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!this.state.loader &&
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row w-100 align-items-center justify-content-center">
                        <div className="col-12">
                            <Form className="hoja-vida form-fiscales rounded border border-secondary shadow-lg bg-light" onSubmit={this.update} >

                                <div className="form-header">
                                    <div className="row">
                                        <div className="col-12 py-3">
                                            <h2 className="text-center">Datos Fiscales</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content p-1 px-md-1 pt-md-1 pb-md-0">
                                
                                    <div className="datos">
                                    
                                        <div className="datos-content w-100 py-3">
                                            <div className="form-group row m-0">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-holder">
                                                        <Label>Identificación fiscal</Label>
                                                        <Input type="text" className="form-control" id="identificacionFiscal" name="identificacionFiscal" value={this.state.identificacionFiscal} placeholder="Identificador Fiscal" 
                                                             onChange={this.handleChange} />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="form-holder">
                                                        <Label for="representanteLegal">Representante Legal:</Label>
                                                        <Input name="representanteLegal" id="representanteLegal" className="form-control" type="text" value={this.state.representanteLegal} placeholder="Nombre y apellido del representante legal" 
                                                            onChange={this.handleChange}  />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row m-0">
                                                <div className="col-12">
                                                    <div className="form-holder">
                                                        <Label for="nombreEmpresa">Nombre Empresa:</Label>
                                                        <Input name="nombreEmpresa" id="nombreEmpresa" className="form-control" type="text" value={this.state.nombreEmpresa} placeholder="Nombre de la empresa" 
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row m-0">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-holder">
                                                        <Label>Ciudad</Label>
                                                        <Input type="text" name="ciudad" id="ciudad" className="form-control" value={this.state.ciudad} placeholder="Ciudad donde esta ubicada la empresa" 
                                                             onChange={this.handleChange} />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="form-holder ">
                                                        <Label>Número de whatsapp</Label>
                                                        <Input type="text" className="form-control" id="numWhatsapp" name="numWhatsapp" value={this.state.numWhatsapp} placeholder="(+009999999999)"
                                                             onChange={this.handleChange}  />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group row m-0">
                                                <div className="col">
                                                    <div className="form-holder ">
                                                        <Label>Dirección</Label>
                                                        <Input type="text" className="form-control" id="direccion" name="direccion" value={this.state.direccion} placeholder="Ubicación de la empresa" 
                                                        onChange={this.handleChange}  />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row m-0">
                                                <div className="col">
                                                    <Alert color={this.state.typeAlert} isOpen={this.state.visibleAlert} toggle={this.onDismissAlert} >
                                                        {this.state.messageAlert}
                                                    </Alert>
                                                </div>
                                            </div>

                                            <div className="form-group row m-1">
                                                <div className="col  text-center">
                                                    <Button type="submit" color="success">Actualizar</Button>
                                                </div>
                                            </div>

                                    
                                        </div>
                                    </div>

                                </div>

                             </Form>
                        </div>
                    </div>
                </main>
            }
            </div>
        )
    }
}

export default DatosFiscales;

