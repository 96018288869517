export default {
    tecnologiasData: [
        '.NET',
        '.Net Core',
        'Access',
        'Adobe XD',
        'AdonisJS',
        'AJAX',
        'Android',
        'Angular',
        'Antdesign',
        'API/S',
        'App Inventor',
        'ASP Clasico',
        'ASP.NET',
        'Babel',
        'Bitbucket',
        'Bootstrap',
        'C',
        'C#',
        'C++',
        'CakePHP',
        'Cassandra',
        'CMS',
        'Codeigniter',
        'Compass SASS',
        'ComponentOne',
        'Cristal Report',
        'Crystal Reports',
        'CSS',
        'Dart',
        'DevExpress',
        'Django',
        'Docker',
        'Dreamweaver',
        'Entity Framework', 
        'Entity Framework Core',
        'Express',
        'FireWork',
        'Flutter',
        'Foundation',
        'Frontend',
        'Git',
        'Git Workflow',
        'Github',
        'Gitlab',
        'GraphQL',
        'GULP',
        'Hibernate',
        'HTML',
        'Infragistic',
        'Infragistics',
        'Ionic',
        'iOS',
        'Jakarta-Tomcat',
        'Java',
        'Javascript',
        'JBoos',
        'JQuery',
        'JSON',
        'JSON Web Tokens',
        'Keras',
        'Kotlin',
        'KPIs',
        'Kubernetes',
        'KumbiaPHP',
        'Laravel',
        'LESS',
        'Linq',
        'Linux',
        'Machine Learning',
        'Magento',
        'Material Design',
        'Materialize',
        'MaterialUI',
        'Matplotlib',
        'MEAN',
        'Mercurial',
        'MERN',
        'MEVN',
        'Mocha.js',
        'MongoDB',
        'Moongochef',
        'MVC',
        'MySQL',
        'NetBeans IDE',
        'NGINX',
        'NodeJS',
        'Notepad++',
        'Numpy',
        'Objective-C',
        'Odoo',
        'Oracle',
        'Oracle Apex',
        'Oracle Forms & Report',
        'Pandas',
        'Pentaho BI',
        'Photoshop',
        'PHP',
        'POO',
        'Postgre',
        'PostgreSQL',
        'PrestaShop ',
        'PuTTY',
        'PWAs',
        'Python',
        'Python 3.5.3',
        'QA',
        'Quasar',
        'React',
        'ReportViwer',
        'REST',
        'RESTful',
        'SASS',
        'Scikit-learn.',
        'SciPy',
        'Scrappy',
        'SCRUM',
        'Seaborn',
        'Selenium',
        'SEO/SEM.',
        'Sharepoint',
        'Smush',
        'SO Linux/Windows',
        'SOAP',
        'Socket.IO',
        'SQL Server',
        'SQLite3',
        'Stored Procedure',
        'SVN',
        'Swift',
        'Symfony',
        'Syncfusion',
        'Telerik',
        'TensorFlow',
        'Transact-SQL',
        'TravisCI',
        'UML',
        'Visual Basic',
        'Visual Studio',
        'Visual Studio Code',
        'VueJS',
        'W3',
        'Webpack',
        'Websphere',
        'Woocomerce',
        'Wordpress',
        'Xamarin',
        'XML',
        'Yii',
        'Yoast SEO',
        'Zend Framework'
    ],

    paises: [
        "Afganistan", 
        "Albania", 
        "Alemania", 
        "Andorra", 
        "Angola", 
        "Antartida", 
        "Antigua y Barbuda", 
        "Arabia Saudi", 
        "Argelia", 
        "Argentina", 
        "Armenia", 
        "Australia", 
        "Austria", 
        "Azerbaiyan", 
        "Bahamas", 
        "Bahrain", 
        "Bangladesh", 
        "Barbados", 
        "Belgica", 
        "Belice", 
        "Benin", 
        "Bermudas", 
        "Bielorrusia", 
        "Birmania Myanmar", 
        "Bolivia", 
        "Bosnia y Herzegovina", 
        "Botswana", 
        "Brasil", 
        "Brunei", 
        "Bulgaria", 
        "Burkina Faso", 
        "Burundi", 
        "Butan", 
        "Cabo Verde", 
        "Camboya", 
        "Camerun", 
        "Canada", 
        "Chad", 
        "Chile", 
        "China", 
        "Chipre", 
        "Colombia", 
        "Comores", 
        "Congo", 
        "Corea del Norte", 
        "Corea del Sur", 
        "Costa de Marfil", 
        "Costa Rica", 
        "Croacia", 
        "Cuba", 
        "Dinamarca", 
        "Dominica", 
        "Ecuador", 
        "Egipto", 
        "El Salvador", 
        "El Vaticano", 
        "Emiratos arabes Unidos", 
        "Eritrea", 
        "Eslovaquia", 
        "Eslovenia", 
        "España", 
        "Estados Unidos", 
        "Estonia", 
        "Etiopia", 
        "Filipinas", 
        "Finlandia", 
        "Fiji", 
        "Francia", 
        "Gabon", 
        "Gambia", 
        "Georgia", 
        "Ghana", 
        "Gibraltar", 
        "Granada", 
        "Grecia", 
        "Guam", 
        "Guatemala", 
        "Guinea", 
        "Guinea Ecuatorial", 
        "Guinea Bissau", 
        "Guyana", 
        "Haiti", 
        "Honduras", 
        "Hungria", 
        "India", 
        "Indian Ocean", 
        "Indonesia", 
        "Iran", 
        "Iraq", 
        "Irlanda", 
        "Islandia", 
        "Israel", 
        "Italia", 
        "Jamaica", 
        "Japon", 
        "Jersey", 
        "Jordania", 
        "Kazajstan", 
        "Kenia", 
        "Kirguistan", 
        "Kiribati", 
        "Kuwait", 
        "Laos", 
        "Lesoto", 
        "Letonia", 
        "Libano", 
        "Liberia", 
        "Libia", 
        "Liechtenstein", 
        "Lituania", 
        "Luxemburgo", 
        "Macedonia", 
        "Madagascar", 
        "Malasia", 
        "Malawi", 
        "Maldivas", 
        "Mali", 
        "Malta", 
        "Marruecos", 
        "Mauricio", 
        "Mauritania", 
        "Mexico", 
        "Micronesia", 
        "Moldavia", 
        "Monaco", 
        "Mongolia", 
        "Montserrat", 
        "Mozambique", 
        "Namibia", 
        "Nauru", 
        "Nepal", 
        "Nicaragua", 
        "Niger", 
        "Nigeria", 
        "Noruega", 
        "Nueva Zelanda", 
        "Oman", 
        "Paises Bajos", 
        "Pakistan", 
        "Palau", 
        "Panama", 
        "Papua Nueva Guinea", 
        "Paraguay", 
        "Peru", 
        "Polonia", 
        "Portugal", 
        "Puerto Rico", 
        "Qatar", 
        "Reino Unido", 
        "Republica Centroafricana", 
        "Republica Checa", 
        "Republica Democratica del Congo", 
        "Republica Dominicana", 
        "Ruanda", 
        "Rumania", 
        "Rusia", 
        "Sahara Occidental", 
        "Samoa", 
        "San Cristobal y Nevis", 
        "San Marino", 
        "San Vicente y las Granadinas", 
        "Santa Lucia", 
        "Santo Tome y Principe", 
        "Senegal", 
        "Seychelles", 
        "Sierra Leona", 
        "Singapur", 
        "Siria", 
        "Somalia", 
        "Southern Ocean", 
        "Sri Lanka", 
        "Swazilandia", 
        "Sudafrica", 
        "Sudan", 
        "Suecia", 
        "Suiza", 
        "Surinam", 
        "Tailandia", 
        "Taiwan", 
        "Tanzania", 
        "Tayikistan", 
        "Togo", 
        "Tokelau", 
        "Tonga", 
        "Trinidad y Tobago", 
        "Tunez", 
        "Turkmekistan", 
        "Turquia", 
        "Tuvalu", 
        "Ucrania", 
        "Uganda", 
        "Uruguay", 
        "Uzbekistan", 
        "Vanuatu", 
        "Venezuela", 
        "Vietnam", 
        "Yemen", 
        "Djibouti", 
        "Zambia", 
        "Zimbabue" 
    ],
    arrayTimeZones :
    [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Addis_Ababa",
        "Africa/Algiers",
        "Africa/Asmara",
        "Africa/Asmera",
        "Africa/Bamako",
        "Africa/Bangui",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Blantyre",
        "Africa/Brazzaville",
        "Africa/Bujumbura",
        "Africa/Cairo",
        "Africa/Casablanca",
        "Africa/Ceuta",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Douala",
        "Africa/El_Aaiun",
        "Africa/Freetown",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Kigali",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Lome",
        "Africa/Luanda",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Malabo",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Africa/Mogadishu",
        "Africa/Monrovia",
        "Africa/Nairobi",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Porto-Novo",
        "Africa/Sao_Tome",
        "Africa/Timbuktu",
        "Africa/Tripoli",
        "Africa/Tunis",
        "Africa/Windhoek",
        "America/Adak",
        "America/Anchorage",
        "America/Anguilla",
        "America/Antigua",
        "America/Araguaina",
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/ComodRivadavia",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Aruba",
        "America/Asuncion",
        "America/Atikokan",
        "America/Atka",
        "America/Bahia",
        "America/Bahia_Banderas",
        "America/Barbados",
        "America/Belem",
        "America/Belize",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Bogota",
        "America/Boise",
        "America/Buenos_Aires",
        "America/Cambridge_Bay",
        "America/Campo_Grande",
        "America/Cancun",
        "America/Caracas",
        "America/Catamarca",
        "America/Cayenne",
        "America/Cayman",
        "America/Chicago",
        "America/Chihuahua",
        "America/Coral_Harbour",
        "America/Cordoba",
        "America/Costa_Rica",
        "America/Creston",
        "America/Cuiaba",
        "America/Curacao",
        "America/Danmarkshavn",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Denver",
        "America/Detroit",
        "America/Dominica",
        "America/Edmonton",
        "America/Eirunepe",
        "America/El_Salvador",
        "America/Ensenada",
        "America/Fort_Nelson",
        "America/Fort_Wayne",
        "America/Fortaleza",
        "America/Glace_Bay",
        "America/Godthab",
        "America/Goose_Bay",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guatemala",
        "America/Guayaquil",
        "America/Guyana",
        "America/Halifax",
        "America/Havana",
        "America/Hermosillo",
        "America/Indiana/Indianapolis",
        "America/Indiana/Knox",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Tell_City",
        "America/Indiana/Vevay",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indianapolis",
        "America/Inuvik",
        "America/Iqaluit",
        "America/Jamaica",
        "America/Jujuy",
        "America/Juneau",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Knox_IN",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lima",
        "America/Los_Angeles",
        "America/Louisville",
        "America/Lower_Princes",
        "America/Maceio",
        "America/Managua",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Mendoza",
        "America/Menominee",
        "America/Merida",
        "America/Metlakatla",
        "America/Mexico_City",
        "America/Miquelon",
        "America/Moncton",
        "America/Monterrey",
        "America/Montevideo",
        "America/Montreal",
        "America/Montserrat",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Nome",
        "America/Noronha",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Ojinaga",
        "America/Panama",
        "America/Pangnirtung",
        "America/Paramaribo",
        "America/Phoenix",
        "America/Port-au-Prince",
        "America/Port_of_Spain",
        "America/Porto_Acre",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Punta_Arenas",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Recife",
        "America/Regina",
        "America/Resolute",
        "America/Rio_Branco",
        "America/Rosario",
        "America/Santa_Isabel",
        "America/Santarem",
        "America/Santiago",
        "America/Santo_Domingo",
        "America/Sao_Paulo",
        "America/Scoresbysund",
        "America/Shiprock",
        "America/Sitka",
        "America/St_Barthelemy",
        "America/St_Johns",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Swift_Current",
        "America/Tegucigalpa",
        "America/Thule",
        "America/Thunder_Bay",
        "America/Tijuana",
        "America/Toronto",
        "America/Tortola",
        "America/Vancouver",
        "America/Virgin",
        "America/Whitehorse",
        "America/Winnipeg",
        "America/Yakutat",
        "America/Yellowknife",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Macquarie",
        "Antarctica/Mawson",
        "Antarctica/McMurdo",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/South_Pole",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok",
        "Arctic/Longyearbyen",
        "Asia/Aden",
        "Asia/Almaty",
        "Asia/Amman",
        "Asia/Anadyr",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Ashkhabad",
        "Asia/Atyrau",
        "Asia/Baghdad",
        "Asia/Bahrain",
        "Asia/Baku",
        "Asia/Bangkok",
        "Asia/Barnaul",
        "Asia/Beirut",
        "Asia/Bishkek",
        "Asia/Brunei",
        "Asia/Calcutta",
        "Asia/Chita",
        "Asia/Choibalsan",
        "Asia/Chongqing",
        "Asia/Chungking",
        "Asia/Colombo",
        "Asia/Dacca",
        "Asia/Damascus",
        "Asia/Dhaka",
        "Asia/Dili",
        "Asia/Dubai",
        "Asia/Dushanbe",
        "Asia/Famagusta",
        "Asia/Gaza",
        "Asia/Harbin",
        "Asia/Hebron",
        "Asia/Ho_Chi_Minh",
        "Asia/Hong_Kong",
        "Asia/Hovd",
        "Asia/Irkutsk",
        "Asia/Istanbul",
        "Asia/Jakarta",
        "Asia/Jayapura",
        "Asia/Jerusalem",
        "Asia/Kabul",
        "Asia/Kamchatka",
        "Asia/Karachi",
        "Asia/Kashgar",
        "Asia/Kathmandu",
        "Asia/Katmandu",
        "Asia/Khandyga",
        "Asia/Kolkata",
        "Asia/Krasnoyarsk",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Kuwait",
        "Asia/Macao",
        "Asia/Macau",
        "Asia/Magadan",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Muscat",
        "Asia/Nicosia",
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk",
        "Asia/Oral",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Pyongyang",
        "Asia/Qatar",
        "Asia/Qostanay",
        "Asia/Qyzylorda",
        "Asia/Rangoon",
        "Asia/Riyadh",
        "Asia/Saigon",
        "Asia/Sakhalin",
        "Asia/Samarkand",
        "Asia/Seoul",
        "Asia/Shanghai",
        "Asia/Singapore",
        "Asia/Srednekolymsk",
        "Asia/Taipei",
        "Asia/Tashkent",
        "Asia/Tbilisi",
        "Asia/Tehran",
        "Asia/Tel_Aviv",
        "Asia/Thimbu",
        "Asia/Thimphu",
        "Asia/Tokyo",
        "Asia/Tomsk",
        "Asia/Ujung_Pandang",
        "Asia/Ulaanbaatar",
        "Asia/Ulan_Bator",
        "Asia/Urumqi",
        "Asia/Ust-Nera",
        "Asia/Vientiane",
        "Asia/Vladivostok",
        "Asia/Yakutsk",
        "Asia/Yangon",
        "Asia/Yekaterinburg",
        "Asia/Yerevan",
        "Atlantic/Azores",
        "Atlantic/Bermuda",
        "Atlantic/Canary",
        "Atlantic/Cape_Verde",
        "Atlantic/Faeroe",
        "Atlantic/Faroe",
        "Atlantic/Jan_Mayen",
        "Atlantic/Madeira",
        "Atlantic/Reykjavik",
        "Atlantic/South_Georgia",
        "Atlantic/St_Helena",
        "Atlantic/Stanley",
        "Australia/ACT",
        "Australia/Adelaide",
        "Australia/Brisbane",
        "Australia/Broken_Hill",
        "Australia/Canberra",
        "Australia/Currie",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Hobart",
        "Australia/LHI",
        "Australia/Lindeman",
        "Australia/Lord_Howe",
        "Australia/Melbourne",
        "Australia/NSW",
        "Australia/North",
        "Australia/Perth",
        "Australia/Queensland",
        "Australia/South",
        "Australia/Sydney",
        "Australia/Tasmania",
        "Australia/Victoria",
        "Australia/West",
        "Australia/Yancowinna",
        "Brazil/Acre",
        "Brazil/DeNoronha",
        "Brazil/East",
        "Brazil/West",
        "CET",
        "CST6CDT",
        "Canada/Atlantic",
        "Canada/Central",
        "Canada/Eastern",
        "Canada/Mountain",
        "Canada/Newfoundland",
        "Canada/Pacific",
        "Canada/Saskatchewan",
        "Canada/Yukon",
        "Chile/Continental",
        "Chile/EasterIsland",
        "Cuba",
        "EET",
        "EST",
        "EST5EDT",
        "Egypt",
        "Eire",
        "Etc/GMT",
        "Etc/GMT+0",
        "Etc/GMT+1",
        "Etc/GMT+10",
        "Etc/GMT+11",
        "Etc/GMT+12",
        "Etc/GMT+2",
        "Etc/GMT+3",
        "Etc/GMT+4",
        "Etc/GMT+5",
        "Etc/GMT+6",
        "Etc/GMT+7",
        "Etc/GMT+8",
        "Etc/GMT+9",
        "Etc/GMT-0",
        "Etc/GMT-1",
        "Etc/GMT-10",
        "Etc/GMT-11",
        "Etc/GMT-12",
        "Etc/GMT-13",
        "Etc/GMT-14",
        "Etc/GMT-2",
        "Etc/GMT-3",
        "Etc/GMT-4",
        "Etc/GMT-5",
        "Etc/GMT-6",
        "Etc/GMT-7",
        "Etc/GMT-8",
        "Etc/GMT-9",
        "Etc/GMT0",
        "Etc/Greenwich",
        "Etc/UCT",
        "Etc/UTC",
        "Etc/Universal",
        "Etc/Zulu",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Astrakhan",
        "Europe/Athens",
        "Europe/Belfast",
        "Europe/Belgrade",
        "Europe/Berlin",
        "Europe/Bratislava",
        "Europe/Brussels",
        "Europe/Bucharest",
        "Europe/Budapest",
        "Europe/Busingen",
        "Europe/Chisinau",
        "Europe/Copenhagen",
        "Europe/Dublin",
        "Europe/Gibraltar",
        "Europe/Guernsey",
        "Europe/Helsinki",
        "Europe/Isle_of_Man",
        "Europe/Istanbul",
        "Europe/Jersey",
        "Europe/Kaliningrad",
        "Europe/Kiev",
        "Europe/Kirov",
        "Europe/Lisbon",
        "Europe/Ljubljana",
        "Europe/London",
        "Europe/Luxembourg",
        "Europe/Madrid",
        "Europe/Malta",
        "Europe/Mariehamn",
        "Europe/Minsk",
        "Europe/Monaco",
        "Europe/Moscow",
        "Europe/Nicosia",
        "Europe/Oslo",
        "Europe/Paris",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Riga",
        "Europe/Rome",
        "Europe/Samara",
        "Europe/San_Marino",
        "Europe/Sarajevo",
        "Europe/Saratov",
        "Europe/Simferopol",
        "Europe/Skopje",
        "Europe/Sofia",
        "Europe/Stockholm",
        "Europe/Tallinn",
        "Europe/Tirane",
        "Europe/Tiraspol",
        "Europe/Ulyanovsk",
        "Europe/Uzhgorod",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Vilnius",
        "Europe/Volgograd",
        "Europe/Warsaw",
        "Europe/Zagreb",
        "Europe/Zaporozhye",
        "Europe/Zurich",
        "GB",
        "GB-Eire",
        "GMT",
        "GMT+0",
        "GMT-0",
        "GMT0",
        "Greenwich",
        "HST",
        "Hongkong",
        "Iceland",
        "Indian/Antananarivo",
        "Indian/Chagos",
        "Indian/Christmas",
        "Indian/Cocos",
        "Indian/Comoro",
        "Indian/Kerguelen",
        "Indian/Mahe",
        "Indian/Maldives",
        "Indian/Mauritius",
        "Indian/Mayotte",
        "Indian/Reunion",
        "Iran",
        "Israel",
        "Jamaica",
        "Japan",
        "Kwajalein",
        "Libya",
        "MET",
        "MST",
        "MST7MDT",
        "Mexico/BajaNorte",
        "Mexico/BajaSur",
        "Mexico/General",
        "NZ",
        "NZ-CHAT",
        "Navajo",
        "PRC",
    ]      
}


