import React, {Component} from 'react';
import firebase from '../firebase/firebase';
import { Input } from 'reactstrap';
import Menu from './Menu';
import moment from 'moment';

class MisAgendas extends Component {
    constructor(props){
        super(props);

        this.changeRealizo = this.changeRealizo.bind(this);
        this.logout  = this.logout.bind(this);
        
        this.state = {
            agendas: [],
            loader: true
        }
    }

    componentDidMount(){
        this.getProfile();
        this.loadAgendas();
    }

    getProfile(){
        firebase.db.collection("empresas").doc(`${firebase.auth.currentUser.uid}`)
        .get()
        .then(doc => {
            const data = doc.data();
            this.setState({
                perfil: data
            });
            firebase.db.collection("log").add({ 
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                accion: 'Entro en la ruta Agendas ', 
                tipo: 'empresa',
                info: data,
                modulo: 'Mis Agendas' 
            });
        });
    }

    loadAgendas(){
        firebase.db.collection("agendas")
        .where("uid", "==", `${firebase.auth.currentUser.uid}`)
        .get()
        .then(querySnapshot => {
            let objetoAgenda;
            let arrayagendas = []
            querySnapshot.forEach((doc) => {
                objetoAgenda = doc.data();
                objetoAgenda.id = doc.id
                arrayagendas.push(objetoAgenda)
            });
            this.setState({ agendas: arrayagendas, loader: false })
        });
    }

    changeRealizo(event){
        let agendas = this.state.agendas
        agendas.forEach(agen => {
            if (agen.id === event.target.value){
                agen.seRealizo = event.target.checked
                firebase.db.collection("agendas").doc(`${agen.id}`).update({seRealizo: event.target.checked});
            }
        })
        this.setState({agendas: agendas});
    }

    logout() {
        firebase.logout();
    }

    render(){
        return(
            <div>
                <Menu link='misagendas' admin={false} logout={this.logout} ></Menu>

                {this.state.loader &&
                    <div id="loader">
                        <div className="lds-heart"><div></div></div>
                    </div>
                }
            {!this.state.loader &&
                <main className="container d-flex align-items-center justify-content-center topMain mb-3">
                    <div className="row w-100 h-100 align-items-center justify-content-center">
                        <div className="row p-0 w-100 ">
                            <div className="col-12 p-0 list-postul rounded border border-secondary shadow-lg">
                                <div className="list-postul-header text-center p-3">
                                    <h2>Agendas</h2>
                                </div>
                                <div className="list-postul-content py-3 px-2 px-md-5">

                                <div className="search-true">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="d-table-cell">Programador</th>
                                                    <th scope="col" className="d-none d-md-table-cell">Fecha/Hora</th>
                                                    <th scope="col" className="d-none d-sm-table-cell">Pais</th>
                                                    <th scope="col" className="d-none d-table-cell text-center">Se realizó</th>
                                                </tr>
                                            </thead>                  
                                            <tbody>
                                            {this.state.agendas.map((data, key) => (
                                                <tr key={key}>
                                                    <td className="d-table-cell">{data.programador.nombres.split(' ')[0]} {data.programador.apellidos.split(' ')[0]}</td>
                                                    <td className="d-none d-md-table-cell">{data.fechaAgendado} {data.horaAgendado}</td>
                                                    <td className="d-none d-md-table-cell">{data.empresa.pais}</td>
                                                    <td className="d-none d-md-table-cell text-center">
                                                        <Input  type="checkbox" value={data.id} checked={data.seRealizo} onChange={this.changeRealizo} style={{position:'relative'}} /> 
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }
            </div>
        );
    }
}

export default MisAgendas;