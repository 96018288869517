export const saveToken = (token) => {
    return {
        type: 'SET_TOKEN',
        token: token
    }
}

export const setPerfil = (perfil) => {
    return {
        type: 'SET_PERFIL',
        perfil: perfil
    }
}

export const getPerfil = (perfil) => {
    return {
        type: 'GET_PERFIL',
        perfil
    }
}

export const setBusqueda = (busqueda) => {
    return {
        type: 'SET_BUSQUEDA',
        busqueda: busqueda
    }
}

export const getBusqueda = (busqueda) => {
    return {
        type: 'GET_BUSQUEDA',
        busqueda
    }
}