


const arrayTimezones=  [
    {cod: 'AF', name:'Afganistan',timezone: 'Asia/Kabul', utc: 'UTC +04:30'},
    {cod: 'AL', name:'Albania', timezone: 'Europe/Tirane', utc: 'UTC +02:00'},
    {cod: 'DZ', name:'Argelia', timezone: 'Africa/Algiers', utc: 'UTC +01:00'},
    {cod: 'AS', name:'American Samoa', timezone:'Pacific/Pago_Pago', utc: 'UTC -11:00'},
    {cod: 'AD', name:'Andorra',	timezone: 'Europe/Andorra', utc: 'UTC +02:00'},
    {cod: 'AO', name:'Angola', timezone: 'Africa/Luanda', utc: 'UTC +01:00'},
    {cod: 'AI', name:'Anguilla', timezone: 'America/Anguilla', utc: 'UTC -04:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Casey', utc: 'UTC +08:00'},
    /*{cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Davis', utc: 'UTC +07:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/DumontDUrville', utc: 'UTC +10:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Mawson', utc: 'UTC +05:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/McMurdo', utc: 'UTC +12:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Palmer', utc: 'UTC -03:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Rothera', utc: 'UTC -03:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Syowa', utc: 'UTC +03:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Troll', utc: 'UTC +02:00'},
    {cod: 'AQ', name:'Antártida', timezone: 'Antarctica/Vostok', utc: 'UTC +06:00'},*/
    {cod: 'AG', name:'Antigua y Barbuda', timezone: 'America/Antigua', utc: 'UTC -04:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Buenos_Aires', utc: 'UTC -03:00'},
    /*{cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Catamarca', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Cordoba', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Jujuy', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/La_Rioja', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Mendoza', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Rio_Gallegos', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Salta', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/San_Juan', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/San_Luis', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Tucuman', utc: 'UTC -03:00'},
    {cod: 'AR', name:'Argentina', timezone: 'America/Argentina/Ushuaia', utc: 'UTC -03:00'},*/
    {cod: 'AM', name:'Armenia', timezone: 'Asia/Yerevan', utc: 'UTC +04:00'},
    {cod: 'AW', name:'Aruba', timezone: 'America/Aruba', utc: 'UTC -04:00'},
    /*{cod: 'AU', name:'Australia', timezone: 'Antarctica/Macquarie', utc: 'UTC +11:00'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Adelaide', utc: 'UTC +09:30'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Brisbane', utc: 'UTC +10:00'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Broken_Hill', utc: 'UTC +09:30'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Currie', utc: 'UTC +10:00'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Darwin', utc: 'UTC +09:30'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Eucla', utc: 'UTC +08:45'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Hobart', utc: 'UTC +10:00'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Lindeman', utc: 'UTC +10:00'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Lord_Howe', utc: 'UTC +10:30'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Melbourne', utc: 'UTC +10:00'},
    {cod: 'AU', name:'Australia', timezone: 'Australia/Perth', utc: 'UTC +08:00'},*/
    {cod: 'AU', name:'Australia', timezone: 'Australia/Sydney', utc: 'UTC +10:00'},
    {cod: 'AT', name:'Austria', timezone: 'Europe/Vienna', utc: 'UTC +02:00'},
    {cod: 'AZ', name:'Azerbaiyán', timezone: 'Asia/Baku', utc: 'UTC +04:00'},
    {cod: 'BS', name:'Bahamas', timezone: 'America/Nassau', utc: 'UTC -04:00'},
    {cod: 'BH', name:'Bahrein', timezone: 'Asia/Bahrain', utc: 'UTC +03:00'},
    {cod: 'BD', name:'Bangladesh', timezone: 'Asia/Dhaka', utc: 'UTC +06:00'},
    {cod: 'BB', name:'Barbados', timezone: 'America/Barbados', utc: 'UTC -04:00'},
    {cod: 'BY', name:'Bielorrusia', timezone: 'Europe/Minsk', utc: 'UTC +03:00'},
    {cod: 'BE', name:'Bélgica', timezone: 'Europe/Brussels', utc: 'UTC +02:00'},
    {cod: 'BZ', name:'Belice', timezone: 'America/Belize', utc: 'UTC -06:00'},
    {cod: 'BJ', name:'Benin', timezone: 'Africa/Porto-Novo', utc: 'UTC +01:00'},
    {cod: 'BM', name:'Islas Bermudas', timezone: 'Atlantic/Bermuda', utc: 'UTC -03:00'},
    {cod: 'BT', name:'Bután', timezone: 'Asia/Thimphu', utc: 'UTC +06:00'},
    {cod: 'BO', name:'Bolivia', timezone: 'America/La_Paz', utc: 'UTC -04:00'},
    {cod: 'BQ', name:'Bonaire', timezone: 'America/Kralendijk', utc: 'UTC -04:00'},
    {cod: 'BA', name:'Bosnia y Herzegovina', timezone: 'Europe/Sarajevo', utc: 'UTC +02:00'},
    {cod: 'BW', name:'Botswana', timezone: 'Africa/Gaborone', utc: 'UTC +02:00'},
    /*{cod: 'BR', name:'Brasil', timezone: 'America/Araguaina', utc: 'UTC -03:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Bahia', utc: 'UTC -03:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Belem', utc: 'UTC -03:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Boa_Vista', utc: 'UTC -04:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Campo_Grande', utc: 'UTC -04:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Cuiaba', utc: 'UTC -04:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Eirunepe', utc: 'UTC -05:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Fortaleza', utc: 'UTC -03:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Maceio', utc: 'UTC -03:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Manaus', utc: 'UTC -04:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Noronha', utc: 'UTC -02:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Porto_Velho', utc: 'UTC -04:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Recife', utc: 'UTC -03:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Rio_Branco', utc: 'UTC -05:00'},
    {cod: 'BR', name:'Brasil', timezone: 'America/Santarem', utc: 'UTC -03:00'},*/
    {cod: 'BR', name:'Brasil', timezone: 'America/Sao_Paulo', utc: 'UTC -03:00'},
    {cod: 'IO', name:'Territorio Británico del Océano Índico', timezone: 'Indian/Chagos', utc: 'UTC +06:00'},
    {cod: 'BN', name:'Brunei Darussalam', timezone: 'Asia/Brunei', utc: 'UTC +08:00'},
    {cod: 'BG', name:'Bulgaria', timezone: 'Europe/Sofia', utc: 'UTC +03:00'},
    {cod: 'BF', name:'Burkina Faso', timezone: 'Africa/Ouagadougou', utc: 'UTC'},
    {cod: 'BI', name:'Burundi', timezone: 'Africa/Bujumbura', utc: 'UTC +02:00'},
    {cod: 'KH', name:'Camboya', timezone: 'Asia/Phnom_Penh', utc: 'UTC +07:00'},
    {cod: 'CM', name:'Camerún', timezone: 'Africa/Douala', utc: 'UTC +01:00'},
    /*{cod: 'CA', name:'Canadá', timezone: 'America/Atikokan', utc: 'UTC -05:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Blanc-Sablon', utc: 'UTC -04:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Cambridge_Bay', utc: 'UTC -06:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Creston', utc: 'UTC -07:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Dawson', utc: 'UTC -07:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Dawson_Creek', utc: 'UTC -07:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Edmonton', utc: 'UTC -06:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Fort_Nelson', utc: 'UTC -07:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Glace_Bay', utc: 'UTC -03:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Goose_Bay', utc: 'UTC -03:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Halifax', utc: 'UTC -03:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Inuvik', utc: 'UTC -06:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Iqaluit', utc: 'UTC -04:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Moncton', utc: 'UTC -03:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Nipigon', utc: 'UTC -04:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Pangnirtung', utc: 'UTC -04:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Rainy_River', utc: 'UTC -05:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Rankin_Inlet', utc: 'UTC -05:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Regina', utc: 'UTC -06:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Resolute', utc: 'UTC -05:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/St_Johns', utc: 'UTC -02:30'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Swift_Current', utc: 'UTC -06:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Thunder_Bay', utc: 'UTC -04:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Toronto', utc: 'UTC -04:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Whitehorse', utc: 'UTC -07:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Winnipeg', utc: 'UTC -05:00'},
    {cod: 'CA', name:'Canadá', timezone: 'America/Yellowknife', utc: 'UTC -06:00'},*/
    {cod: 'CA', name:'Canadá', timezone: 'America/Vancouver', utc: 'UTC -07:00'},
    {cod: 'CV', name:'Cabo Verde', timezone: 'Atlantic/Cape_Verde', utc: 'UTC -01:00'},
    {cod: 'KY', name:'Islas Caimán', timezone: 'America/Cayman', utc: 'UTC -05:00'},
    {cod: 'CF', name:'República Centroafricana', timezone: 'Africa/Bangui', utc: 'UTC +01:00'},
    {cod: 'TD', name:'Chad', timezone: 'Africa/Ndjamena', utc: 'UTC +01:00'},
    /*{cod: 'CL', name:'Chile', timezone: 'America/Punta_Arenas', utc: 'UTC -03:00'},
    {cod: 'CL', name:'Chile', timezone: 'Pacific/Easter', utc: 'UTC -06:00'},*/
    {cod: 'CL', name:'Chile', timezone: 'America/Santiago', utc: 'UTC -04:00'},
    {cod: 'CN', name:'China', timezone: 'Asia/Shanghai', utc: 'UTC +08:00'},
    //{cod: 'CN', name:'China', timezone: 'Asia/Urumqi', utc: 'UTC +06:00'},
    {cod: 'CX', name:'Isla de Navidad', timezone: 'Indian/Christmas', utc: 'UTC +07:00'},
    {cod: 'CC', name:'Islas Cocos (Keeling)', timezone: 'Indian/Cocos', utc: 'UTC +06:30'},
    {cod: 'CO', name:'Colombia', timezone: 'America/Bogota', utc: 'UTC -05:00'},
    {cod: 'KM', name:'Comoras', timezone: 'Indian/Comoro', utc: 'UTC +03:00'},
    {cod: 'CG', name:'Congo', timezone: 'Africa/Brazzaville', utc: 'UTC +01:00'},
    /*{cod: 'CD', name:'Congo, la República Democrática del', timezone: 'Africa/Kinshasa', utc: 'UTC +01:00'},
    {cod: 'CD', name:'Congo, la República Democrática del', timezone: 'Africa/Lubumbashi', utc: 'UTC +02:00'},*/
    {cod: 'CK', name:'Islas Cook', timezone: 'Pacific/Rarotonga', utc: 'UTC -10:00'},
    {cod: 'CR', name:'Costa Rica', timezone: 'America/Costa_Rica', utc: 'UTC -06:00'},
    {cod: 'HR', name:'Croacia', timezone: 'Europe/Zagreb', utc: 'UTC +02:00'},
    {cod: 'CU', name:'Cuba', timezone: 'America/Havana', utc: 'UTC -04:00'},
    {cod: 'CW', name:'Curazao', timezone: 'America/Curacao', utc: 'UTC -04:00'},
    {cod: 'CY', name:'Chipre', timezone: 'Asia/Famagusta', utc: 'UTC +03:00'},
    {cod: 'CY', name:'Chipre', timezone: 'Asia/Nicosia', utc: 'UTC +03:00'},
    {cod: 'CZ', name:'Republica checa', timezone: 'Europe/Prague', utc: 'UTC +02:00'},
    {cod: 'CI', name:'Costa de Marfil', timezone: 'Africa/Abidjan', utc: 'UTC'},
    {cod: 'DK', name:'Dinamarca', timezone: 'Europe/Copenhagen', utc: 'UTC +02:00'},
    {cod: 'DJ', name:'Djibouti', timezone: 'Africa/Djibouti', utc: 'UTC +03:00'},
    {cod: 'DM', name:'Dominica', timezone: 'America/Dominica', utc: 'UTC -04:00'},
    {cod: 'DO', name:'República Dominicana', timezone: 'America/Santo_Domingo', utc: 'UTC -04:00'},
    {cod: 'EC', name:'Ecuador', timezone: 'America/Guayaquil', utc: 'UTC -05:00'},
    //{cod: 'EC', name:'Ecuador', timezone: 'Pacific/Galapagos', utc: 'UTC -06:00'},
    {cod: 'EG', name:'Egipto', timezone: 'Africa/Cairo', utc: 'UTC +02:00'},
    {cod: 'SV', name:'El Salvador', timezone: 'America/El_Salvador', utc: 'UTC -06:00'},
    {cod: 'GQ', name:'Guinea Ecuatorial', timezone: 'Africa/Malabo', utc: 'UTC +01:00'},
    {cod: 'ER', name:'Eritrea', timezone: 'Africa/Asmara', utc: 'UTC +03:00'},
    {cod: 'EE', name:'Estonia', timezone: 'Europe/Tallinn', utc: 'UTC +03:00'},
    {cod: 'ET', name:'Etiopía', timezone: 'Africa/Addis_Ababa', utc: 'UTC +03:00'},
    {cod: 'FK', name:'Islas Malvinas', timezone: 'Atlantic/Stanley', utc: 'UTC -03:00'},
    {cod: 'FO', name:'Islas Faroe', timezone: 'Atlantic/Faroe', utc: 'UTC +01:00'},
    {cod: 'FJ', name:'Fiyi', timezone: 'Pacific/Fiji', utc: 'UTC +12:00'},
    {cod: 'FI', name:'Finlandia', timezone: 'Europe/Helsinki', utc: 'UTC +03:00'},
    {cod: 'FR', name:'Francia', timezone: 'Europe/Paris', utc: 'UTC +02:00'},
    {cod: 'GF', name:'Guayana Francesa', timezone: 'America/Cayenne', utc: 'UTC -03:00'},
    {cod: 'PF', name:'Polinesia francés', timezone: 'Pacific/Gambier', utc: 'UTC -09:00'},
    /*{cod: 'PF', name:'Polinesia francés', timezone: 'Pacific/Marquesas', utc: 'UTC -09:30'},
    {cod: 'PF', name:'Polinesia francés', timezone: 'Pacific/Tahiti', utc: 'UTC -10:00'},*/
    {cod: 'TF', name:'Territorios Franceses del Sur', timezone: 'Indian/Kerguelen', utc: 'UTC +05:00'},
    {cod: 'GA', name:'Gabón', timezone: 'Africa/Libreville', utc: 'UTC +01:00'},
    {cod: 'GM', name:'Gambia', timezone: 'Africa/Banjul', utc: 'UTC'},
    {cod: 'GE', name:'Georgia', timezone: 'Asia/Tbilisi', utc: 'UTC +04:00'},
    {cod: 'DE', name:'Alemania', timezone: 'Europe/Berlin', utc: 'UTC +02:00'},
    // {cod: 'DE', name:'Alemania', timezone: 'Europe/Busingen', utc: 'UTC +02:00'},
    {cod: 'GH', name:'Ghana', timezone: 'Africa/Accra', utc: 'UTC'},
    {cod: 'GI', name:'Gibraltar', timezone: 'Europe/Gibraltar', utc: 'UTC +02:00'},
    {cod: 'GR', name:'Grecia', timezone: 'Europe/Athens', utc: 'UTC +03:00'},
    {cod: 'GL', name:'Groenlandia', timezone: 'America/Danmarkshavn', utc: 'UTC'},
    /*{cod: 'GL', name:'Groenlandia', timezone: 'America/Nuuk', utc: 'UTC -02:00'},
    {cod: 'GL', name:'Groenlandia', timezone: 'America/Scoresbysund', utc: 'UTC'},
    {cod: 'GL', name:'Groenlandia', timezone: 'America/Thule', utc: 'UTC -03:00'},*/
    {cod: 'GD', name:'Granada', timezone: 'America/Grenada', utc: 'UTC -04:00'},
    {cod: 'GP', name:'Guadalupe', timezone: 'America/Guadeloupe', utc: 'UTC -04:00'},
    {cod: 'GU', name:'Guam', timezone: 'Pacific/Guam', utc: 'UTC +10:00'},
    {cod: 'GT', name:'Guatemala', timezone: 'America/Guatemala', utc: 'UTC -06:00'},
    {cod: 'GG', name:'Guernsey', timezone: 'Europe/Guernsey', utc: 'UTC +01:00'},
    {cod: 'GN', name:'Guinea', timezone: 'Africa/Conakry', utc: 'UTC'},
    {cod: 'GW', name:'Guinea-Bissau', timezone: 'Africa/Bissau', utc: 'UTC'},
    {cod: 'GY', name:'Guyana', timezone: 'America/Guyana', utc: 'UTC -04:00'},
    {cod: 'HT', name:'Haití', timezone: 'America/Port-au-Prince', utc: 'UTC -04:00'},
    {cod: 'VA', name:'Estado de la Ciudad del Vaticano', timezone: 'Europe/Vatican', utc: 'UTC +02:00'},
    {cod: 'HN', name:'Honduras', timezone: 'America/Tegucigalpa', utc: 'UTC -06:00'},
    {cod: 'HK', name:'Hong Kong', timezone: 'Asia/Hong_Kong', utc: 'UTC +08:00'},
    {cod: 'HU', name:'Hungría', timezone: 'Europe/Budapest', utc: 'UTC +02:00'},
    {cod: 'IS', name:'Islandia', timezone: 'Atlantic/Reykjavik', utc: 'UTC'},
    {cod: 'IN', name:'India', timezone: 'Asia/Kolkata', utc: 'UTC +05:30'},
    {cod: 'ID', name:'Indonesia', timezone: 'Asia/Jakarta', utc: 'UTC +07:00'},
    /*{cod: 'ID', name:'Indonesia', timezone: 'Asia/Jayapura', utc: 'UTC +09:00'},
    {cod: 'ID', name:'Indonesia', timezone: 'Asia/Makassar', utc: 'UTC +08:00'},
    {cod: 'ID', name:'Indonesia', timezone: 'Asia/Pontianak', utc: 'UTC +07:00'},*/
    {cod: 'IR', name:'Irán', timezone: 'Asia/Tehran', utc: 'UTC +04:30'},
    {cod: 'IQ', name:'Irak', timezone: 'Asia/Baghdad', utc: 'UTC +03:00'},
    {cod: 'IE', name:'Irlanda', timezone: 'Europe/Dublin', utc: 'UTC +01:00'},
    {cod: 'IM', name:'Isla del hombre', timezone: 'Europe/Isle_of_Man', utc: 'UTC +01:00'},
    {cod: 'IL', name:'Israel', timezone: 'Asia/Jerusalem', utc: 'UTC +03:00'},
    {cod: 'IT', name:'Italy', timezone: 'Europe/Rome', utc: 'UTC +02:00'},
    {cod: 'JM', name:'Jamaica', timezone: 'America/Jamaica', utc: 'UTC -05:00'},
    {cod: 'JP', name:'Japón', timezone: 'Asia/Tokyo', utc: 'UTC +09:00'},
    {cod: 'JE', name:'Jersey', timezone: 'Europe/Jersey', utc: 'UTC +01:00'},
    {cod: 'JO', name:'Jordán', timezone: 'Asia/Amman', utc: 'UTC +03:00'},
    /*{cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Almaty', utc: 'UTC +06:00'},
    {cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Aqtau', utc: 'UTC +05:00'},
    {cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Aqtobe', utc: 'UTC +05:00'},
    {cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Atyrau', utc: 'UTC +05:00'},
    {cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Qostanay', utc: 'UTC +06:00'},
    {cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Oral', utc: 'UTC +05:00'}, */
    {cod: 'KZ', name:'Kazajstán', timezone: 'Asia/Qyzylorda', utc: 'UTC +05:00'},
    {cod: 'KE', name:'Kenia', timezone: 'Africa/Nairobi', utc: 'UTC +03:00'},
    {cod: 'KI', name:'Kiribati', timezone: 'Pacific/Enderbury', utc: 'UTC +13:00'},
    /*{cod: 'KI', name:'Kiribati', timezone: 'Pacific/Kiritimati', utc: 'UTC +14:00'},
    {cod: 'KI', name:'Kiribati', timezone: 'Pacific/Tarawa', utc: 'UTC +12:00'},*/
    {cod: 'KP', name:'Korea, del Norte', timezone: 'Asia/Pyongyang', utc: 'UTC +09:00'},
    {cod: 'KR', name:'Korea, del Sur', timezone: 'Asia/Seoul', utc: 'UTC +09:00'},
    {cod: 'KW', name:'Kuwait', timezone: 'Asia/Kuwait', utc: 'UTC +03:00'},
    {cod: 'KG', name:'Kirguistán', timezone: 'Asia/Bishkek', utc: 'UTC +06:00'},
    {cod: 'LA', name:'República Democrática Popular Lao', timezone: 'Asia/Vientiane', utc: 'UTC +07:00'},
    {cod: 'LV', name:'Letonia', timezone: 'Europe/Riga', utc: 'UTC +03:00'},
    {cod: 'LB', name:'Líbano', timezone: 'Asia/Beirut', utc: 'UTC +03:00'},
    {cod: 'LS', name:'Lesoto', timezone: 'Africa/Maseru', utc: 'UTC +02:00'},
    {cod: 'LR', name:'Liberia', timezone: 'Africa/Monrovia', utc: 'UTC'},
    {cod: 'LY', name:'Libia', timezone: 'Africa/Tripoli', utc: 'UTC +02:00'},
    {cod: 'LI', name:'Liechtenstein', timezone: 'Europe/Vaduz', utc: 'UTC +02:00'},
    {cod: 'LT', name:'Lituania', timezone: 'Europe/Vilnius', utc: 'UTC +03:00'},
    {cod: 'LU', name:'Luxemburgo', timezone: 'Europe/Luxembourg', utc: 'UTC +02:00'},
    {cod: 'MO', name:'Macao', timezone: 'Asia/Macau', utc: 'UTC +08:00'},
    {cod: 'MK', name:'Macedonia', timezone: 'Europe/Skopje', utc: 'UTC +02:00'},
    {cod: 'MG', name:'Madagascar', timezone: 'Indian/Antananarivo', utc: 'UTC +03:0'},
    {cod: 'MW', name:'Malawi', timezone: 'Africa/Blantyre', utc: 'UTC +02:00'},
    {cod: 'MY', name:'Malasia', timezone: 'Asia/Kuala_Lumpur', utc: 'UTC +08:'},
    //{cod: 'MY', name:'Malasia', timezone: 'Asia/Kuching', utc: 'UTC +09:00'},
    {cod: 'MV', name:'Maldivas', timezone: 'Indian/Maldives', utc: 'UTC +05:00'},
    {cod: 'ML', name:'Mali', timezone: 'Africa/Bamako', utc: 'UTC'},
    {cod: 'MT', name:'Malta', timezone: 'Europe/Malta', utc: 'UTC +02:00'},
    {cod: 'MH', name:'Islas Marshall', timezone: 'Pacific/Kwajalein', utc: 'UTC +12:00'},
    //{cod: 'MH', name:'Islas Marshall', timezone: 'Pacific/Majuro', utc: 'UTC +12:00'},
    {cod: 'MQ', name:'Martinica', timezone: 'America/Martinique', utc: 'UTC -04:00'},
    {cod: 'MR', name:'Mauritania', timezone: 'Africa/Nouakchott', utc: 'UTC'},
    {cod: 'MU', name:'Mauricio', timezone: 'Indian/Mauritius', utc: 'UTC +04:00'},
    {cod: 'YT', name:'Mayotte', timezone: 'Indian/Mayotte', utc: 'UTC +03:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Mexico_City', utc: 'UTC -05:00'},
    /*{cod: 'MX', name:'Mexico', timezone: 'America/Bahia_Banderas', utc: 'UTC -05:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Cancun', utc: 'UTC -05:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Chihuahua', utc: 'UTC -06:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Hermosillo', utc: 'UTC -07:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Matamoros', utc: 'UTC -05:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Mazatlan', utc: 'UTC -06:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Merida', utc: 'UTC -05:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Monterrey', utc: 'UTC -05:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Ojinaga', utc: 'UTC -06:00'},
    {cod: 'MX', name:'Mexico', timezone: 'America/Tijuana', utc: 'UTC -07:00'},*/
    {cod: 'FM', name:'Micronesia', timezone: 'Pacific/Chuuk', utc: 'UTC +10:00'},
    //{cod: 'FM', name:'Micronesia', timezone: 'Pacific/Kosrae', utc: 'UTC +11:00'},
    {cod: 'FM', name:'Micronesia', timezone: 'Pacific/Pohnpei', utc: 'UTC +11:00'},
    {cod: 'MD', name:'Moldavia', timezone: 'Europe/Chisinau', utc: 'UTC +03:00'},
    {cod: 'MC', name:'Mónaco', timezone: 'Europe/Monaco', utc: 'UTC +02:00'},
    {cod: 'MN', name:'Mongolia', timezone: 'Asia/Choibalsan', utc: 'UTC +08:00'},
    //{cod: 'MN', name:'Mongolia', timezone: 'Asia/Hovd', utc: 'UTC +07:00'},
    {cod: 'MN', name:'Mongolia', timezone: 'Asia/Ulaanbaatar', utc: 'UTC +08:00'},
    {cod: 'ME', name:'Montenegro', timezone: 'Europe/Podgorica', utc: 'UTC +02:00'},
    {cod: 'MS', name:'Montserrat', timezone: 'America/Montserrat', utc: 'UTC -04:00'},
    {cod: 'MA', name:'Marruecos', timezone: 'Africa/Casablanca', utc: 'UTC +01:00'},
    {cod: 'MZ', name:'Mozambique', timezone: 'Africa/Maputo', utc: 'UTC +02:00'},
    {cod: 'MM', name:'Myanmar', timezone: 'Asia/Yangon', utc: 'UTC +06:30'},
    {cod: 'NA', name:'Namibia', timezone: 'Africa/Windhoek', utc: 'UTC +02:00'},
    {cod: 'NR', name:'Nauru', timezone: 'Pacific/Nauru', utc: 'UTC +12:00'},
    {cod: 'NP', name:'Nepal', timezone: 'Asia/Kathmandu', utc: 'UTC +05:45'},
    {cod: 'NL', name:'Países Bajos', timezone: 'Europe/Amsterdam', utc: 'UTC +02:00'},
    {cod: 'NC', name:'Caledonia', timezone: 'Pacific/Noumea', utc: 'UTC +11:00'},
    {cod: 'NZ', name:'Nueva Zelanda', timezone: 'Pacific/Auckland', utc: 'UTC +12:00'},
    // {cod: 'NZ', name:'Nueva Zelanda', timezone: 'Pacific/Chatham', utc: 'UTC +12:45'},
    {cod: 'NI', name:'Nicaragua', timezone: 'America/Managua', utc: 'UTC -06:00'},
    {cod: 'NE', name:'Níger', timezone: 'Africa/Niamey', utc: 'UTC +01:00'},
    {cod: 'NG', name:'Nigeria', timezone: 'Africa/Lagos', utc: 'UTC +01:00'},
    {cod: 'NU', name:'Niue', timezone: 'Pacific/Niue', utc: 'UTC -11:00'},
    {cod: 'NF', name:'Isla Norfolk', timezone: 'Pacific/Norfolk', utc: 'UTC +11:00'},
    {cod: 'MP', name:'Islas Marianas del Norte', timezone: 'Pacific/Saipan', utc: 'UTC +10:00'},
    {cod: 'NO', name:'Noruega', timezone: 'Europe/Oslo', utc: 'UTC +02:00'},
    {cod: 'OM', name:'Omán', timezone: 'Asia/Muscat', utc: 'UTC +04:00'},
    {cod: 'PK', name:'Pakistán', timezone: 'Asia/Karachi', utc: 'UTC +05:00'},
    {cod: 'PW', name:'Palau', timezone: 'Pacific/Palau', utc: 'UTC +09:00'},
    {cod: 'PS', name:'Palestina', timezone: 'Asia/Gaza', utc: 'UTC +03:00'},
    // {cod: 'PS', name:'Palestina', timezone: 'Asia/Hebron', utc: 'UTC +03:00'},
    {cod: 'PA', name:'Panamá', timezone: 'America/Panama', utc: 'UTC -05:00'},
    {cod: 'PG', name:'Papúa Nueva Guinea', timezone: 'Pacific/Bougainville', utc: 'UTC +11:00'},
    //{cod: 'PG', name:'Papúa Nueva Guinea', timezone: 'Pacific/Port_Moresby', utc: 'UTC +10:00'},
    {cod: 'PY', name:'Paraguay', timezone: 'America/Asuncion', utc: 'UTC -04:00'},
    {cod: 'PE', name:'Peru', timezone: 'America/Lima', utc: 'UTC -05:00'},
    {cod: 'PH', name:'Filipinas', timezone: 'Asia/Manila', utc: 'UTC +08:00'},
    {cod: 'PN', name:'Pitcairn', timezone: 'Pacific/Pitcairn', utc: 'UTC -08:00'},
    {cod: 'PL', name:'Polonia', timezone: 'Europe/Warsaw', utc: 'UTC +02:00'},
    /*{cod: 'PT', name:'Portugal', timezone: 'Atlantic/Azores', utc: 'UTC'},
    {cod: 'PT', name:'Portugal', timezone: 'Atlantic/Madeira', utc: 'UTC +01:00'},*/
    {cod: 'PT', name:'Portugal', timezone: 'Europe/Lisbon', utc: 'UTC +01:00'},
    {cod: 'PR', name:'Puerto Rico', timezone: 'America/Puerto_Rico', utc: 'UTC -04:00'},
    {cod: 'QA', name:'Katar', timezone: 'Asia/Qatar', utc: 'UTC +03:00'},
    {cod: 'RO', name:'Romania', timezone: 'Europe/Bucharest', utc: 'UTC +03:00'},
    /*{cod: 'RU', name:'Rusia', timezone: 'Asia/Anadyr', utc: 'UTC +12:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Barnaul', utc: 'UTC +07:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Chita', utc: 'UTC +09:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Irkutsk', utc: 'UTC +08:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Kamchatka', utc: 'UTC +12:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Khandyga', utc: 'UTC +09:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Krasnoyarsk', utc: 'UTC +07:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Magadan', utc: 'UTC +11:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Novokuznetsk', utc: 'UTC +07:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Novosibirsk', utc: 'UTC +07:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Omsk', utc: 'UTC +06:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Sakhalin', utc: 'UTC +11:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Srednekolymsk', utc: 'UTC +11:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Tomsk', utc: 'UTC +07:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Ust-Nera', utc: 'UTC +10:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Vladivostok', utc: 'UTC +10:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Yakutsk', utc: 'UTC +09:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Asia/Yekaterinburg', utc: 'UTC +05:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Astrakhan', utc: 'UTC +04:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Kaliningrad', utc: 'UTC +02:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Kirov', utc: 'UTC +03:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Samara', utc: 'UTC +04:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Saratov', utc: 'UTC +04:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Ulyanovsk', utc: 'UTC +04:00'},
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Volgograd', utc: 'UTC +04:00'},*/
    {cod: 'RU', name:'Rusia', timezone: 'Europe/Moscow', utc: 'UTC +03:00'},
    {cod: 'RW', name:'Ruanda', timezone: 'Africa/Kigali', utc: 'UTC +02:00'},
    {cod: 'RE', name:'Réunion', timezone: 'Indian/Reunion', utc: 'UTC +04:00'},
    {cod: 'BL', name:'San Bartolomé', timezone: 'America/St_Barthelemy', utc: 'UTC -04:00'},
    {cod: 'SH', name:'Santa Elena, Ascensión y Tristán de Cunha', timezone: 'Atlantic/St_Helena', utc: 'UTC'},
    //{cod: 'KN', name:'Santa Elena, Ascensión y Tristán de Cunha', timezone: 'America/St_Kitts', utc: 'UTC -04:00'},
    {cod: 'LC', name:'Santa Lucia', timezone: 'America/St_Lucia', utc: 'UTC -04:00'},
    {cod: 'MF', name:'San Martín (parte francesa)', timezone: 'America/Marigot', utc: 'UTC -04:00'},
    {cod: 'PM', name:'San Pedro y Miquelón', timezone: 'America/Miquelon', utc: 'UTC -02:00'},
    {cod: 'VC', name:'San Vicente y las Granadinas', timezone: 'America/St_Vincent', utc: 'UTC -04:00'},
    {cod: 'WS', name:'Samoa', timezone: 'Pacific/Apia', utc: 'UTC +13:00'},
    {cod: 'SM', name:'San Marino', timezone: 'Europe/San_Marino', utc: 'UTC +02:00'},
    {cod: 'ST', name:'Santo Tomé y Príncipe', timezone: 'Africa/Sao_Tome', utc: 'UTC'},
    {cod: 'SA', name:'Arabia Saudita', timezone: 'Asia/Riyadh', utc: 'UTC +03:00'},
    {cod: 'SN', name:'Senegal', timezone: 'Africa/Dakar', utc: 'UTC'},
    {cod: 'RS', name:'Serbia', timezone: 'Europe/Belgrade', utc: 'UTC +02:00'},
    {cod: 'SC', name:'Seychelles', timezone: 'Indian/Mahe', utc: 'UTC +04:00'},
    {cod: 'SL', name:'Sierra Leone', timezone: 'Africa/Freetown', utc: 'UTC'},
    {cod: 'SG', name:'Singapur', timezone: 'Asia/Singapore', utc: 'UTC +08:00'},
    {cod: 'SX', name:'San Martín (parte Holandesa)', timezone: 'America/Lower_Princes', utc: 'UTC -04:00'},
    {cod: 'SK', name:'Eslovaquia', timezone: 'Europe/Bratislava', utc: 'UTC +02:00'},
    {cod: 'SI', name:'Eslovenia', timezone: 'Europe/Ljubljana', utc: 'UTC +02:00'},
    {cod: 'SB', name:'Islas Salomón', timezone: 'Pacific/Guadalcanal', utc: 'UTC +11:00'},
    {cod: 'SO', name:'Somalia', timezone: 'Africa/Mogadishu', utc: 'UTC +03:00'},
    {cod: 'ZA', name:'Sudáfrica', timezone: 'Africa/Johannesburg', utc: 'UTC +02:00'},
    {cod: 'GS', name:'Georgia del sur y las islas Sandwich del sur', timezone: 'Atlantic/South_Georgia', utc: 'UTC -02:00'},
    {cod: 'SS', name:'Sudán del Sur', timezone: 'Africa/Juba', utc: 'UTC +03:00'},
    /*{cod: 'ES', name:'España', timezone: 'Africa/Ceuta', utc: 'UTC +02:00'},
    {cod: 'ES', name:'España', timezone: 'Atlantic/Canary', utc: 'UTC +01:00'},*/
    {cod: 'ES', name:'España', timezone: 'Europe/Madrid', utc: 'UTC +02:00'},
    {cod: 'LK', name:'Sri Lanka', timezone: 'Asia/Colombo', utc: 'UTC +05:30'},
    {cod: 'SD', name:'Sudán', timezone: 'Africa/Khartoum', utc: 'UTC +02:00'},
    {cod: 'SR', name:'Suriname', timezone: 'America/Paramaribo', utc: 'UTC -03:00'},
    {cod: 'SJ', name:'Svalbard y Jan Mayenn', timezone: 'Arctic/Longyearbyen', utc: 'UTC +02:00'},
    {cod: 'SZ', name:'Swazilandia', timezone: 'Africa/Mbabane', utc: 'UTC +02:00'},
    {cod: 'SE', name:'Suecin', timezone: 'Europe/Stockholm', utc: 'UTC +02:00'},
    {cod: 'CH', name:'Suiza', timezone: 'Europe/Zurich', utc: 'UTC +02:00v'},
    {cod: 'SY', name:'República Árabe Siria', timezone: 'Asia/Damascus', utc: 'UTC +03:00'},
    {cod: 'TW', name:'Taiwan, provincia de China', timezone: 'Asia/Taipei', utc: 'UTC +08:00'},
    {cod: 'TJ', name:'Tayikistán', timezone: 'Asia/Dushanbe', utc: 'UTC +05:00'},
    {cod: 'TZ', name:'Tanzania', timezone: 'Africa/Dar_es_Salaam', utc: 'UTC +03:00'},
    {cod: 'TH', name:'Tailandia', timezone: 'Asia/Bangkok', utc: 'UTC +07:00'},
    {cod: 'TL', name:'Timor-Lestee', timezone: 'Asia/Dili', utc: 'UTC +09:00'},
    {cod: 'TG', name:'Togo', timezone: 'Africa/Lome', utc: 'UTC'},
    {cod: 'TK', name:'Tokelau', timezone: 'Pacific/Fakaofo', utc: 'UTC +13:00'},
    {cod: 'TO', name:'Tonga', timezone: 'Pacific/Tongatapu', utc: 'UTC +13:00'},
    {cod: 'TT', name:'Trinidad y Tobago', timezone: 'America/Port_of_Spain', utc: 'UTC -04:00'},
    {cod: 'TN', name:'Tunez', timezone: 'Africa/Tunis', utc: 'UTC +01:00'},
    {cod: 'TR', name:'Turkia', timezone: 'Europe/Istanbul', utc: 'UTC +03:00'},
    {cod: 'TM', name:'Turkmenistan', timezone: 'Asia/Ashgabat', utc: 'UTC +05:00'},
    {cod: 'TC', name:'Islas Turcas y Caicos', timezone: 'America/Grand_Turk', utc: 'UTC -04:00'},
    {cod: 'TV', name:'Tuvalu', timezone: 'Pacific/Funafuti', utc: 'UTC +12:00'},
    {cod: 'UG', name:'Uganda', timezone: 'Africa/Kampala', utc: 'UTC +03:00'},
    {cod: 'UA', name:'Ucrania', timezone: 'Europe/Kiev', utc: 'UTC +03:00'},
    /*{cod: 'UA', name:'Ucrania', timezone: 'Europe/Simferopol', utc: 'UTC +03:00'},
    {cod: 'UA', name:'Ucrania', timezone: 'Europe/Uzhgorod', utc: 'UTC +03:00'},
    {cod: 'UA', name:'Ucrania', timezone: 'Europe/Zaporozhye', utc: 'UTC +03:00'},*/
    {cod: 'AE', name:'Emiratos Árabes Unidos', timezone: 'Asia/Dubai', utc: 'UTC +04:00'},
    {cod: 'GB', name:'Reino Unido', timezone: 'Europe/London', utc: 'UTC +01:00'},
    /*{cod: 'US', name:'Estados Unidos', timezone: 'America/Adak', utc: 'UTC -09:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Anchorage', utc: 'UTC -08:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Boise', utc: 'UTC -06:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Detroit', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Indianapolis', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Knox', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Marengo', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Petersburg', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Tell_City', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Vevay', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Vincennes', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Indiana/Winamac', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Juneau', utc: 'UTC -08:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Menominee', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Metlakatla', utc: 'UTC -08:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Nome', utc: 'UTC -08:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/North_Dakota/Beulah', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/North_Dakota/Center', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/North_Dakota/New_Salem', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Phoenix', utc: 'UTC -07:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Sitka', utc: 'UTC -08:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Yakutat', utc: 'UTC -08:00'},
    {cod: 'US', name:'Estados Unidos', timezone:	'Pacific/Honolulu', utc: 'UTC -10:00'},
    {cod: 'UM', name:'Estados Unidos Minor Outlying Islands', timezone:	'Pacific/Midway', utc: 'UTC -11:00'},
    {cod: 'UM', name:'Estados Unidos Minor Outlying Islands', timezone:	'Pacific/Wake', utc: 'UTC +12:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Kentucky/Louisville', utc: 'UTC -04:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Denver', utc: 'UTC -06:00'},
    {cod: 'US', name:'Estados Unidos', timezone: 'America/Kentucky/Monticello', utc: 'UTC -04:00'},*/
    {cod: 'US', name:'Estados Unidos / Chicago', timezone: 'America/Chicago', utc: 'UTC -05:00'},
    {cod: 'US', name:'Estados Unidos / Los_Angeles' , timezone: 'America/Los_Angeles', utc: 'UTC -07:00'},
    {cod: 'US', name:'Estados Unidos / New York', timezone: 'America/New_York', utc: 'UTC -04:00'},
    {cod: 'UY', name:'Uruguay', timezone: 'America/Montevideo', utc: 'UTC -03:00'},
    {cod: 'UZ', name:'Uzbekistán', timezone: 'Asia/Samarkand', utc: 'UTC +05:00'},
    //{cod: 'UZ', name:'Uzbekistán', timezone: 'Asia/Tashkent', utc: 'UTC +05:00'},
    {cod: 'VU', name:'Vanuatu', timezone:	'Pacific/Efate', utc: 'UTC +11:00'},
    {cod: 'VE', name:'Venezuela', timezone: 'America/Caracas', utc: 'UTC -04:00'},
    {cod: 'VN', name:'Vietnam', timezone: 'Asia/Ho_Chi_Minh', utc: 'UTC +07:00'},
    {cod: 'VG', name:'Islas Vírgenes Británicas', timezone: 'America/Tortola', utc: 'UTC -04:00'},
    {cod: 'VI', name:'Islas Vírgenes, EE. UU.', timezone: 'America/St_Thomas', utc: 'UTC -04:00'},
    {cod: 'WF', name:'Wallis y Futuna', timezone: 'Pacific/Wallis', utc: 'UTC +12:00'},
    {cod: 'EH', name:'Sahara Occidental', timezone: 'Africa/El_Aaiun', utc: 'UTC +01:00'},
    {cod: 'YE', name:'Yemen', timezone: 'Asia/Aden', utc: 'UTC +03:00'},
    {cod: 'ZM', name:'Zambia', timezone: 'Africa/Lusaka', utc: 'UTC +02:00'},
    {cod: 'ZW', name:'Zimbabue', timezone: 'Africa/Harare', utc: 'UTC +02:00'},
    {cod: 'AX', name:'Islas Aland', timezone: 'Europe/Mariehamn', utc: 'UTC +03:00'}
];

function compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
}

export default { 
    list: arrayTimezones.sort(compare)
}